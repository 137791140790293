//=============================================================================
// Contents   : HPRP
//              RecruitMiniRTitle
// Author     : RV太田
// LastUpdate : 2023/6/6
// Since      : 2023/6/6
//=============================================================================

/* ======================================================================================================================================================= */  
/* ==================================================================== Start Of Program ================================================================= */  
/* ======================================================================================================================================================= */ 


/* ======================================================================== */  
/* ============================ import ==================================== */  
/* ======================================================================== */  
/* ====== React ====== */
import React from "react";
/* ====== CSS ====== */
import styled from "styled-components";
/* ====== Image ====== */
/* ====== Component Js/Jsx ====== */
/* ====== Style ====== */
/* ======================================================================== */  
/* ============================ Styles ==================================== */  
/* ======================================================================== */
const SminiTitle = styled.div`
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 0.1em;
    margin: 7vh auto 0 auto;
    text-align: center;
    color: #5CC6C0;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        font-size: 1.5rem;
        margin: 5vh auto 0 auto;
    }   
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170px以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;

/* ======================================================================== */  
/* ================== global variables/functions ========================== */  
/* ======================================================================== */

/* ======================================================================== */  
/* ============================ Main ====================================== */  
/* ======================================================================== */  

/* ========= 一覧テンプレート ========= */
export const RecruitMiniTitle = (props) => {
    
    /* ---------------------------------------------------- */
    /* ------------------ Instance ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ------------------ variables ----------------------- */
    /* ---------------------------------------------------- */
   
    /* ---------------------------------------------------- */
    /* ---------------------- state ----------------------- */
    /* ---------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------- reack-hook-form ------------------- */
    /* ----------------------------------------------------- */
   
    /* ---------------------------------------------------- */
    /* ---------------------- function -------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- data ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- jsx ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* -------------------- Start Render ------------------ */
    /* ---------------------------------------------------- */

    return(
        <>
            <SminiTitle>{props.title}</SminiTitle>
        </>
    );
};

/* ======================================================================================================================================================= */  
/* ==================================================================== End Of Program =================================================================== */  
/* ======================================================================================================================================================= */ 


