/*====== Define import ======*/
import { useLocation } from "react-router-dom";
/*------ React ------*/
import React from "react";
/*------ CSS ------*/
import styled from "styled-components";
import { useEffect } from "react";


/*------ Styled Components ------*/
const Btn = styled.button`
    width: ${(props) => props.width};//whidthを使い回したい場合があるのでpropsで管理します
    height: ${(props) => props.height};//whidthを使い回したい場合があるのでpropsで管理しま
    position: relative;
    overflow: hidden;
    z-index: 0;
    text-decoration: none;
    display: inline-block;
    padding: 0.2rem 1rem 0.2rem 1rem;
    text-align: center;
    outline: none;
    transition: ease .2s;
    font-weight: bold;
    letter-spacing: 0.2em;
    cursor:  ${(props) => props.disabled ? "default" : "pointer"};
    color: ${(props) => props.disabled ? "#A9A9A9" : "#none"};
    border: 0.5px solid ${(props) => props.disabled ? "#A9A9A9" : props.color};
    background-color: ${(props) => props.disabled ? "#E9E9E9" : "#none"};
    cursor: pointer;
    
    //hoverした時の文字色
    &:hover{
        color: ${(props) => props.hoverColor};
        cursor:  ${(props) => props.disabled ? "default" : "pointer"};
  		background-color: ${(props) => props.disabled ? "#none" : "#none"};
    }
    //hoverした時のスライドする背景をbeforeで設定
    &:before {
        content: '';
        position: absolute;//絶対配置で位置を指定
        top: 0;
        left: 0;
        z-index: 1;
        /*色や形状*/
        background:${(props) => props.disabled ? "#none" : props.hoverBackgroundColor};//背景色
        width: 100%;
        height: 100%;
        //アニメーション
        transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
        transform: scale(0, 1);//背景の位置
        transform-origin: right top;
    }
    //hoverした時の背景色の動き
    &:hover::before {
        transform-origin:left top;
        transform:scale(1, 1); //背景の位置
    }
    @media screen and (max-width:550px){
		margin-bottom:30px;
    }

`;

/*------ Styled Components ------*/
//ButtonTextの設定
const ButtonText = styled.p`
padding-top: 2.3rem;
    position: relative;
    /* top: 13%; */
    left: 52%;
    transform: translate(-50%,-50%);
    z-index: 100;//動く背景よりも文字を前にする
`;
const P = styled.div`
    margin: 0 1rem;
`;
/*====================*/ 
/*====== Main ======*/ 
/*====================*/ 
export const ContactInputBtn = (props) => {
    /*====== Instance ======*/ 
    /*====== variables ======*/
    const { btn_text, width, height, color, hoverColor, hoverBackgroundColor , navi, backColor, type, dvalue, disabled, values, onSubmit, onClick, className } = props;
    /*====== State ======*/
    
    /*====== function ======*/
    const location = useLocation();

    useEffect(()=>{
        window.scrollTo(0,0);
    },[location]);

    return (
       
        <>
        <P>
                <Btn 
                onClick={onClick}
                width={width}
                height={height}
                color={color}
                backColor={backColor}
                hoverColor={hoverColor}
                hoverBackgroundColor={hoverBackgroundColor}
                type={type}
                dvalue={dvalue}
                disabled={disabled}
                values={values}
                onSubmit={onSubmit}
                navi={navi}
                className={className}
                >
                <ButtonText>{btn_text}</ButtonText>
                </Btn>
                </P>
        </>
    );
};