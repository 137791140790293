import { StrictMode } from "react";
import ReactDom from "react-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'; 
import {HelmetProvider } from 'react-helmet-async';
 
/*------ CSS ------*/
import "./index.css";
import App from "./App";
 
ReactDom.render(
  <StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} language="ja">
    <HelmetProvider>
      <App />
      </HelmetProvider>
    </GoogleReCaptchaProvider>
  </StrictMode>
  , document.getElementById("root")

);
