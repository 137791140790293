/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from 'react';
import { Timeline } from 'react-twitter-widgets';
/* ---------- CSS ---------- */
import styled, { keyframes } from 'styled-components';
/* ---------- Image ---------- */
import image01 from "../../Images/0310B.JPG";
import Entry from "../../Images/RecruitSVG/Entry.svg";
import karasuA from "../../Images/Recruit_Karasu/EntryKarasu.png";
import karasuB from "../../Images/Recruit_Karasu/EntryKarasu2.png";
/* ---------- compornent ---------- */
import { GlobalBtn, GlobalBtnHref } from '../../Components/Common/GlobalBtn';

/*====== Define styles ======*/
/*------ Styled Components ------*/
const SentryWrapper = styled.div`
    background-color: #27282F;
    width: 100%;
    text-align: center;
    padding: 60px 0;
    display: ${(props) => props.display};
`;

const SentryImg = styled.svg`
    max-width: 320px;
    width: 90%;
    margin: 0 0 2vh 0;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        margin: 0 0 1vh 0;
    }   
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        margin: 0 0 1vh 0;
    }
    /* ########### 769px以上～1170px以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
        margin: 0 0 2vh 0;
    }
`;

const SentryP = styled.p`
    color: white;
    width: 90%;
    margin: 0 auto 3vh auto;
    font-size: 1.2rem;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        font-size: 0.8rem;
    }   
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        font-size: 1rem;
    }
    /* ########### 769px以上～1170px以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
        margin: 0 auto 5vh auto;
    }
`;

const SkarasuWrapper = styled.div`  
    margin: 0 auto 3vh auto;
    height: 200px;
    position: relative;
`;

const AKeyframes = keyframes`
    0% {
        opacity: 1;
    }
    49%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;

const SkarasuA = styled.img`
    animation: ${AKeyframes} 2s ease infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
    width: 11rem;
`;

const BKeyframes = keyframes`
    0% {
        opacity: 0;
    }
    49%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`;

const SkarasuB = styled.img`
    animation: ${BKeyframes} 2s ease infinite;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    width: 11rem;
`;

const Swrap = styled.div`
    padding: 60px 0;
    background-color: #F0F0F0;
    width: 100%;
    height: auto;
    overflow: hidden;
`;
const Sbox = styled.div`
    max-width: 680px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    //レスポンシブ
    @media (max-width: 715px) {
        display: block;
    }
`;
const StimeLine = styled.div`
    width: 100%;
    overflow-y: hidden;
    :nth-child(1){padding: 0 5% 0 0};
    //レスポンシブ
    @media (max-width: 715px) {
        width: 100%;
        overflow-x: hidden;
        padding: 0;
        :nth-child(1){padding: 0 0 5% 0};
    }
`;
const Stitle = styled.p`
    margin: 0 0 9% 0;
    padding: 0 0 2% 0;
    border-bottom: 2px solid #5CC6C0;
    text-align: center;
    color: #5CC6C0;
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
`;
const SinternTitle = styled.p`
    padding: 0 0 6% 0;
    line-height: 1.5em;
    font-size: 1.2rem;
    font-weight: bold;
     text-align: center;
     color: red;
`;
const SimgBox = styled.div`
    width: fit-content;
    margin: 0 auto;
`;
const SimgWrapper = styled.div`
    width: 100%;
    margin: 0 0 6% 0;
    border-radius: 15px;
    overflow: hidden;
`;
const Simg = styled.img`
    width: 100%;
    transform:scale(1.8,1.8);
`;
const STwBox = styled.div`
    width: 100%;
    @media (max-width: 680px) {
    }
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const RecruitFooter = (props) => {
    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return( 
        <> 
            <SentryWrapper display={props.display} >
                {/* <SentryImg src={Entry} alt="エントリー" /> */}
                <SentryImg vviewBox="0 0 256 52">
                    <use xlinkHref={`${Entry}#Entry`}></use>
                </SentryImg>

                <SentryP>レイヴンはあなたのエントリーをお待ちしております。</SentryP>
                <SkarasuWrapper>
                    <SkarasuA src={karasuA} alt="karasuA" />
                    <SkarasuB src={karasuB} alt="karasuB" />
                </SkarasuWrapper>
                <GlobalBtn 
                    btn_text="エントリーはこちら 〉"
                    hoverBackgroundColor="#FBE04F"
                    height="3rem"
                    width="18rem"
                    color="white"
                    hoverColor="#27282F"
                    navi="/recruit/jobdescription"
                />
            </SentryWrapper>
            <Swrap>
                <Sbox>
                    <StimeLine>
                        <Stitle>採用X</Stitle>
                        <STwBox>    
                            <Timeline 
                                dataSource={{
                                    sourceType: 'profile',
                                    screenName: 'raven_newgrads' // アカウント名
                                }}
                                options={{
                                    width: '100%',
                                    height: '430'
                                }}
                            />
                        </STwBox>
                    </StimeLine>
                    <StimeLine>
                        <Stitle>インターンシップ</Stitle>
                        <SinternTitle>5Daysインターンシップ開催!!<br/>第1回募集【2024/7/22締め切り】<br/>第2回募集【2024/8/12締め切り】<br/>第3回募集【2024/8/19締め切り】</SinternTitle>
                        <SimgWrapper>
                            <Simg src={image01} />
                        </SimgWrapper>
                        <SimgBox>
                            <GlobalBtnHref 
                                btn_text="詳細はこちら 〉"
                                hoverBackgroundColor="#FBE04F"
                                height="3rem"
                                width="12rem"
                                color="black"
                            />
                        </SimgBox>

                    </StimeLine>
                </Sbox>
            </Swrap>
        </>
    );
};