/*====== Define import ======*/
/*------ React ------*/
import React from "react";
import styled from "styled-components";

/*------ Styled Components ------*/

const Swrapper = styled.div`
/* max-width:90%; */
display: flex;
padding: 2rem 0 0 0;
// レスポンシブ
/* ########### 599px以下 ########### */
@media screen and (max-width: 599px) {
    display: block;
}
/* ########### 600px以上～768px以下 ########### */
@media (min-width: 600px) and (max-width: 768px) {
    display: block;
}
/* ########### 769px以上 ########### */
@media (min-width: 769px) and (max-width: 1169px) {
    display: block;
}
`;

const SwrapperText = styled.div`
letter-spacing: 0.1rem;
line-height: 1.8rem;
color: #1C1C1C;
width: 100%;
margin: 0 auto;
// レスポンシブ
/* ########### 599px以下 ########### */
@media (max-width: 599px) {
    width: 90%;
}
/* ########### 600px以上～768px以下 ########### */
@media (min-width: 600px) and (max-width: 768px) {
    width: 90%;
}
/* ########### 769px以上 ########### */
@media (min-width: 769px) and (max-width: 1169px) {
    width: 90%;
}
`;

const SsentenceTitle = styled.h2`
font-size: 1.5rem;
width: 100%;
display: block;
padding: 1.5rem 0 1.5rem 1.5rem;
border-left: 5px solid rgb(251,224,79);
`;

const SsentenceP = styled.p`
font-size: 1rem;
padding: 1rem 0 0 0;
width: 100%;
`;

const SwrapperImg = styled.div`
display:${(props) => props.type ? "block" : "none"};
width: 80%;
margin: 1rem 0 0 0;
// レスポンシブ
/* ########### 599px以下 ########### */
@media (max-width: 599px) {
    margin: 0 auto;
}
/* ########### 600px以上～768px以下 ########### */
@media (min-width: 600px) and (max-width: 768px) {
     margin: 0 auto;
}
/* ########### 769px以上 ########### */
@media (min-width: 769px) and (max-width: 1169px) {
    margin: 0 auto;
}
`;

const Simg = styled.img`
/* margin: 0 0 0 4rem; */
margin: 0 0 0 5%;
width: 95%;
/* ########### 600px以上～768px以下 ########### */
@media (min-width: 769px) and (max-width: 1169px) {
   margin: 2rem auto 0 auto;
   width: 100%;
}
@media (min-width: 600px) and (max-width: 768px) {
       margin: 2rem auto 0 auto;
    width: 100%;
}
@media (min-width: 350px) and (max-width: 599px) {
       margin: 2rem auto 0 auto;
    width: 100%;
}
`;

export const ServiceInterviewSentence = (props) => {
    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/
    return (
        <>
            <Swrapper>
                <SwrapperText>
                    <SsentenceTitle>{props.sentenceTitle}</SsentenceTitle>
                    <SsentenceP>{props.sentence}</SsentenceP>
                </SwrapperText>
                <SwrapperImg type={props.type}>
                    <Simg src={props.img} alt={props.alt}/>
                </SwrapperImg>
            </Swrapper>
        </>
    );
}
