/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React, { useState } from 'react';
/* ---------- CSS ---------- */
import styled, {keyframes} from 'styled-components'
/* ---------- components ---------- */
import { Header } from '../../Components/Common/Header';
import { PgTitle } from '../../Components/Recruit/pgTitle';
import { PgTitle2 } from '../../Components/Recruit/pgTitle2';
import { Footer } from '../../Components/Common/Footer';
import { RecruitCard } from '../../Components/Common/RecruitCard';
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { HeadBlock } from "../../HeadBlock";
/* ---------- image ---------- */
import Image00 from "../../Images/whiteBord.jpg";
import Image01 from "../../Images/MeetingRoomBigNew.jpg";
import Image02 from "../../Images/workSpaceNew.jpg";
import Image04 from "../../Images/restSpaceNew.jpg";
import Image05 from "../../Images/trainingSpaceNew.jpg";
import Image06 from "../../Images/eatSpaceNew.jpg";
import Image07 from "../../Images/bikeSpaceNew.jpg";
import OfficeSvg from "../../Images/RecruitSVG/TopOffice.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon21-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon21-2.png";

const Swrap = styled.div`
    margin: 8% auto 8% auto;
`;
const SimgWrap = styled.div`
    display: flex;
    width: 60%;
    margin: 50px auto 0 auto;
    overflow: hidden;
    /* #### 599px以下 ### */
    @media (max-width: 599px) {
        width: 100%;
    }
`;
const Simg = styled.img`
    width: 100%;
    padding:  0 20px;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        /* 以下の設定は必須 */
        scroll-snap-align: center;
        height: 100%;
        width: 100%;
        flex: none;
    }

`;
const SimgBox = styled.div`
    display: flex;
    animation: ${(props) => props.mvoe} 1s ease-in-out;
    animation-fill-mode: forwards;
    transform: translateX(-60vw);

    ${Simg}:nth-child(4){display: none};
    ${Simg}:nth-child(5){display: none};
    ${Simg}:nth-child(6){display: none};
    ${Simg}:nth-child(7){display: none};
    ${Simg}:nth-child(8){display: none};
    ${Simg}:nth-child(9){display: none};
    ${Simg}:nth-child(10){display: none};

    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        /* 以下の設定は必須 */
        scroll-snap-type: x mandatory;
        transform: translateX(0vw);
        width: 100%;
        margin: 10px;
        overflow-x: scroll;
        display: flex;
        -webkit-overflow-scrolling: touch;// スマホ対応のため必須
        -overflow-scrolling: touch; // スマホ対応のため必須
        ${Simg}:nth-child(1){display:  none};
        ${Simg}:nth-child(2){display:  none};
        ${Simg}:nth-child(3){display:  none};
        ${Simg}:nth-child(4){display:  block};
        ${Simg}:nth-child(5){display:  block};
        ${Simg}:nth-child(6){display:  block};
        ${Simg}:nth-child(7){display:  block};
        ${Simg}:nth-child(8){display:  block};
        ${Simg}:nth-child(9){display:  block};
        ${Simg}:nth-child(10){display:  block};
    }
`;
const SbtnWrsp = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    margin: 20px auto 50px auto;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        display: none;
    }
`;
const Sorder = styled.div`
    width: 15px;
    height: 15px;
    margin: 0 10px 0 0;
    border-radius: 100%;
    background-color: ${(props) => props.backColor};
`;
const Sbtn = styled.button`
    font-size: 1.2rem;
    font-weight: bold;
    margin: -4px 0 0 10px;
    padding: 3px 10px 5px 10px;
    box-sizing: border-box;
    height: 3rem;
    width: 10rem;
    border-radius: 8px;
    background-color: #D9D9D9;
    border: none;
    cursor: pointer;
    &:hover{
        background-color: #5CC6C0;
    }
`;
const SbtnBack = styled.button`
    font-size: 1.2rem;
    font-weight: bold;
    margin: -4px 10px 0 0;
    padding: 3px 10px 5px 10px;
    box-sizing: border-box;
    height: 3rem;
    width: 10rem;
    border-radius: 8px;
    background-color: #D9D9D9;
    border: none;
    cursor: pointer;
    &:hover{
        background-color: #5CC6C0;
    }
`;
const MoveAnim = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(-200%);
    }
`;
const MoveAnimBack = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
`;

export const OfficeImage = () => {
    
    const [mainImg,setMainImg] = useState(0);
    const [subImg,setSubImg] = useState(1);
    const [backImg,setBackImg] = useState(6);
    const [start,setStart] = useState();
    
    const MasterImages = [
        Image00,
        Image01,
        Image02,
        Image04,
        Image05,
        Image06,
        Image07
    ];
    
    // const onClickBtn = (props) => {

    //     setStart(MoveAnim);

    //     const element = document.getElementById('imgWrap');
    //     element.onanimationend = () =>{
    //         if(MasterImages.length - 2 === mainImg){
    //             setMainImg(MasterImages.length - 1);
    //             setSubImg(0);
    //         }else if(MasterImages.length - 1 === mainImg){
    //             setMainImg(0);
    //             setSubImg(1);
    //         }else{
    //             setMainImg(mainImg + 1);
    //             setSubImg(subImg + 1);

    //         }
    //         setStart(null);
    //     }
    // };
    const onClickBtn = (props) => {

        setStart(MoveAnim);

        const element = document.getElementById('imgWrap');
        element.onanimationend = () =>{
            if(MasterImages.length - 1 === mainImg){
                setMainImg(0);
                setSubImg(1);
                setBackImg(MasterImages.length - 1);
            }else if(MasterImages.length - 2 === mainImg){
                setMainImg(mainImg + 1);
                setSubImg(0);
                setBackImg(mainImg );
            }else if(0 === mainImg){
                setMainImg(mainImg + 1);
                setSubImg(subImg + 1);
                setBackImg(mainImg);
            }else{
                setMainImg(mainImg + 1);
                setSubImg(subImg + 1);
                setBackImg(backImg + 1);
            }
            setStart(null);
        }
    };

    const onClickBtnBack = (props) => {

        setStart(MoveAnimBack);

        const element = document.getElementById('imgWrap');
        element.onanimationend = () =>{
            if(0 === mainImg){
                setMainImg(MasterImages.length - 1);
                setSubImg(0);
                setBackImg(MasterImages.length - 2);
            }else if(1 === mainImg){
                setMainImg(mainImg - 1);
                setSubImg(mainImg);
                setBackImg(MasterImages.length - 1);
            }else if(MasterImages.length - 1 === mainImg){
                setMainImg(mainImg - 1);
                setSubImg(mainImg);
                setBackImg(backImg - 1);
            }else{
                setMainImg(mainImg - 1);
                setSubImg(subImg - 1);
                setBackImg(backImg - 1);
            }
            setStart(null);
        }
    };



    return( 
        <>
            <HeadBlock title="RECRUIT - オフィス - 株式会社レイヴン" description="レイヴンのオフィスを紹介するページ。" path="OfficeImage"  />
            <Header color="black" />

            <PgTitle
                pgTitle={OfficeSvg}
                pgTitleName={'TopOffice'}
                describe="レイヴンのオフィス紹介をしていきます！"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <PgTitle2
                pgTitle={OfficeSvg}
                pgTitleName={'TopOffice'}
                describe="レイヴンのオフィス紹介をしていきます！"
                karasuA={karasuA}
                karasuB={karasuB}
            />

            <Swrap>
                {/* <SimgWrap>
                    <SimgBox id='imgWrap' mvoe={start}>
                        <Simg src={MasterImages[mainImg]} />
                        <Simg src={MasterImages[subImg]} />
                        <Simg src={MasterImages[2]} />
                        <Simg src={MasterImages[3]} />
                        <Simg src={MasterImages[4]} />
                        <Simg src={MasterImages[5]} />
                        <Simg src={MasterImages[6]} />
                    </SimgBox>
                </SimgWrap> */}

                <SimgWrap>
                    <SimgBox id='imgWrap' mvoe={start}>
                        <Simg src={MasterImages[backImg]} />
                        <Simg src={MasterImages[mainImg]} />
                        <Simg src={MasterImages[subImg]} />
                        <Simg src={MasterImages[0]} />
                        <Simg src={MasterImages[1]} />
                        <Simg src={MasterImages[2]} />
                        <Simg src={MasterImages[3]} />
                        <Simg src={MasterImages[4]} />
                        <Simg src={MasterImages[5]} />
                        <Simg src={MasterImages[6]} />
                    </SimgBox>
                </SimgWrap>

                <SbtnWrsp>
                    <SbtnBack onClick={onClickBtnBack}>前へ</SbtnBack>
                    <Sorder backColor={mainImg === 0 ? "#5CC6C0" : "#D9D9D9"}></Sorder>
                    <Sorder backColor={mainImg === 1 ? "#5CC6C0" : "#D9D9D9"}></Sorder>
                    <Sorder backColor={mainImg === 2 ? "#5CC6C0" : "#D9D9D9"}></Sorder>
                    <Sorder backColor={mainImg === 3 ? "#5CC6C0" : "#D9D9D9"}></Sorder>
                    <Sorder backColor={mainImg === 4 ? "#5CC6C0" : "#D9D9D9"}></Sorder>
                    <Sorder backColor={mainImg === 5 ? "#5CC6C0" : "#D9D9D9"}></Sorder>
                    <Sorder backColor={mainImg === 6 ? "#5CC6C0" : "#D9D9D9"}></Sorder>
                    <Sbtn onClick={onClickBtn}>次へ</Sbtn>
                </SbtnWrsp>
            </Swrap>

            <RecruitCard />
            <RecruitFooter 
                display="block"
            />

            <Footer />

        </>
    );
};