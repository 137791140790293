/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Components ----- ----- */
import { Header } from "../../Components/Common/Header";
import { SectionTitleAnime } from "../../Components/Common/SectionTitleAnime";
import { XSlider } from "../../Components/Common/XSlider";
import { Tile } from "../../Components/Common/Tile";
import { GlobalBtn } from "../../Components/Common/GlobalBtn";
import { Footer } from "../../Components/Common/Footer";
import { TopHero } from "../../Components/Top/TopHero";
import { TopNews } from "../../Components/Top/TopNews";
import { HeadBlock } from "../../HeadBlock";
// image
import ximg1 from "../../Images/3U5A3867.JPG";
import ximg2 from "../../Images/3U5A4927 2.JPG";
import ximg3 from "../../Images/03_03/3U5A4125.JPG";
import ximg4 from "../../Images/3U5A4711_1.jpg";
import ximg5 from "../../Images/3U5A4978 2.JPG";
import ximg6 from "../../Images/03_03/3U5A4243.JPG";
import ximg7 from "../../Images/03_03/3U5A4737.JPG";
import ximg8 from "../../Images/HP_ replace_2024/kenshu/24sotu.jpg"


import contactTile from "../../Images/3U5A4763.JPG";
import aboutTile from "../../Images/aboutTile.jpg";
import companyTile from "../../Images/3U5A2817.JPG";
import serviceTile from "../../Images/3U5A4730.JPG";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const SWrapper = styled.ul`
    max-width: 1170px;
    width: auto;
    margin: 5vh auto;
`;
const Scontents = styled.div`
    height: auto;
    padding: 5% 0 3% 0;
    box-sizing: border-box;
    background-color: rgb(39,40,47);
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        padding: 15% 0 1% 0;
        box-sizing: border-box;
    }
`;
const SList = styled.ul`
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    list-style: none;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        padding: 0 5% 0 5%;
        box-sizing: border-box;
    }
`;

const SbtnWrapper = styled.div`
    text-align: center;
    margin: 5vh auto 10vh 0;
`;
// --------------------------------------------
const SbackColor = styled.div`
    margin: 8vh 0;
    text-align: center;
`;

const Ssentence = styled.p`
    margin: 1vh 20px;
    font-size: 0.8rem;
    line-height: 1.5em;
`;


export const Top = () => {

    return (
        <>
         <HeadBlock title="株式会社レイヴン" description="株式会社レイヴンは、プロジェクト・マネジメントを中心に、システム開発や運用構築の技術・ノウハウを活かし、ビジネスを成功に導く会社です。本サイトでは、レイヴンのサービス、企業情報、採用情報などがご覧いただけます。" path="Top"  />
         
            <Header color="white" />
            <div></div>

            <TopHero />

            <SWrapper>
                <SectionTitleAnime
                    TitleName="NEWS"
                    fontSize="2rem"
                    fontFamily="'Roboto Condensed', sans-serif;"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <br />
                <SectionTitleAnime
                    TitleName="ニュース"
                    fontSize="0.8rem"
                    fontFamily=" 'Noto Sans JP', sans-serif"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <br />
                    <TopNews />
                <SbtnWrapper />
            </SWrapper>

            <Scontents>
                <SectionTitleAnime
                    TitleName="CONTENTS"
                    fontSize="2rem"
                    fontFamily="'Roboto Condensed', sans-serif;"
                    fontColor="white"
                    backGroundcolor="white"
                />
                <br />
                <SectionTitleAnime
                    TitleName="内容"
                    fontSize="0.8rem"
                    fontFamily=" 'Noto Sans JP', sans-serif"
                    fontColor="white"
                    backGroundcolor="white"
                />
                <SWrapper>
                    <SList>
                        <Tile
                            width="40%"
                            paddingTop="40%"
                            marginTop="80%"
                            title="ABOUT"
                            text="企業理念/行動指針/社訓"
                            img={aboutTile}
                            alt="ABOUTの画像"
                            navi="/about"
                        />
                        <Tile
                            width="40%"
                            paddingTop="40%"
                            marginTop="80%"
                            title="SERVICE"
                            text="レイヴンの強み/３つの事業"
                            img={serviceTile}
                            alt="SERVICEの画像"
                            navi="/service"
                        />
                        <Tile
                            width="40%"
                            paddingTop="40%"
                            marginTop="80%"
                            title="COMPANY"
                            text="会社概要/アクセス/子会社"
                            img={companyTile}
                            alt="COMPANYの画像"
                            navi="/company"
                        />
                        <Tile
                            width="40%"
                            paddingTop="40%"
                            marginTop="80%"
                            title="CONTACT"
                            text="お問い合わせフォーム"
                            img={contactTile}
                            alt="CONTACTの画像"
                            navi="/contact"
                        />
                    </SList>
                </SWrapper>
            </Scontents>

            

            <SbackColor>
                <SectionTitleAnime
                    TitleName="RECRUIT"
                    fontSize="2rem"
                    fontFamily="'Roboto Condensed', sans-serif;"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <br />
                <SectionTitleAnime
                    TitleName="採用情報"
                    fontSize="0.8rem"
                    fontFamily=" 'Noto Sans JP', sans-serif"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <SWrapper>
                    <h1>本当の豊かさを手に入れる</h1>
                    <Ssentence>レイヴンでは世界に通用するビジネスマンを目指せます。<br /> ITサービス構築のすべてに挑戦できる環境活かし ビジネスパーソンとして活躍しませんか？</Ssentence>
                    <SbtnWrapper>
                        <GlobalBtn
                            btn_text="MORE　〉"
                            width="12rem"
                            height="3rem"
                            hoverColor="#27282F"
                            hoverBackgroundColor="rgb(251,224,79)"
                            navi="/recruit"
                        />
                    </SbtnWrapper>
                </SWrapper>
                <XSlider
                    Image01={ximg1}
                    Image06={ximg6}
                    Image02={ximg2}
                    Image03={ximg3}
                    Image04={ximg4}
                    Image05={ximg5}
                    Image07={ximg7}
                    Image08={ximg8}
                    width={"32rem"}
                />
            </SbackColor>

            <Footer />
        </>
    );
}