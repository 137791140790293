// /* ==================== Define import ==================== */

// /* ---------- React ---------- */
import React from "react";
//import { Nakao } from "../../Pages/WorkSpace/feature_Nakao";
// /* ==================== Define styles ==================== */s
import styled from "styled-components";
// /* ---------- Styled Compornents ---------- */
const Tile = styled.div`
  max-width: 1170px;
  background-color: #ffffff;
  width: ${(props) => props.width};
  height: 160px;
  margin:0 auto 40px auto;
  @media (max-width: 920px) {
    width: 310px;
    }
  @media (max-width: 620px) {
    width: 100%;
    margin: 0 40px 40px 40px;
  }
`;
const Contents = styled.div`
`;
const Title = styled.h1`
  font-size: 36px;
  font-weight: 900;
  margin: auto 0;
  @media (max-width: 920px) {
    font-size: 28px;
    }
  @media (max-width: 430px) {
    font-size: 20px;
    }
`;
const Texts = styled.div`
    font-size: 20px;
    line-height: 1.5rem;
    padding: 0 0 0 0;
    @media (max-width: 900px) {
    font-size: 17.5px;
    }
  @media (max-width: 440px) {
    font-size: 15px;
    }
`;
const Text1 = styled.p``;

const SdivTop = styled.div`
display: flex;
height: 50px;
margin-bottom: 30px;
`;
const Simg = styled.img`
margin-right: 20px;
`;



/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const AboutTile = (props) => {
  const { title,text1,width,key,margin,img} = props;

  return (
    <>    
        <Tile key={key} width={width} margin={margin}>
          <Contents>
            <SdivTop>
            <Simg src={img}/>
            <Title>{title}</Title>
            </SdivTop>
            <Texts>
              <Text1>{text1}</Text1>
            </Texts>
          </Contents>
        </Tile>
    </>
  )
}
