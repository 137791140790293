/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
import { RecruitTraining23 } from "../../Components/Recruit/RecruitTraining23";
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { PgTitle } from "../../Components/Recruit/pgTitle";
import { PgTitle2 } from "../../Components/Recruit/pgTitle2";
import { GlobalBtn } from "../../Components/Common/GlobalBtn";
import { Answer } from "../../Components/Recruit/InterviewContents";
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { RecruitMiniTitle } from "../../Components/Recruit/RecruitMiniTitle";
import { HeadBlock } from "../../HeadBlock";
/* ====== Image ====== */

import TarningListSvg from "../../Images/RecruitSVG/TopTraining.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon3-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon3-2.png";
import schedule from "../../Images/HP_ replace_2024/kenshu/suke23.png";
/* ======================================================================== */
/* ============================ Styles ==================================== */
/* ======================================================================== */




/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
const Top = styled.div`
    margin: 60px auto;
    max-width: 1170px;
    width: 90%;
`;

    const All = styled.div`
    text-align  : center;
    `;

    const TitleSize = styled.h1`
    margin-bottom: 40px;
    font-size: 1.5rem;
    `;

    const Heigth = styled.div`
    margin-bottom: 80px;
    `;

    const SImg = styled.img`
    width: 90%;
    `;
/*====================*/
/*======  Main  ======*/
/*====================*/
export const TrainingG23 = () => {
    /*====== Instance ======*/
    /*====== variables ======*/
    /*====== Data ======*/
    /*====== function ======*/
    /*====== Jsx ======*/


    /* ======================================================================== */
    /* ============================ Styles ==================================== */
    /* ======================================================================== */

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/
    return (
        <>
        <HeadBlock title="RECRUIT - 23卒研修 - 株式会社レイヴン" description="23卒研修の研修内容" path="TrainingG23"  />
            <PgTitle
                pgTitle={TarningListSvg}
                pgTitleName={'TopTraining'}
                describe="23卒内定者研修"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <PgTitle2
                pgTitle={TarningListSvg}
                pgTitleName={'TopTraining'}
                describe="23卒内定者研修"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <Header color="black" />
            <Top>
                <All>
                    <RecruitMiniTitle
                        title="自社ホームページ"
                    />
                </All>
            </Top>
            <RecruitTraining23 />
            <Top>
                <All>
                    <Heigth />
                    <TitleSize>研修内容</TitleSize>
                </All>
                    <Answer
                        color="#5CC6C0"
                        title="HPリニューアル"
                        text="レイヴンのHPリニューアルに向けた、企画・デザイン・実装をしました。 企画は22卒内定者研修で作成したHPを参考にして進め、デザインは内定者全員で案を出し作成しました。実装は内定者のほとんどがプログラミング未経験者だったため、わからないことは自分で調べて内定者同士で相談しながら実装しました。HP全体のコンセプトはスタイリッシュですが、一部採用の部分は遊び心のあるデザインを意識しました。 "
                        Btitle="研修で学んだこと"
                        Btext="研修ではデザインとプログラミングについて学びました。デザインは基礎知識もなく、どのようにして魅力的なデザインを作成すれば良いのかわかりませんでした。特に、色彩やレイアウトの組み合わせ、ユーザーインターフェースの設計については理解が難しかったため、内定者同士で既存のWebサイトを調べて、何が良いのか話し合い学習しました。この学習の過程で、デザインの基本的な概念やテクニックを理解し、レイヴンHPを訪れた人がどんな目的で何を見たいかを考えてデザインすることができました。プログラミングはHTML・CSS・Reactという言語を学びました。 初めは簡単なレイアウトさえ作ることができませんでした。そこで、毎日のようにプログラミングし、間違いやエラーから学ぶことを心がけました。また、社員からのアドバイスやフィードバックも大きな助けとなりました。その結果、デザインしたHP通りにプログラミングすることができました。 内定者研修で心の片隅にあったプログラミングに対する不安の払拭や自分の考えたアイデアをコードに落とし込む喜びを味わうことができたため、不安なく入社することができました。"
                    />
                    <Heigth />
                <All>
                    <TitleSize>スケジュール</TitleSize>
                    <SImg style={{ padding: '1vw 0' }} src={schedule} alt="pagetitle" />

                    <Heigth />
                    {/* <TitleSize>研修風景</TitleSize>
                    <Iimages
                        images={trpic}
                        Bimages={trpic}
                        Cimages={trpic}
                    />
                    <Heigth /> */}
                    <GlobalBtn
                        btn_text="研修一覧に戻る〉"
                        width="15rem"
                        height="3rem"
                        color="#27282F"
                        hoverColor="#00000"
                        hoverBackgroundColor="#fbe04f"
                        navi="/recruit/traininglist"
                    />
                    <Heigth />
                </All>
            </Top>
            <RecruitFooter
                display="black"
            />
            <Footer />
        </>
    );
}
