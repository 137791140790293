
/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
import { Answer } from "./InterviewContents";
import image01 from "../../Images/recruit_work/Group 228.png"
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const All = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  opacity:1;
  animation-name:sample01;
  animation-duration:2s; 
  animation-iteration-count:1;
  @keyframes sample01 {
    0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
  }
  40%,100% {
    opacity: 1;
  }}
  @media (max-width: 768px) {
    width: 80%;
    }
`;
const Sbox = styled.div`
`;
const Sbox2 = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 5% auto 2% auto;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      margin: 20px 0;
    }
`;
const SimgBox = styled.div`
    text-align: center;
    margin: 0 30px 0 0;
    width: 150px;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Simg = styled.img`
  width: 100%;
`;
const Stext = styled.p`
    margin: 5% 0 0 0;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      font-size: 0.8rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Stitle = styled.p`
    /* margin: 0 0 0 5%; */
    font-weight: bold;
    font-size: 1.4rem;
`;
const Test = styled.span`
    font-size: 2rem;
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const WorkBkaihatu = (props) => {
  return (
    <>
        <All>
            <Sbox>
                <Sbox2>
                    <SimgBox>
                        <Simg src={image01} alt="バックエンド開発担当" />
                        <Stext>2022年入社</Stext>
                    </SimgBox>
                    <Stitle>卓越したバックエンド<Test>実装</Test></Stitle>
                </Sbox2>
            </Sbox>
            <Answer 
                color="#5CC6C0"
                title="いつ実装を経験？"
                text="バックエンド実装は、大きくAPI(プログラムと外部アプリを繋ぐ機能)の作成と、インフラ構築の2つに分けられます。私がAPI作成を経験したのは1年目の7月で、インフラ構築に関しては1年目の12月に経験しました。私はもともとプログラミングが好きで小学生の頃からプログラミングを始めました。バックエンドとインフラの開発は大学生の頃から趣味で行っていました。バックエンド実装をしている理由としては、入社時に自分がプログラミングが好きで色々な経験をしてきましたと伝えたら、「こんな案件あるけどやってみる？」や「この言語できる？」と言ってもらえ、それに応えたら色々な役がもらえるようになりました。それと、月に1回行われている面談でバックエンドをやってみたいと伝えたことがきっかけで、今はバックエンドをメイン業務にしています。"
                Btitle="バックエンドエンジニアの苦労"
                Btext="私がバックエンド実装で大変だったことは、知識がたくさん必要であるということです。お客様にサーバーのインフラ構築を説明する際に、お客様から何を聞かれても答えられるように知識を網羅的に増やす必要があったり、AWS環境を構築した時にはもっと安くできるのではないかなども考えなくてはいけないので大変でした。なので、私は調べた情報を自分の中で腹落ちさせるために、なぜこの設計でなのかを深く考え、この設計を組んでいる理由を理解できる(自分の中に落とし込める)まで調べます。その中で調べたことから派生してより深く調べて勉強していくことが大切だと思います。あとは、調べるだけじゃなくて実際に手を動かしてできることを確認することも大切です。今後は、インフラ構築を一人前にできるようになりたいと思っています。レイヴンにはインフラ構築ができる人が非常に少なく、インフラ構築ができないとサービス運用が難しくなるため、私がインフラ構築までできるようになりたいです。また、セキュリティーは重要な部分なので勉強をしっかり行い技術を身につけたいです。"
            />
        </All>
    </>
  )
}