/*====== Define import ======*/
/*------ React ------*/
import React from "react";
import { useNavigate } from "react-router-dom";

/* ==================== Define styles ==================== */
/* ---------- Styled Compornents ---------- */
import styled from "styled-components";
import { GlobalBtn } from "../Common/GlobalBtn";
//横線
const Line = styled.hr`
  border: solid 0.5px silver;
  text-align: center;
  margin-bottom:0;
`;
//タイトル
const TitleName = styled.h1`
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-weight: bold;
  padding-top: 10px;
  font-family: "Noto Sans JP", sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 1rem;
    }

`;
const Dateyear = styled.div`
  display:flex;
`
//ニュース写真
const NewsDiv = styled.div`
  width: 100%;
  margin:0 auto;
`
//ニュース写真
const NewsImage = styled.img`
  width: 60%;
  object-fit: contain;
  margin-top:2rem;
  margin-bottom:2rem;
  @media screen and (max-width: 600px) {
    width: 70%;
    }
`
const Category = styled.p`
  font-size: 0.8rem;
  padding-left:5px;
  height:20px;
  width:100px;
  color:#2e2e2e;
  font-weight: bold;
`
//本文
const Text = styled.div`
  font-size: 0.8rem;
  line-height: 1.5rem;
  letter-spacing:0.1rem;
`;
//日付けとカテゴリー
const Tops = styled.div`
  width: 80%;
  text-align:left;
`;
//日付
const Data = styled.p`
  margin: 0 1rem 0 0;
  font-size:0.8rem;
  font-weight: bold;
`;
//戻るボタン
const ReturnBtn = styled.div`
  text-align:center;
  margin: 2rem auto 2rem auto;
  width:100%;
`;
// 全体
const Total = styled.div`

  width: 70%;
  max-width: 1170px;
  background-color: white;
  margin:70px auto;
  min-height:70vh;
  height: fit-content;

`;
const SimgBox = styled.div`
    width: fit-content;
    text-align: center;
`;
/*====================*/
/*======  Main  ======*/
/*====================*/
export const Contents = (props) => {
   const navigate = useNavigate();
 
   
  
  const { date, categoly, title, text, inputimg } = props;
  return (
    <>
    

    
      <Total>
        <Tops>
          <Dateyear>
          <Data>{date}</Data>
          <Category>{categoly}</Category>
          </Dateyear>
          <TitleName>{title}</TitleName>
        </Tops>
        <Line />
        <NewsDiv>
          <SimgBox>
            <NewsImage src={inputimg} alt="ニュース写真" />       
          </SimgBox>
            <Text>{text}</Text>
         
        </NewsDiv>


      <ReturnBtn>
          <GlobalBtn
            navi="/news"
            btn_text="ニュース一覧へ 〉"
            width="12.7rem"
            height="3rem"
            color="#1C1C1C"
            hoverColor=""
            hoverBackgroundColor="#fbe04f"
            font-family="'Roboto Condensed', sans-serif"
            onClick={() => navigate('/news')}
            />
      </ReturnBtn>
   
            </Total>
    </>
  );
};