/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
import { useLocation } from "react-router-dom";
/* ---------- Style ---------- */
import styled from "styled-components";
/* ---------- components ---------- */
import { TileJp } from "../Common/TileJp";
import { RecruitMiniTitle } from "./RecruitMiniTitle";

/* ---------- Images ---------- */
import Mtng from "../../Images/Recruit_KeyVisual/KeyVisualMitunaga.jpg";
import Mzgc from "../../Images/Recruit_KeyVisual/KeyVisualMizoguchi.jpg";
import Inb from "../../Images/Recruit_KeyVisual/KeyVisualInaba.jpg";
import Fjok from "../../Images/Recruit_KeyVisual/KeyVisualFujioka.jpg";
import Szk from "../../Images/Recruit_image/appi02.jpg"
import Kdr from "../../Images/HP_ replace_2024/Interview/kodera01.jpg"
import karasu from "../../Images/Recruit_Karasu/RV_karasu_icon6-1.png";


const SWrapper = styled.ul`
    max-width: 1170px;
    width: auto;
    margin: 5vh auto;
`;
const SList = styled.ul`
    list-style: none;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
`;
const Sspan = styled.span`
    color: #5CC6C0;
`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const InterviewTransionList = (props) => {
    const location = useLocation();

    const tileList = [
        {width:'25%', paddingTop:'24.5%', title:<>光永 和功 <Sspan>/</Sspan> 開発</>, text:'2022年入社', img:Mtng, alt:'光永の画像', href:'/recruit/mitsunaga' },
        {width:'25%', paddingTop:'24.5%', title:<>溝口 和哉 <Sspan>/</Sspan> 開発</>, text:'2021年入社', img:Mzgc, alt:'溝口の画像', href:'/recruit/mizoguchi' },
        {width:'25%', paddingTop:'24.5%', title:<>稲葉 真巳 <Sspan>/</Sspan> 開発・PM/PMO</>, text:'2018年入社', img:Inb, alt:'稲葉の画像', href:'/recruit/inaba' },
        {width:'25%', paddingTop:'24.5%', title:<>藤岡 莉紗 <Sspan>/</Sspan> 開発</>, text:'2021年入社', img:Fjok, alt:'藤岡の画像', href:'/recruit/fujioka' },
        {width:'25%', paddingTop:'24.5%', title:<>鈴木 亜衣梨 <Sspan>/</Sspan> PM/PMO</>, text:'2022年入社', img:Szk, alt:'鈴木の画像', href:'/recruit/suzuki' },
        {width:'25%', paddingTop:'24.5%', title:<>小寺 みき <Sspan>/</Sspan> 開発</>, text:'2022年入社', img:Kdr, alt:'小寺の画像', href:'/recruit/kodera' },
        {width:'25%', paddingTop:'24.5%', title:'インタビュー一覧に戻る', text:'', img:karasu, alt:'一覧に戻る', href:'/recruit/interviewlist' },
    ];
    return(
        <>
            <SWrapper>
                <RecruitMiniTitle 
                    title="他のインタビュー"
                />
                <SList>
                    {
                        tileList.map((item, index) => {
                            return (
                                <TileJp
                                    key={index}
                                    width={item.width}
                                    paddingTop={item.paddingTop}
                                    title={item.title}
                                    text={item.text}
                                    img={item.img}
                                    alt={item.alt}
                                    href={item.href}
                                    location={location.pathname}
                                />
                            );
                        })
                    }
                </SList>
            </SWrapper>
        </>
    )
};