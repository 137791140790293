/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
import { TopNewsParts } from "../Top/TopNewsParts";
import {  useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { NewsBtn } from "./NewsBtn"

/*------ CSS ------*/
import styled from "styled-components";
/*------ Img ------*/
import img1 from "../../Images/PHITOP.JPG"
import img2 from "../../Images/NewsImage/Newsarticle.JPG"
import img3 from "../../Images/RMATCH.jpg"
import img4 from "../../Images/NewsImage/internship.JPG"
import img5 from "../../Images/PHITOP24.JPG"
import img6 from "../../Images/HP_Re.JPG"
import img7 from "../../Images/RMATCHUp.jpg"
import img8 from "../../Images/NewsImage/internship24.JPG"

/*====== Define styles ======*/
// 全体
const Total = styled.div`
    margin:70px auto;
    max-width: 1170px;
    width: auto;
    min-height:70vh;
    height: fit-content;
`;
// ニュース一覧
const Snews = styled.div`
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* margin: 2rem auto; */
    &::after {
        display: block;
        content:"";
        width: ${(pros) => pros.num};
    }
    @media screen and (max-width: 1169px) {
        justify-content: flex-start;
        &::after {
        display: none;
    }
    }
`;
// タイトル
const Title = styled.p`
font-size:1.2rem;
font-family: 'Roboto Condensed', sans-serif;

`;
// カテゴリー
const Category = styled.div`
    display: flex;
    margin: 0.5rem  0 0 0;
    height:3rem;
    font-family: 'Roboto Condensed', sans-serif;
    color: rgb(120,120,120);

    @media screen and (max-width: 766px) {
        display: block; 
    } 
`;
// 年度
const Year = styled.div`
    display: flex;
    margin: 0.5rem  0 0 0;
    height:3rem;
    font-family: 'Roboto Condensed', sans-serif;
    color: rgb(120,120,120);
    @media screen and (max-width: 766px) {
        display: block;
    } 
`;
const Sdiv = styled.div`
    margin-top:20px;
    @media screen and (max-width: 766px) {
        margin-top:0;
    } 

`;
const Select = styled.div`
    margin: 0 0 0 2%;
    width: 90%;
    @media (min-width: 767px) and (max-width: 1169px) {
        margin: 0 0 0 7%;
    }
    @media screen and (max-width: 766px) {
     display: flex;
     margin: 0 0 0 3%;
    }
    
    `;
//ニュース画像
const RNews = styled.div`
    list-style: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 2rem auto;
    &::after {
        display: block;
        content:"";
        width: 300px;
    }
    @media screen and (max-width: 766px) {
        margin-top:130px;
    }
    `;

export const NewsAll = (props) => {
    /*====== セッションストレージから値を取得 ======*/
    const data = sessionStorage.getItem("choiceYear") ?? 'ALL';
    const Categorydata = sessionStorage.getItem("choicecategory") ?? 'ALL';

    /*====== State ======*/
    const [newsRow, setNewsRow] = useState();
  
    const [year, setYear] = useState(data);
    const [category, setCategory] = useState(Categorydata);
    // eslint-disable-next-line no-unused-vars
    const [selected, setSelected] = useState("ALL");
    // eslint-disable-next-line no-unused-vars
    const [Yearselected, setYearSelected] = useState("ALL");
    const [num, setNum] = useState();
    const navigate = useNavigate();  
    /*====== Map ======*/
    // 種類別ボタン
    const button = [
        { name: 'name', color: 'red',label:'ALL 〉', kind: 'ALL', value: '1', id: '1', checked: true, changed: 'changed' },
        { name: 'name', color: 'red',label:'RRECRUIT', kind: 'RECRUIT', value: '2', id: '2', for: 'RECRUIT', checked: false },
        { name: 'name', color: 'red',label:'CORPORATE ', kind: 'CORPORATE', value: '3', id: '3', for: 'CORPORATE', checked: false }
    ]
    // 年度別プルダウン
    const items = [
        { id: 'ALL', value: 'ALL', label: 'ALL', click: true, checked: true, changed: 'changed' },
        { id: '2023', value: '2023', label: '2023', click: false, checked: false },
        { id: '2024', value: '2024', label: '2024', click: false, checked: false },
    ];
    // ニュース記事
    const newsData = [
        { id: '8', year: '2024', month: '05', date: '22', kind: 'CORPORATE', text: 'ホームページをリニューアルしました', img: img6 },
        { id: '7', year: '2024', month: '05', date: '07', kind: 'RECRUIT', text: 'インターンシップ開催', img: img8 },
        { id: '6', year: '2024', month: '05', date: '01', kind: 'CORPORATE', text: '自社サービス（トライアル）をアップデートしました', img: img7 },
        { id: '5', year: '2024', month: '04', date: '01', kind: 'CORPORATE', text: '入社式を行いました', img: img5 },
        { id: '1', year: '2023', month: '06', date: '26', kind: 'CORPORATE', text: '入社式を行いました', img: img1 },
        { id: '4', year: '2023', month: '06', date: '26', kind: 'RECRUIT', text: 'インターンシップ開催', img: img4 },
        { id: '2', year: '2023', month: '06', date: '26', kind: 'CORPORATE', text: 'ホームページをリニューアルしました', img: img2 },
        { id: '3', year: '2023', month: '06', date: '26', kind: 'CORPORATE', text: '自社サービスのトライアル運用開始しました', img: img3 },
    ]

    // 全ての絞り込み
    useEffect(() => {
        setNewsRow(newsData);
        setYear(data);
        setCategory(Categorydata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (year === "ALL" && category === "ALL") {// 全部
            setNewsRow(newsData);
            updataNum(newsData);
           
        } else if (year !== "ALL" && category === "ALL") {// 種別だけAll
            const filterData = newsData.filter((item) => item.year === year);
            setNewsRow(filterData);
            updataNum(filterData);
        } else if (year === "ALL" && category !== "ALL") {// 年だけAll
            const filterButtonData = newsData.filter((item) => item.kind === category);
            setNewsRow(filterButtonData);
            updataNum(filterButtonData);
        } else {// どちらもfilter
            const filterData = newsData.filter((item) => item.year === year);
            const filterDataA = filterData.filter((item) => item.kind === category);
            setNewsRow(filterDataA);
            updataNum(filterDataA);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, year])

  
    // 年度別の絞り込み
    const onClickYearButton = (e, year ) => {
        setYearSelected(e.target.value);
        setYear(year);
        sessionStorage.setItem("choiceYear",year);
       
    };
    // 種類別の絞り込み
    const onChangeSelectButton = (e, id) => {
        setSelected(e.target.value);
        setCategory(id);
        sessionStorage.setItem("choicecategory",id);
    };
    // 画面遷移とstateの値を渡す
    const onClick = (item) => {
        navigate('/newspages', {
            state: { id: item, path: '/NewsList'
             }
        })};
    // ニュース記事の配置調整
    const updataNum = (row) => {
        switch (row.length % 4) {
            case 3:
                setNum("24%");
                break;
            case 2:
                setNum("49%");
                break;
            case 1:
                setNum("73.5%");
                break;
            default:
                setNum("0%");
                break;
        }
    }

    /*====================*/
    /*======  Main  ======*/
    /*====================*/

    return (
        <>
            <Total>
                <Select>
                    <div>
                    <Title>CATEGORY</Title>
                    <Category>
                        {button.map((item, index) => (
                                <div key={index}>

                                    <NewsBtn
                                        btn_text={item.kind}
                                        width="12rem"
                                        height="3rem"
                                        color="#1C1C1C"
                                        hoverColor=""
                                        hoverBackgroundColor="#fbe04f"
                                        font-family="'Roboto Condensed', sans-serif"
                                        onClick={(e) => onChangeSelectButton(e, item.kind)}
                                        id = {category === item.kind ? 1 : 0}
                                        marginright= "20px"
                                       
                                    />
                                </div>
                        ))}
                    </Category>
                    </div>
                    <Sdiv>
                    <Title>YEAR</Title>
                    <Year>
                        {items.map((item, index) => (
                            <div key={index}>

                                <NewsBtn
                                            btn_text={item.value}
                                            width="12rem"
                                            height="3rem"
                                            color="#1C1C1C"
                                            hoverColor=""
                                            hoverBackgroundColor="#fbe04f"
                                            font-family="'Roboto Condensed', sans-serif"
                                            onClick={(e) => onClickYearButton(e, item.value)}
                                            id = {year === item.id ? 1 : 0}
                                            marginright= "20px"
                                        />
                            </div>
                        ))}
                    </Year>
                    </Sdiv>
                </Select>
                <div>
                <RNews>
                    <Snews num={num}>
                        {newsRow?.map((item, index) => (
                            <TopNewsParts
                                color={item.kind}
                                date={`${item.year}.${item.month}.${item.date}`}
                                category={item.kind}
                                p={item.text}
                                value={item.value}
                                onClick={() => onClick(item.id)}
                                img={item.img}
                                key={index}
                            />
                        ))}
                    </Snews>
                </RNews>

                </div>
            </Total>

        </>

    );
};

