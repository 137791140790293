/*====== Define import ======*/
/*------ React ------*/
import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Contents } from "./NewsTransitionParts";
/*------ Img ------*/
import img1 from "../../Images/PHITOP.JPG"
import img2 from "../../Images/NewsImage/Newsarticle.JPG"
import img3 from "../../Images/RMATCH.jpg"
import img4 from "../../Images/NewsImage/internship.JPG"
import img5 from "../../Images/PHITOP24.JPG"
import img6 from "../../Images/HP_Re.JPG"
import img7 from "../../Images/RMATCHUp.jpg"
import img8 from "../../Images/NewsImage/internship24.JPG"

/*====================*/
/*======  Main  ======*/
/*====================*/
const Sa = styled.a`
  font-size:0.8rem;
  color: #639bb7; 
`


export const Transition = () => {  
  const text = (() => {
      return (
        <>
         <p>2023年4月3日  広小路クロスタワーの名古屋営業所にて、入社式を行いました。<br/>
         入社式にて新入社員は、希望にあふれる眼差しで代表や先輩社員からの言葉を聞いていました。<br/>
         レイヴンの一員として、共に頑張ってまいります。お客様、お取引先会社様のみなさまより温かいご指導ご声援を賜りますよう、宜しくお願い申し上げます。 
         </p>
        </>         
      )
  });
  const Homapagetext =() => {
    return (
      <>
        <p>
          日頃より、株式会社レイヴンのホームページをご利用いただき、誠にありがとうございます。<br/>
          <br/>
          さて、当社ではこの度ホームページをリニューアルいたしました。<br/>
          今回のリニューアルでは、皆さまにとってより見やすく、また情報を分かりやすくお伝えできるホームページとなっております。<br/>
          さらに、社員のインタビューやリクルートページなど、今までにないコンテンツを新設し、当社のことをより深く知っていただけるような内容でございます。<br/>
          今後も定期的にホームページを更新し、皆様にご満足いただけるように努めてまいります。<br/>
          <br/>
          今後とも株式会社レイヴンをよろしくお願い致します。        
      </p>
      </>
      )

  };
  const originalservicetext = () => {
    return (
      <>
        <div>
        <p>
            この度、R MATCHをトライアルリリースいたしました。<br/>
            R MATCHは、訪問介護事業所と訪問介護を受けたい人をマッチングさせるサービスです。<br/>
            <br/>
            R MATCHには、3つの強みがあります。<br/>
            1.手軽な検索<br/>
            　介護希望者は登録されているすべての介護事業所を手軽に検索することが出来ます。<br/>
            2.豊富な検索条件<br/>
            　介護事業所がどのような介護支援が可能なのか、サービス可能時間はいつなのか、など介護希望者に合った事業所を探すことが出来ます。<br/>
            3.介護事業者の支援<br/>
            　訪問介護事業所は事業所情報と従業員情報をRMATCHに登録でき、従業員のシフト作成と介護予定作成により運営をサポートすることが出来ます。<br/>
            <br/>
            R MATCHの特徴として、事業所ごとの予定の空き情報をリアルタイムに取得することで、介護希望者がスムーズに事業所へ問い合わせを行うことが出来ます。<br/>
            そのため、介護事業所探しのために、複数の事業所に電話で何度も問い合わせ、条件に合う事業所を探す手間を大幅に削減することが可能です。<br/>
            <br/>
          </p>
            <Sa　href="https://www.rmatch.jp/" target="_blank">R MATCHへ移動する</Sa>

        </div>
        
      </>
          
      )
  };
  // const originalservicetext = () => {
  //   return (
  //     <>
  //       <div>
  //       <p>
  //           この度、R MATCHをリリースいたしました。<br/>
  //           R MATCHは、訪問介護事業所と訪問介護を受けたい人をマッチングさせるサービスです。<br/>
  //           <br/>
  //           R MATCHには、3つの強みがあります。<br/>
  //           1.手軽な検索<br/>
  //           　介護希望者は登録されているすべての介護事業所を手軽に検索することが出来ます。<br/>
  //           2.豊富な検索条件<br/>
  //           　介護事業所がどのような介護支援が可能なのか、サービス可能時間はいつなのか、など介護希望者に合った事業所を探すことが出来ます。<br/>
  //           3.介護事業者の支援<br/>
  //           　訪問介護事業所は事業所情報と従業員情報をRMATCHに登録でき、従業員のシフト作成と介護予定作成により運営をサポートすることが出来ます。<br/>
  //           <br/>
  //           R MATCHの特徴として、事業所ごとの予定の空き情報をリアルタイムに取得することで、介護希望者がスムーズに事業所へ問い合わせを行うことが出来ます。<br/>
  //           そのため、介護事業所探しのために、複数の事業所に電話で何度も問い合わせ、条件に合う事業所を探す手間を大幅に削減することが可能です。<br/>
  //           <br/>
  //         </p>
  //           <Sa　href="https://www.rmatch.jp/" target="_blank">R MATCHへ移動する</Sa>

  //       </div>
        
  //     </>
          
  //     )
  // };
  const internshiptext = () => {
    return (
           <p >
            今年も、昨年度に引き続き夏の5daysインターンシップを開催いたします。<br/>
            弊社では、8月から9月にかけてインターンシップを開催しており、昨年度は計37人もの学生が参加してくれました。<br/>
            弊社の強みは、上流工程から下流工程まで一気通貫して携われる事です。そのため、夏の5daysインターンシップでも企画・デザイン・プログラミングのすべてを体験することが出来ます。<br/>
            <br/>
            昨年は、チームでのメンバー自己紹介サイトを作成しました。<br/>
            どのようなサイト・デザインにするかと悩んだり、実際にコーディングしてみたりなど慣れない作業ながら、わからないところは社員と相談しながら進めてくれていました。また、最終日には代表も参加し学生の発表に対しアドバイスをしている場面もありました。<br/>
            <br/>
            今年もインターンシップを開催いたします。興味のある方はぜひ、<Sa href="https://job.mynavi.jp/25/pc/search/corp244491/outline.html" target="_blank" rel="noreferrer">マイナビ</Sa>からのご応募お待ちしております。
          </p>
      )
  };
  const text24 = (() => {
    return (
      <>
       <p>
        2024年4月1日  広小路クロスタワーの名古屋営業所にて、入社式を行いました。<br/>
        代表や先輩社員からの言葉を聞く新入社員の姿からは、初々しさと共に新社会人としての緊張や意気込みが強く感じられました。<br/>
        レイヴンの一員として、共に頑張ってまいります。お客様、お取引先会社様のみなさまより温かいご指導ご声援を賜りますよう、宜しくお願い申し上げます。<br/>
       </p>
      </>         
    )
});
  const Homapage24text =() => {
    return (
      <>
        <p>
          日頃より、株式会社レイヴンのホームページをご利用いただき、誠にありがとうございます。<br/>
          <br/>
          さて、当社ではこの度ホームページをリニューアルいたしました。<br/>
          今回のリニューアルでは、社員のインタビューや社内ニュース、統計データなどの追加・更新を行い、業務内容や社内の雰囲気なども含め、当社のことをより深く知っていただけるような内容になっています。<br/>
          今後も定期的にホームページを更新し、皆様にご満足いただけるように努めてまいります。<br/>
          <br/>
        </p>
      </>
      )

  };
  const originalserviceUptext = () => {
    return (
      <>
        <div>
        <p>
          弊社の自社サービスである「R MATCH」をアップデートいたしました。<br/>
          R MATCHは、訪問介護事業所と訪問介護を受けたい人をマッチングさせるサービスです。。<br/>
          <br/>
          具体的なアップデート内容は以下の通りです <br/>
          <br/>
          対象利用者：ユーザー・事業者 <br/>
          ・レスポンシブ調整<br/>
          ・バグの修正<br/>
          ・マニュアルを作成&ダウンロードできる機能を追加<br/>
          対象利用者：事業者 <br/>
          ・サービス利用料の無料期間を半年から一年に延長<br/>
          ・事業者ユーザーによるシフト登録時の機能拡大<br/>
          ・空き時間をシフトから自動判別するか、手動で入力するか選択できる機能を追加<br/>
          <br/>
          「R MATCH」の特徴は事業所ごとの予定の空き情報をリアルタイムに取得することで、介護希望者がスムーズに事業所へ問い合わせを行うことが出来ます。<br/>
          そのため、介護事業所探しのために複数の事業所に電話で何度も問い合わせ、条件に合う事業所を探す手間を大幅に削減することが可能です。<br/>
          <br/>
          </p>
            <Sa href="https://www.rmatch.jp/" target="_blank">R MATCHへ移動する</Sa>

        </div>
        
      </>
          
      )
  };
  // const originalserviceUptext = () => {
  //   return (
  //     <>
  //       <div>
  //       <p>
  //         この度、R MATCHをアップデートいたしました。<br/>
  //         R MATCHは、訪問介護事業所と訪問介護を受けたい人をマッチングさせるサービスです。<br/>
  //         <br/>
  //         具体的なアップデート内容は以下の通りです。<br/>
  //         <br/>
  //         ・レスポンシブ調整<br/>
  //         ・バグの修正<br/>
  //         ・サービス利用料の無料期間を半年から一年に延長<br/>
  //         ・事業者ユーザーによるシフト登録時の機能拡大<br/>
  //         ・マニュアルを作成&ダウンロードできる機能を追加<br/>
  //         ・空き時間をシフトから自動判別するか、手動で入力するか選択できる機能を追加<br/>
  //         <br/>
  //         R MATCHの特徴として、事業所ごとの予定の空き情報をリアルタイムに取得することで、介護希望者がスムーズに事業所へ問い合わせを行うことが出来ます。<br/>
  //         そのため、介護事業所探しのために、複数の事業所に電話で何度も問い合わせ、条件に合う事業所を探す手間を大幅に削減することが可能です。<br/>
  //         <br/>
  //         </p>
  //           <Sa　href="https://www.rmatch.jp/" target="_blank">R MATCHへ移動する</Sa>

  //       </div>
        
  //     </>
          
  //     )
  // };
  const internship24text = () => {
    return (
           <p >
            2024年も、前年に引き続き、夏の5Daysインターンシップを開催いたします。<br/>
            弊社では8月から9月にかけてインターンシップを開催しており、昨年は計18名の学生が参加しました。<br/>
            弊社の強みは企画から始まり、開発の上流工程から下流工程まで一気通貫して携われる事です。そのため夏の5daysインターンシップでも企画・デザイン・プログラミングのすべてを体験することが出来ます。<br/>
            <br/>
            昨年は参加者をチームに分け、チームごとのメンバー自己紹介サイトを作成しました。<br/>
            それぞれのチームが個性的なサイトをデザインし、そのデザインをプログラミングで再現しました。<br/>
            プログラミングやデザインに初めて挑戦する人も多く、分からないところは社員と相談しながら完成させました。<br/>
            また、最終日には社員と代表を含め座談会を行いました。インターンの感想だけでなく若手社員から就職活動のアドバイスなど行っている場面もありました。<br/>
            <br/>
            今年もインターンシップを開催いたします。興味のある方はぜひ、<Sa href="https://job.mynavi.jp/26/pc/search/corp244491/outline.html" target="_blank" rel="noreferrer">マイナビ</Sa>からのご応募お待ちしております。
          </p>
      )
  };
  // ニュース記事一覧
  const newsListData = [
    { id: '1', inputimg: img1,date: '2023.06.26', categoly: 'CORPORATE', title: '入社式を行いました', text: text()},
    { id: '2', inputimg: img2,date: '2023.06.26', categoly: 'CORPORATE', title: 'ホームページをリニューアルしました', text:Homapagetext() },
    { id: '3', inputimg: img3,date: '2023.06.26', categoly: 'CORPORATE', title: '自社サービスのトライアル運用開始しました', text:  originalservicetext() },
    // { id: '3', inputimg: img3,date: '2023.06.26', categoly: 'CORPORATE', title: '自社サービス開始しました', text:  originalservicetext() },
    { id: '4', inputimg: img4,date: '2023.06.26', categoly: 'RECRUIT', title: 'インターンシップ開催', text: internshiptext()},
    { id: '5', inputimg: img5,date: '2024.04.01', categoly: 'CORPORATE', title: '入社式を行いました', text: text24()},
    { id: '6', inputimg: img7,date: '2024.05.01', categoly: 'CORPORATE', title: '自社サービス（トライアル）をアップデートしました', text:  originalserviceUptext() },
    // { id: '6', inputimg: img7,date: '2024.05.01', categoly: 'CORPORATE', title: '自社サービスをアップデートしました', text:  originalserviceUptext() },
    { id: '7', inputimg: img8,date: '2024.05.07', categoly: 'RECRUIT', title: 'インターンシップ開催', text: internship24text()},
    { id: '8', inputimg: img6,date: '2024.05.22', categoly: 'CORPORATE', title: 'ホームページをリニューアルしました', text:Homapage24text() },
  ]
  
  // useLocationでidを取得
  const location = useLocation()
  const selectId = location.state.id;

  const selectIdData = newsListData.find((item) => item.id === selectId);

    return (
      <>
        <Contents
          img={selectIdData.img}
          inputimg={selectIdData.inputimg}
          date={selectIdData.date}
          categoly={selectIdData.categoly}
          title={selectIdData.title}
          text={selectIdData.text}
        />
      </>
    );
};