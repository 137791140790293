//=============================================================================
// Contents   : HPRP
//              研修内容22
// Author     : RV神野
// LastUpdate : 2023/6/8
// Since      : 2023/6/8
//=============================================================================
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { PgTitle } from "../../Components/Recruit/pgTitle";
import { PgTitle2 } from "../../Components/Recruit/pgTitle2";
import { GlobalBtn } from "../../Components/Common/GlobalBtn";
import { RecruitTraining22 } from "../../Components/Recruit/RecruitTraining22";
// import { Footer } from "../../Components/Common/Footer";
import { BAnswer } from "../../Components/Recruit/InterviewContents"
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { RecruitMiniTitle } from "../../Components/Recruit/RecruitMiniTitle";
import { HeadBlock } from "../../HeadBlock";

/* ====== Image ====== */
import TarningListSvg from "../../Images/RecruitSVG/TopTraining.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon3-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon3-2.png";
import schedule from "../../Images/recruit_Training/suke22.png";

/* ======================================================================== */
/* ============================ Styles ==================================== */
/* ======================================================================== */
const Top = styled.div`
margin: 60px auto;
max-width: 1170px;
width: 90%;
`;

const All = styled.div`
text-align  : center;
`;

const TitleSize = styled.h1`
margin-bottom: 40px;
font-size: 1.5rem;
`;

const Heigth = styled.div`
margin-bottom: 80px;
`;

const SImg = styled.img`
width: 90%;
`;

export const TrainingG22 = () => {

    return (
        <>
         <HeadBlock title="RECRUIT - 22卒研修 - 株式会社レイヴン" description="22卒研修の研修内容" path="TrainingG22"  />
            <PgTitle
               pgTitle={TarningListSvg}
               pgTitleName={'TopTraining'}
                describe="22卒内定者研修"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <PgTitle2
               pgTitle={TarningListSvg}
               pgTitleName={'TopTraining'}
                describe="22卒内定者研修"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <Header color="black" />
            <Top>
                <All>
                    <RecruitMiniTitle
                        title="自社ホームページ"
                    />
                </All>
            </Top>
                    <RecruitTraining22></RecruitTraining22>
            <Top>
                <All>
                    <Heigth />
                    <TitleSize>研修内容</TitleSize>
                </All>
                    <BAnswer 
                        color="#5CC6C0"
                        Ctitle="HP制作"
                        Ctext="レイヴンのHPをリニューアルするため、1から企画・デザイン・開発をしました。
                        企画・デザインは内定者全員でミーティングを行い、ファシリテーションと議事録を順番に担当しました。
                        開発では個々の勉強を活かし、また内定者内で教え合いながらコーディングしました。"
                        Dtitle="アプリ作成"
                        Dtext="入社前は新しくMicrosoftのTeamsのスマートフォンアプリをSwiftとKotlinを使用し、模倣版を作成しました。
                        SwiftやKotlinは初めて使用する言語であったため、ネットやYouTubeで調べたり、先輩社員に積極的に質問して、疑問を解消しました。
                        主にレイアウト、ログイン機能、チャット機能を作成しました。"
                        Etitle="研修で学んだこと"
                        Etext="HP作成では、ミーティングでファシリテーター(会議の司会進行役)を立てて行ったので、
                        他社員が参加しているミーティングでも、段取りを事前に考え、進行することを学びました。
                        プログラミング面では、研修で１からHPを作成したので、基礎をしっかりと押さえ入社後に即戦力としてプログラミングをすることができました。
                        スマートフォンアプリ作成では、実際に会社で研修をしていたため、プログラミングでわからないことがあった時に、先輩社員に頻繁質問しました。
                        ですが、先輩社員は私たちの研修中も業務をおこなっていたので、短時間で効率よく質問を行う工夫を学ぶことができました。このような質問力や理解する力は入社後も大切な力であるため、入社後も上達させていきたいです。"
                     />
                        <Heigth />
                    <All>
                        <TitleSize>スケジュール</TitleSize>
                        <SImg style={{ padding: '1vw 0' }} src={schedule} alt="pagetitle" />

                        {/* <Heigth />
                        <TitleSize>研修風景</TitleSize>
                        <Iimages
                            images={trpic}
                            Bimages={trpic}
                            Cimages={trpic}
                        /> */}
                        <Heigth />
                        <GlobalBtn
                            btn_text="研修一覧に戻る〉"
                            width="15rem"
                            height="3rem"
                            color="#27282F"
                            hoverColor="#00000"
                            hoverBackgroundColor="#fbe04f"
                            navi="/recruit/traininglist"
                        />
                    </All>
                    <Heigth />
            </Top>
            <RecruitFooter
                display="black"
            />
                        <Footer/>
        </>
    );
}