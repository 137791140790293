/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React, { useEffect } from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/*------ component ------*/
import { XSlider } from "../Common/XSlider";
/* ------ Library ------ */
import Vivus from "vivus";


/*------ image ------*/
import Image2201 from "../../Images/recruit_Training/train22-1.png";
import Image2202 from "../../Images/recruit_Training/train22-2.png";
import Image2203 from "../../Images/recruit_Training/train22-3.png";
import Image2204 from "../../Images/recruit_Training/train22-4.png";
import Image2205 from "../../Images/recruit_Training/train22-5.png";
import Image2206 from "../../Images/recruit_Training/train22-6.png";
import Image2207 from "../../Images/recruit_Training/train22-7.png";
import Image2208 from "../../Images/recruit_Training/train22-8.png";

/* ---------- Styled Compornents ---------- */
const Two = styled.div`
    position: relative;
`;


const SvgA = styled.svg`
    max-width:80rem;/*SVGタグの横幅*/
    width:100%;/*レスポンシブ対応にするため100%を指定*/
    height:auto;
    position: absolute;
    top: 10%;
    left: -20%;
    z-index: 5;
`;


/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */


export const RecruitTraining22 = () => {
    useEffect(() => {
        new Vivus(
            "Svg22",
            {
                duration: 100,
                // file: Svg22,
                type: "delayed",
                forceRender: false,
                animTimingFunction: Vivus.EASE,
            })
    }, []);

    return (
        <>
            <Two>
                <SvgA id="Svg22" viewBox="0 0 2 0.4" />
                <XSlider
                    Image01={Image2201}
                    Image02={Image2202}
                    Image03={Image2203}
                    Image04={Image2204}
                    Image05={Image2205}
                    Image06={Image2206}
                    Image07={Image2207}
                    Image08={Image2208}
                    width={"35rem"}
                >
                </XSlider>
            </Two>

        </>
    );
};