/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React, { useState } from 'react';
/* ---------- CSS ---------- */
import styled from 'styled-components';
/* ---------- Image ---------- */
import Arro1 from "../../Images/recruit_work/workAro01.svg";
import Arro2 from "../../Images/recruit_work/workAro02.svg";
import Arro3 from "../../Images/recruit_work/workAro03.svg";
import Arro4 from "../../Images/recruit_work/workAro04.svg";
import Arro5 from "../../Images/recruit_work/workAro05.svg";
import WorkDes1 from "../../Images/recruit_work/workDes01.svg";
import WorkDes2 from "../../Images/recruit_work/workDes02.svg";
import WorkDes3 from "../../Images/recruit_work/workDes03.svg";
import WorkDes4 from "../../Images/recruit_work/workDes04.svg";
import WorkDes5 from "../../Images/recruit_work/workDes05.svg";
/* ---------- compornent ---------- */


/*====== Define styles ======*/
/*------ Styled Components ------*/
// const Swrap = styled.div`
//     background-color: aliceblue;
// `;
const Swrap = styled.div`
    margin: 60px 0 80px 0;
`;
const Sbox1 = styled.div`
    display: flex;
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
`;
const Ssvg = styled.svg`
    width: 100%;
    transition: 0.7s;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
    @media (max-width: 299px){
        height: 50px;
    }
    @media (min-width: 300px) and (max-width: 459px) {
        height: 70px;
    }
    @media (min-width: 460px) and (max-width: 599px) {
        height: 100px;
    }
    @media (min-width: 600px) and (max-width: 700px) {
        height: 130px;
    }
`;
const StextSvg = styled.svg`
    width: 90%;
    max-width: 800px;
    height: 200px;
    @media (max-width: 299px){
        height: 70px;
    }
    @media (min-width: 300px) and (max-width: 459px) {
        height: 90px;
    }
    @media (min-width: 460px) and (max-width: 599px) {
        height: 150px;
    }
    @media (min-width: 600px) and (max-width: 700px) {
        height: 150px;
    }
`;

// max-width: 1170px;
// margin: 0 auto;
// opacity:1;
// animation-duration:2s; 
// animation-iteration-count:1;
// @keyframes sample01 {
//   0% {
//   transform: translateX(-150px);
//   opacity: 0;
// }
// 100% {
//   transform: translateX(0px);
// }
// 40%,100% {
//   opacity: 1;
// }}
const Sanim = styled.div`
    opacity:1;
`;
// 追加


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const WorkProcess = () => {
    const [data, setData] = useState(1);

    const List = [
        {id:1, src:Arro1 ,titleName:"workAro01"},
        {id:2, src:Arro2 ,titleName:"workAro02"},
        {id:3, src:Arro3 ,titleName:"workAro03"},
        {id:4, src:Arro4 ,titleName:"workAro04"},
        {id:5, src:Arro5 ,titleName:"workAro05"},
    ];

    const onClick = (item) => {
        if (item === 1) {
            setData(1)
        }else if (item === 2) {
            setData(2)
        }else if (item === 3) {
            setData(3)
        }else if (item === 4) {
            setData(4)
        }else if (item === 5) {
            setData(5)
        }
    };


    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return( 
        <> 
            <Swrap>
                <Sbox1>
                    {
                        List.map((item, index) => {
                            return(
                                <Ssvg key={index} onClick={() => {
                                    onClick(item.id)
                                }}>
                                    <use xlinkHref={`${item.src}#${item.titleName}`} />
                                </Ssvg>
                            )
                        })
                    }
                </Sbox1>
                <Sanim>
                    {
                        data === 1 ?
                            <StextSvg>
                                <use xlinkHref={`${WorkDes1}#${"workDes01"}`}/>
                            </StextSvg>
                        : data === 2 ?
                            <StextSvg>
                                <use xlinkHref={`${WorkDes2}#${"workDes02"}`} />
                            </StextSvg>
                        : data === 3 ?
                            <StextSvg>
                                <use xlinkHref={`${WorkDes3}#${"workDes03"}`} />
                            </StextSvg>
                        : data === 4 ?
                            <StextSvg>
                                <use xlinkHref={`${WorkDes4}#${"workDes04"}`} />
                            </StextSvg>
                        : data === 5 ?
                            <StextSvg>
                                <use xlinkHref={`${WorkDes5}#${"workDes05"}`} />
                            </StextSvg>
                        : ''
                    }
                </Sanim>
            </Swrap>
        </>
    );
};