/*====== Define import ======*/
import React from "react";
import styled from "styled-components";
/* ---------- components ---------- */
import { Header } from "../../Components/Common/Header";
import { SubVisual } from "../../Components/Common/SubVisual";
import { ServiceInterviewVisual } from "../../Components/Service/ServiceInterviewVisual";
import { ServiceInterviewSentence } from "../../Components/Service/ServiceInterviewSentence";
import { ServiceInterviewBtnB } from "../../Components/Service/ServiceInterviewBtnB";
import { Footer } from "../../Components/Common/Footer";
/* ---------- Images ---------- */
import serviceHeaderImg from '../../Images/businessHeaderImg.png';
import Img01 from "../../Images/man/IMG_1173.JPG";
import Img02 from "../../Images/IMG_1165 (1) 2.jpg";

import personImage from "../../Images/suzukisan.png"
import { InterviewTitle } from "../../Components/Service/InterviewTitle";
import { ServiceInterviewBusiness } from "../../Components/Service/ServiceInterview Business";
import busi1 from '../../Images/busi1.svg';
import busi2 from '../../Images/Service_Interview/busi2.svg';
import business3 from '../../Images/Service_Interview/business3.svg'
import { HeadBlock } from "../../HeadBlock";
/*------ Styled Components ------*/
const ScolorWrapper = styled.div`
/* background-color: rgb(250,250,250); */
background-color:white;
margin-top: 15%;
padding-bottom: 5%;
`;

const SinterviewWrapper = styled.div`
margin: 0 auto;
max-width: 1170px;
width: 90%;
position: relative;

@media (min-width: 769px) and (max-width: 1169px) {
}
@media (min-width: 600px) and (max-width: 768px) {
}
@media (min-width: 350px) and (max-width: 599px) {
}
`;


const Center = styled.div`
    margin: 80px auto;
    text-align: center;
    width: 80%;
`
const All = styled.div`
    max-width: 1170px;
    width: auto;
    margin: 0 auto;
`;


const Snummber = styled.h2`
    position: relative;
    right: 13px;
    top:-20px;
    text-align: center;
    z-index: 10;
    display: block;
    padding: 2rem 0 2rem 2rem;
    font-size: 1.4rem;
`

const Info = styled.h2`
    font-size: 1.4rem;
`
const Svg = styled.svg`
    max-width: 1170px;
    width: 100%;
    /* #### 850px以下 ### */
    @media (max-width: 850px) {
        width:100%;
    }
    /* #### 400px以下 ### */
    @media (max-width: 500px) {
        width: 100%;
    }
    /* margin-left: 3rem; */
    /* height: -webkit-fill-available;
    width: -webkit-fill-available;
    display: flex; */
    /* width: 80%; */
    /* margin: -10rem auto; */
`
const Svg1 = styled.svg`
    max-width: 1170px;
    width: 100%;
    /* #### 850px以下 ### */
    @media (max-width: 850px) {
        width: 100%;
    }
    /* #### 400px以下 ### */
    @media (max-width: 500px) {
        width: 100%;
    }
    /* margin-left: 3rem; */
    /* height: -webkit-fill-available;
    width: -webkit-fill-available;
    display: flex; */
    /* width: 80%; */
    /* margin: -10rem auto; */
`

const Set = styled.div`
    margin-top: 2rem;
`;
const Infoma = styled.h2`
    font-size: 1.4rem;
`;
const Sborder = styled.div`
    width: 170px;
    height: 20px;
    margin: -60px auto 0 auto;
    background-color: #FBE04F;
    opacity: 0.5;
`;
const Snummber3 = styled.h2`
    position: relative;
    top:-20px;
    text-align: center;
    z-index: 10;
    display: block;
    padding: 2rem 0 0 0;
    font-size: 1.4rem;
`
const Snummber5 = styled.h2`
    position: relative;
    top:-40px;
    text-align: center;
    z-index: 10;
    display: block;
    padding: 2rem 0 0 0;
    font-size: 1.4rem;
`
const Sborder3 = styled.div`
    width: 180px;
    height: 20px;
    margin: -50px auto 0 auto;
    background-color: #FBE04F;
    opacity: 0.5;
`;
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Business = () => {
    // 改行
    const msg1 = `新しいIT サービスの現場運用を\nゼロベースから設計・構築`
    const text = msg1.split(/(\n)/).map((item, index) => {
        return (
            <div key={index}>
                {item.match(/\n/) ? <br /> : item}
            </div>
        )
    });
    /*------ 本文の部品化 ------*/
    const sentenceList = [
        { sentenceTitle: "お仕事内容について教えてください。", sentence: "ITサービスをメインに、新規ビジネス・サービスの運用現場を設計・構築しています。 運用では、主に「運用要件定義」、「運用構築」を行います。はじめに、運用要件定義を行います。ここでは、サービスの規模感を把握します。例えば、お客様がオペレーターへ要望を出し対応を行うもの、サービスを使用する前の問い合わせ、 申し込みをしたいお客様はどうすればよいのかなど、一つひとつのアクションに運用の種類を決めていきます。 このようなざっくりとした運用の流れを決めることが運用要件定義です。 つぎに運用構築を行います。 ここでは、運用要件定義で決めたことをフロー図に落とし込んでいきます。 フロー図は実際にサービスを使用するお客様も見るものなので、誰が見てもわかるようにしなければなりません。 運用要件定義ではざっくりと決めていたことを、運用構築では関わる人の一挙手一投足すべてを決めていき、 サービスが机上の空論とならないよう開発側と連携し運用を行います。", type: "true", img: Img01, alt: "鈴木さん" },
        { sentenceTitle: "どういうところにやりがいを感じますか？", sentence: "表立って評価される仕事ではないですが、陰ながら支えている、「縁の下の力持ち」であるところにやりがいを感じます。いくらサービスが良くても、サービスを販売している人やコールセンターの対応が悪いとサービスそのものが悪くなってしまうと考えています。その点の質を向上させるための私の仕事は他の人からはあまり関心を持たれることはありませんがとても重要な役割であると自負しています。" },
        { sentenceTitle: "ビジネス構築事業で大変だったことは何ですか？", sentence: "お客様に運用構築の重要さを認識してもらい、仕事として取ってくることです。サービスを提供する上で、サービスを滞りなく回すための人や業務の設計をする運用構築という点、実際に世の中に提供する中で人が運用を回していくという点もすべてが重要です。その重要さにサービスを出す直前まで気づいていただけないケースが大半で、なるべく早く認識していただく必要があると考えています。そのため、少しでも早く営業込みで運用の必要性を伝えるようにしています。そこが大変であり、楽しい部分でもあります。" },
        { sentenceTitle: "どのような人がビジネス構築事業に向いていますか？", sentence: "物事を俯瞰して考えられる人です。ビジネス構築事業では、運用の流れを様々な点を踏まえて考えることが重要です。運用をしていく上で開発事業部や他会社など様々な業務に携わる方が存在し、その方達の意見をまとめ、最適解を出すことが求められます。そのため、自分だけでなく様々な観点や都合を考えられる人が向いています。また、運用の流れを考える点において細かいところまで気がつく人も向いていると思います。", type: "true", img: Img02, alt: "鈴木さん" },
    ]
    return (
        <>
            <HeadBlock title='SERVICE - ビジネス構築事業 - 株式会社レイヴン' description='ビジネス構築事業は、新規サービスの運用現場を構築し、既存サービスの運用現場を改善します。サービスを運営するお客様の要件を広く深く定義し、安定した土台の上に合理性と効率性の高い運用を構築します。' path='Business' />
            <Header color="white" />
            <SubVisual
                Image={serviceHeaderImg}
                sub_titleEn="SERVICE"
                subTitleJa="サービス"
            />

            <ServiceInterviewVisual
                // Img={Img}
                sctsubttl="ビジネス構築事業"
                content={text}
                content2="新規サービスの運用現場を構築し、既存サービスの運用現場を改善します。サービスを運営するお客様の要件を広く深く定義し、安定した土台の上に合理性と効率性の高い運用を構築します。"
            />
            <All>
                <Center>
                    <ServiceInterviewBusiness
                        nummber='1'
                        text='サービス企画'
                        p='ITサービスを企画し、企画した内容をドキュメント化します。サービス内容の企画だけでなく、サービスの提供に必要なシステムや運用、サービスの販促・営業面の企画を実施します。企画書の読み手（企画書を参照する後工程の部門）が必要な情報を広く深く企画できます。'
                        text1='組織を横断してPMやPMが所属する組織の支援をする役割'
                    />
                    <Svg1 viewBox="0 0 406 163"><use xlinkHref={`${busi1}#busi1`}></use></Svg1>
                    <Set>
                        <Infoma>必要な情報を広く深く</Infoma>
                        <Snummber>企画できます</Snummber>
                    </Set>
                    <Sborder></Sborder>
                    <ServiceInterviewBusiness
                        nummber='2'
                        text='サービス運用構築'
                        p='サービス運用構築ではサービス提供時のオペレーションを設計します。サービスにおける各種業務の詳細な流れを設計し、業務で用いる帳票やツールを作成します。後工程となる運用部門への業務内容の落とし込みも実施します。'
                    />
                    <Svg viewBox="0 0 900 378"><use xlinkHref={`${busi2}#busi2`}></use></Svg>
                    <Set>
                        <Info>すべてのステークホルダーにとって</Info>
                        <Snummber3>合理的かつ効率的な運用を</Snummber3>
                        <Snummber5>構築できます</Snummber5>
                    </Set>
                    <Sborder3></Sborder3>
                    <ServiceInterviewBusiness
                        nummber='3'
                        text='サービス運用'
                        p='お客様からの問い合わせへの対応といったサービス提供に伴うオペレーションを遂行します。フロントオフィス業務（お客様と直接関わる業務）、バックオフィス業務（サービス提供を支える事務業務）、B2C業務（一般向け運用業務）、B2B業務（法人向け運用業務）'
                    />
                    <Svg viewBox="0 0 900 450"><use xlinkHref={`${business3}#business3`}></use></Svg>
                    <Set>
                        <Info>フロントオフィスとバックオフィス、</Info>
                        <Snummber3>B2CとB2B、様々な運用業務に</Snummber3>
                        <Snummber5>対応可能です</Snummber5>
                    </Set>
                    <Sborder3></Sborder3>
                </Center>
                <ScolorWrapper>
                    <SinterviewWrapper>
                        <InterviewTitle
                            title="INTERVIEW"
                            inputimg={personImage}
                            name="鈴木 大裕 / Daisuke  Suzuki"
                            workhistory="2016年入社　PM / PMO事業部"

                        />
                        {
                            sentenceList.map((sentenceItem, index) => {
                                return (
                                    <ServiceInterviewSentence
                                        sentenceTitle={sentenceItem.sentenceTitle}
                                        sentence={sentenceItem.sentence}
                                        type={sentenceItem.type}
                                        img={sentenceItem.img}
                                        alt={sentenceItem.alt}
                                        key={index}
                                    />
                                )
                            })
                        }
                        {/* <SsheduleWrapper>
                        <SsheduleTitle>ONE DAY<br />SCHEDULE</SsheduleTitle>
                        <SsheduleImg src={schedule} />
                    </SsheduleWrapper> */}
                        <ServiceInterviewBtnB />
                    </SinterviewWrapper>
                </ScolorWrapper>
            </All>
            <Footer color="white" />
        </>
    );
}