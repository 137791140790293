
/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
import { Answer } from "./InterviewContents";
import image01 from "../../Images/recruit_work/Group 226.png"
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const All = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  opacity:1;
  animation-name:sample01;
  animation-duration:2s; 
  animation-iteration-count:1;
  @keyframes sample01 {
    0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
  }
  40%,100% {
    opacity: 1;
  }}
  @media (max-width: 768px) {
    width: 80%;
    }
`;
const Sbox = styled.div`
`;
const Sbox2 = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 5% auto 2% auto;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      margin: 20px 0;
    }
`;
const SimgBox = styled.div`
    text-align: center;
    margin: 0 30px 0 0;
    width: 150px;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Simg = styled.img`
  width: 100%;
`;
const Stext = styled.p`
    margin: 5% 0 0 0;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      font-size: 0.8rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Stitle = styled.p`
    /* margin: 0 0 0 5%; */
    font-weight: bold;
    font-size: 1.4rem;
`;
const Test = styled.span`
    font-size: 2rem;
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const WorkSekei = (props) => {
  return (
    <>
        <All>
            <Sbox>
                <Sbox2>
                    <SimgBox>
                        <Simg src={image01} alt="設計担当"/>
                        <Stext>2021年入社</Stext>
                    </SimgBox>
                    <Stitle>器用にこなす<Test>設計</Test></Stitle>
                </Sbox2>
            </Sbox>
            <Answer 
                color="#5CC6C0"
                title="フロントエンドの設計"
                text="設計は、大きくフロントエンドとバックエンドの２つに分けることができます。私がフロントエンドの設計をしたのは、1年目の6月頃でした。グループ自体の人数も少なかったため、やるしかなかったという状況でした。入社したばかりでわからないことも多々ありましたが、山田代表と一緒に切磋琢磨し、業務に臨んでいました。特に、大変だった業務はコーディングです。フロントエンドの設計では、システムのプロトタイプを作成します。ですが、私はタイピングもできない初心者でしたし、Reactという言語を使ってプログラムを書いていました。React自体、会社で初導入だった言語で、全て独学で学ばなければいけなかったことが大変でした。ただ、プログラミングを少し経験してみると、だんだん楽しくなってきて、2年目になる頃にはバックエンドの方も任されるようになってきました。"
                Btitle="バックエンドの設計"
                Btext="バックエンドの設計は、2年目の4月頃に経験しました。バックエンドに関しては、自ら名乗り出て学び始め、入社前からシステムを1人で作り上げたいという目標を持っていたため、フロントエンドを一通り学んだ後にバックエンドを学び始めました。バックエンドの設計も山田代表に教えてもらいながら作業をしていました。バックエンドの設計はフロントエンドほど苦労はしませんでした。ですが、データをどのように保存するのが一番効率が良いのかなどの仕様を考えることが大変でした。フロントエンドとは違い周りに教えてくれる人がいたので、フロントエンドほど大変ではありませんでした。"
            />
        </All>
    </>
  )
}