/*====== Define import ======*/
/*------ React ------*/
import { React, useEffect } from "react";
/*------ CSS ------*/
import styled from "styled-components";
/*------ Link ------*/
import { Link } from "react-router-dom";

/*====== Define styles ======*/
/*------ Styled Components ------*/
const SLi = styled.li`
    list-style: none;
    flex:auto;
    line-height:1.5rem;
`;
const StyledLink = styled(Link)`
    position: relative;
    padding: 0 0 3px 0;
    text-decoration: none;
    /* color: ${(props) => props.to === props.pathname ? "blue" : "red"}; */
    &::after{
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background: ${(props) => props.color};
        bottom: -0.1vh;
        transform: ${(props) => props.pathname.indexOf(props.to) !== -1 ? "scale(0.8,1.2)" : "scale(0,1.2)" };
        transform-origin:  left top;
        transition: transform 0.3s;
    }
    &:hover::after{
        transform: scale(0.8,1.2);
    }
    /* #### 1169px以下 #### */
    @media (max-width: 1001px)  {
        &::after{
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background: ${(props) => props.color};
        bottom: -0.1vh;
        transform: scale(0,1.2);
        transform-origin:  left top;
        transition: transform 0.3s;
        }
        &:hover::after{
            transform: scale(0,1.2);
        }
    }
`;
const Stitle = styled.p`
    font-size:1rem;
    letter-spacing:0.1em;
    padding: 5px 0 0 0;
    color: ${(props) => props.color};
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
     /* #### 1169px以下 #### */
    @media (max-width: 1001px)  {
        color: ${(props) => props.to === props.pathname ? "#fbe04f" : props.color };
    }
`;
const Ssubtitle = styled.p`
    font-size:0.6rem;
    letter-spacing:0.1em;
    padding: 0 0 5px 0;
    
    color: ${(props) => props.color};
     /* #### 1169px以下 #### */
    @media (max-width: 1001px)  {
        color: ${(props) => props.to === props.pathname ? "#fbe04f" : props.color };
    }
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/

/*====================*/
/*======  Main  ======*/
/*====================*/
export const GlobalNavParts = (props) => {

    /*====== variables ======*/
    const {route, itemEn, itemJp, color, pathname } = props;

    
    useEffect(()=>{

        //ニュースページ以外に遷移した場合、セッションストレージを空にする
        //それ以外は、値を保持した状態に遷移する
        if(pathname !==  "/news" && pathname !==  "/newspages" ){
            sessionStorage.removeItem('choicecategory');
            sessionStorage.removeItem('choiceYear');
        }; 

        if(pathname !==  "/about"){
            sessionStorage.removeItem('categorydata');
        };
        
        return () => window.scrollTo(0,0);

    },[pathname]);

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/
    return(
        <>
            <SLi>
                <StyledLink to={route} color={color} pathname={pathname} >
                    <Stitle to={route} color={color} pathname={pathname}>{itemEn}</Stitle>
                    <Ssubtitle to={route} color={color} pathname={pathname}>{itemJp}</Ssubtitle>
                </StyledLink>
            </SLi>
        </>
    )
};