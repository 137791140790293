
/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
import { Answer } from "./InterviewContents";
import image01 from "../../Images/recruit_work/Group 225.png"
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const All = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  opacity:1;
  animation-name:sample01;
  animation-duration:2s; 
  animation-iteration-count:1;
  @keyframes sample01 {
    0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
  }
  40%,100% {
    opacity: 1;
  }}
  @media (max-width: 768px) {
    width: 80%;
    }
`;
const Sbox = styled.div`
`;
const Sbox2 = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 5% auto 2% auto;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      margin: 20px 0;
    }
`;
const SimgBox = styled.div`
    text-align: center;
    margin: 0 30px 0 0;
    width: 150px;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Simg = styled.img`
  width: 100%;
`;
const Stext = styled.p`
    margin: 5% 0 0 0;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      font-size: 0.8rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Stitle = styled.p`
    /* margin: 0 0 0 5%; */
    font-weight: bold;
    font-size: 1.4rem;
`;
const Test = styled.span`
    font-size: 2rem;
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const WorkTeigi = (props) => {
  return (
    <>
        <All>
            <Sbox>
                <Sbox2>
                    <SimgBox>
                        <Simg src={image01} alt="要件定義担当"/>
                        <Stext>2021年入社</Stext>
                    </SimgBox>
                    <Stitle>リーダーとしての<Test>要件定義</Test></Stitle>
                </Sbox2>
            </Sbox>
            <Answer 
                color="#FFEF61"
                title="いつ要件定義を経験？"
                text="私が要件定義を初めて経験したのは、1年目の8月頃でした。経験当初は案件メンバーの一員としてお客様との会議に参加していました。案件リーダーとして要件定義したのは、2年目の4月頃からです。この頃からリーダーを任せられるようになり、案件を引っ張らなければならないという気持ちで要件定義に臨んでいました。1年目の8月頃から経験していたのもあり、リーダーを任される頃にはある程度お客さんと話せる状態だったので、早くに経験を積んでおいて良かったです。"
                Btitle="案件リーダーとしての成長"
                Btext="実際に案件リーダーとして要件定義を経験した案件では、取引先の業務システムを作成することが多かったです。取引先のお客様と会議するときにお客様の業務が忙しいと、確認していただく時間が取れないため、そういう時の対応に苦労しました。なので、お客様の要望に合わせて要件定義を進めていくことに努めてきました。例えば、お客様が業務システムの画面を見ながら要件定義を進めていきたいと要望するのであれば、先にモック画面(実際の業務システムを模倣した画面)を作成して、ある程度どのような画面になるのかを見てもらった上で要件定義を進めました。また、お客様が多様な要望を追加してくることがあったので、その時は予算や納期、お客様のスケジュールのことも考えて調整するという作業が大変でした。そのような場合は、両者の折り合いがつくところを探して解決していました。"
            />
        </All>
    </>
  )
}