//=============================================================================
// Contents   : HPRP
//              ゆたかさって何？
// Author     : RV太田
// LastUpdate : 2023/6/6
// Since      : 2023/6/6
//=============================================================================

/* ======================================================================================================================================================= */  
/* ==================================================================== Start Of Program ================================================================= */  
/* ======================================================================================================================================================= */ 


/* ======================================================================== */  
/* ============================ import ==================================== */  
/* ======================================================================== */  
/* ====== React ====== */
import React from "react";
import { BiChevronsDown } from "react-icons/bi";

/* ====== CSS ====== */
import styled from "styled-components";
/* ====== Image ====== */
// import RichnessSvg from "../../Images/RecruitSVG/TopRichness.svg";
import RichnessSvg_2 from "../../Images/RecruitSVG/TopRichness_2.svg";
import RichnessSvg from "../../Images/RecruitSVG/TopRichness.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon2-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon2-2.png";
import Group from "../../Images/Recruit_Karasu/Rkarasu.png";
import rich1 from "../../Images/Recruit_Karasu/rich1.png";
import rich2 from "../../Images/Recruit_Karasu/rich2.png";
import rich3 from "../../Images/Recruit_Karasu/rich3.png";
import rich4 from "../../Images/Recruit_png/Karasu_icon7-1.png";
import batu from "../../Images/Recruit_png/Batu.png";
import free1 from "../../Images/Recruit_png/Freedom1.png";
import free2 from "../../Images/Recruit_png/Freedom2.png";
import free3 from "../../Images/Recruit_png/Freedom3.png";
/* ====== Component Js/Jsx ====== */
import { Header } from "../../Components/Common/Header";
import { PgTitle2 } from "../../Components/Recruit/pgTitle2";
import { PgTitle } from "../../Components/Recruit/pgTitle";
import { Footer } from "../../Components/Common/Footer";
import { RecruitCard } from "../../Components/Common/RecruitCard";
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { HeadBlock } from "../../HeadBlock";
/* ====== Style ====== */
/* ======================================================================== */  
/* ============================ Styles ==================================== */  
/* ======================================================================== */
const SyutakaBox = styled.div`
    width: fit-content;
    margin: 80px auto 60px auto;
`;
const Syutaka = styled.h1`
    width: fit-content;
    padding: 0 10px 5px 10px;
    color: #5CC6C0;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 900;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
    }   
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170px以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const SyutakaSmall = styled.span`
    font-size: 1.5rem;
    font-weight: normal;
    /* #### 440px以下 ### */
    @media (max-width: 440px) {
        font-size: 1.2rem;
    }
`;
const SyutakaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
`;
const SkeizaiBox = styled.div`
    width: 30%;
    max-width: 333.45px;
`;
const Skeizaihead = styled.div`
    background-color: #FFEF61;
    height: 160px;
    border-radius: 30px 30px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    /* ### 760px以下 ### */
    @media (max-width: 760px) {
        flex-direction: column;
        padding-top: 8px;
        border-radius: 20px 20px 0 0;
    }
    /* #### 440px以下 ### */
    @media (max-width: 440px) {
        height: 140px;
    }
`;
const Skeizaigroup = styled.div`
    margin: 0 6px 0 16px;
    /* ### 760px以下 ### */
    @media (max-width: 760px) {
        display: flex;
        margin-bottom: 8px;
    }
    /* #### 680px以下 ### */
    @media (max-width: 760px) {
        margin-top: 8px;
        margin-bottom: 12px;
    }
    /* #### 440px以下 ### */
    @media (max-width: 440px) {
        margin-left: 8px;
    }
`;
const Skeizai = styled.h2`
    font-size: 40px;
    /* ### 925px以下 ### */
    @media (max-width: 925px) {
        font-size: 32px;
    }
    /* ### 860px以下 ### */
    @media (max-width: 860px) {
        font-size: 28px;
    }
    /* ### 530px以下 ### */
    @media (max-width: 530px) {
        font-size: 24px;
    }
    /* ### 400px以下 ### */
    @media (max-width: 400px) {
        font-size: 20px;
    }
    /* ### 335px以下 ### */
    @media (max-width: 335px) {
        font-size: 18px;
    }
    /* ### 310px以下 ### */
    @media (max-width: 310px) {
        font-size: 17px;
    }
`;
const Sfree = styled.h2`
    margin: 12px 0 0 0;
    font-size: 20px;
    text-align: right;
    /* ### 925px以下 ### */
    @media (max-width: 925px) {
        font-size: 16px;
    }
    /* ### 760px以下 ### */
    @media (max-width: 760px) {
        padding-top: 2px;
        margin-left: 2px;
    }
    /* #### 680px以下 ### */
    @media (max-width: 760px) {
        margin-top: 10px;
        font-size: 14px;
    }
    /* ### 530px以下 ### */
    @media (max-width: 530px) {
        font-size: 12px;
    }
    /* ### 400px以下 ### */
    @media (max-width: 400px) {
        margin-top: 6px;
    }
    /* ### 335px以下 ### */
    @media (max-width: 335px) {
        font-size: 11px;
        margin-top: 5px;
        margin-left: 1px;
    }
    /* ### 310px以下 ### */
    @media (max-width: 310px) {
        margin-top: 3px;
    }
`;
const SkarasuImg = styled.img`
    width: 40%;
    min-width: 100px;
    margin: 0 8px 8px 12px;
    /* #### 440px以下 ### */
    @media (max-width: 440px) {
        min-width: 80px;
    }
`;
const StextBox = styled.div`
    display: flex;
    justify-content: center;
    background-color: #5CC6C0;
    border-radius: 0 0 30px 30px;
    /* #### 760px以下 ### */
    @media (max-width: 760px) {
        border-radius: 0 0 20px 20px;
    }
`;
const Stext = styled.p`
    display: grid;
    place-items: center;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
    height: 86px;
    margin: 0 12px;
    /* #### 960px以下 ### */
    @media (max-width: 960px) {
        font-size: 14px;
    }
    /* #### 530px以下 ### */
    @media (max-width: 530px) {
        font-size: 11px;
        line-height: 16px;
    }
    /* #### 450px以下 ### */
    @media (max-width: 450px) {
        font-weight: normal;
        text-align: left;
    }
    /* #### 439px以下 ### */
    @media (max-width: 439px) {
        letter-spacing: 0px;
    }
    /* #### 409px以下 ### */
    @media (max-width: 409px) {
        height: 100px;
    }
    /* #### 373px以下 ### */
    @media (max-width: 373px) {
        height: 120px;
    }
`;
const SbatuImg = styled.img`
    margin-top: 112px;
    margin-left: 1px;
    width: 28px;
    height: 28px;
    /* #### 1000px以下 ### */
    @media (max-width: 1000px) {
        margin-top: 116px;
        width: 24px;
        height: 24px;
    }
    /* #### 925px以下 ### */
    @media (max-width: 925px) {
        margin-top: 116px;
        width: 20px;
        height: 20px;
    }
    /* #### 760px以下 ### */
    @media (max-width: 760px) {
        margin-top: 116px;
        width: 16px;
        height: 16px;
    }
    /* #### 480px以下 ### */
    @media (max-width: 480px) {
        margin-top: 120px;
        width: 12px;
        height: 12px;
    }
    /* #### 440px以下 ### */
    @media (max-width: 440px) {
        margin-top: 108px;
    }
    /* #### 409px以下 ### */
    @media (max-width: 409px) {
        margin-top: 116px;
    }
    /* #### 380px以下 ### */
    @media (max-width: 380px) {
        width: 10px;
        height: 10px;
    }
    /* #### 373px以下 ### */
    @media (max-width: 373px) {
        margin-top: 132px;
    }
`;
const Syutakatextflame = styled.div`
    text-align: center;
`;
const SyutakaTextWrapper = styled.div`
    display: inline-block;
    margin: 0 48px;
    margin-top: 48px;
    padding: 8px 16px;
    text-align: center;
    border: 2px solid #5CC6C0;
    /* #### 450px以下 ### */
    @media (max-width: 450px) {
        margin: 48px 16px 0 16px;
    }
`;
const SyutakaText = styled.p`
    margin: auto;
    line-height: 1.6em;
    font-size: 18px;
    /* #### 850px以下 ### */
    @media (max-width: 850px) {
        font-size: 16px;
    }
    /* #### 775px以下 ### */
    @media (max-width: 775px) {
        font-size: 14px;
    }
    /* #### 695px以下 ### */
    @media (max-width: 695px) {
        font-size: 12px;
    }
    /* #### 450px以下 ### */
    @media (max-width: 450px) {
        text-align: left;
    }
`;
const Showyutaka = styled.div`
    margin-top: 47px;
    padding-top: 47px;
    padding-bottom: 47px;
    background-color: #FEF9DC;
    display: grid;
    place-items: center;
`;
const Showyutakahead = styled.div`
    margin: 0 auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 136px;
    background-color: #FFB21B;
    width: 66%;
    /* #### 1070px以下 ### */
    @media (max-width: 1070px) {
        height: 120px;
    }
    /* #### 960px以下 ### */
        @media (max-width: 960px) {
        height: 100px;
    }
    /* #### 835px以下 ### */
        @media (max-width: 835px) {
        height: 92px;
    }
    /* #### 755px以下 ### */
        @media (max-width: 755px) {
        width: 498.3px;
    }
    /* #### 570px以下 ### */
        @media (max-width: 570px) {
        width: 87.5%;
    }
    /* #### 450px以下 ### */
    @media (max-width: 450px) {
        width: auto;
        margin: 0 16px;
    }
`;
const ShowyutakaImg = styled.img`
    width: 12%;
    min-width: 55px;
    margin-left: 12px;
`;
const Showyutakaheadtext = styled.h2`
    font-size: 24px;
    margin: 0 12px;
    /* #### 1070px以下 ### */
    @media (max-width: 1070px) {
        font-size: 22px;
        margin: 0 8px;
    }
    /* #### 960px以下 ### */
    @media (max-width: 960px) {
        font-size: 21px;
    }
    /* #### 920px以下 ### */
    @media (max-width: 920px) {
        font-size: 19px;
    }
    /* #### 835px以下 ### */
    @media (max-width: 835px) {
        font-size: 17px;
    }
`;
const Showyutakacontents = styled.div`
    margin-top: 24px;
    text-align: center;
`;
const Showyutakatext1 = styled.p`
    margin: 0 28px;
    font-size: 20px;
    line-height: 32px;
    /* #### 775px以下 ### */
    @media (max-width: 775px) {
        font-size: 18px;
        line-height: 28px;
    }
    /* #### 705px以下 ### */
    @media (max-width: 705px) {
        font-size: 16px;
        line-height: 24px;
    }
    /* #### 630px以下 ### */
    @media (max-width: 630px) {
        font-size: 14px;
        line-height: 20px;
    }
    /* #### 450px以下 ### */
    @media (max-width: 450px) {
        width: auto;
        margin: 0 16px;
        text-align: left;
    }
`;
const Sbusiness = styled.span`
    color: red;
    font-size: 28px;
    font-weight: 900;
    /* #### 775px以下 ### */
    @media (max-width: 775px) {
        font-size: 24px;
    }
    /* #### 705px以下 ### */
    @media (max-width: 705px) {
        font-size: 20px;
    }
    /* #### 630px以下 ### */
    @media (max-width: 630px) {
        font-size: 18px;
    }
`;
const Showyutakagroups = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 31px;
    margin-bottom: 47px;
    width: 100%;
    max-width: 1170px;
`;
const Syutakagroup = styled.div`
    display: flex;
    background-color: #2EBFB7;
    width: 30%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    /* ### 760px以下 ### */
    @media (max-width: 760px) {
        flex-direction: column;
    }
`;
const ShowyutakagroupImg = styled.img`
    width: 30%;
    min-width: 80px;
    margin: 8px 0 8px 8px;
    /* ### 760px以下 ### */
    @media (max-width: 760px) {
        margin: 8px 8px 8px 8px;
    }
`;
const Showyutakagrouptext = styled.p`
    margin: 0 8px;
    font-weight: 900;
    line-height: 20px;
    /* ### 880px以下 ### */
    @media (max-width: 880px) {
        font-size: 14px;
    }
    /* ### 815px以下 ### */
    @media (max-width: 815px) {
        font-size: 13px;
    }
    /* ### 780px以下 ### */
    @media (max-width: 780px) {
        font-size: 12px;
        line-height: 16px;
    }
    /* ### 760px以下 ### */
    @media (max-width: 760px) {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        height: 48px;
    }
    /* ### 455px以下 ### */
    @media (max-width: 455px) {
        font-size: 11px;
    }
    /* ### 450px以下 ### */
    @media (max-width: 450px) {
        font-weight: normal;
        text-align: left;
    }
    /* ### 419px以下 ### */
    @media (max-width: 419px) {
        height: 64px;
    }
    /* ### 346px以下 ### */
    @media (max-width: 346px) {
        height: 80px;
    }
`;
const SimgWrap = styled.div`
    max-width: 1170px;
    margin: 0 auto;
    margin-top: 51px;
    text-align: center;
`;
const SgroupImg = styled.img`
    width: 50%;
`;
const Sbr = styled.br`
    /* ### 450px以下 ### */
    @media (max-width: 450px) {
       display: none;   
    }
`;

/* ======================================================================== */  
/* ================== global variables/functions ========================== */  
/* ======================================================================== */

/* ======================================================================== */  
/* ============================ Main ====================================== */  
/* ======================================================================== */  

/* ========= 一覧テンプレート ========= */
export const Richness = (props) => {
    /* ---------------------------------------------------- */
    /* ------------------ Instance ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ------------------ variables ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ---------------------- state ----------------------- */
    /* ---------------------------------------------------- */

    /* ----------------------------------------------------- */
    /* ----------------- reack-hook-form ------------------- */
    /* ----------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ---------------------- function -------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- data ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- jsx ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* -------------------- Start Render ------------------ */
    /* ---------------------------------------------------- */
    return(
        <>
            <HeadBlock title="RECRUIT - 「ゆたか」って何？ - 株式会社レイヴン" description="レイヴンの「ゆたか」について説明するページ。" path="Richness"  />
            <Header color="black" />
            <div>
            <PgTitle2
               pgTitle={RichnessSvg_2}
               pgTitleName={'TopRichness_2'}
                describe="RAVENでは、企業理念に「社員とその家族をゆたかにする」とあります。ここでは、RAVENの「ゆたか」を説明します。"
                karasuA={karasuA}
                karasuB={karasuB}
                />
            </div>
            <div>
            <PgTitle
               pgTitle={RichnessSvg}
               pgTitleName={'TopRichness'}
                describe="RAVENでは、企業理念に「社員とその家族をゆたかにする」とあります。ここでは、RAVENの「ゆたか」を説明します。"
                karasuA={karasuA}
                karasuB={karasuB}
                />
            </div>

            <SyutakaBox>
                <Syutaka>「ゆたか」<SyutakaSmall>とは</SyutakaSmall></Syutaka>
            </SyutakaBox>

            <SyutakaWrap>
                <SkeizaiBox>
                    <Skeizaihead>
                        <Skeizaigroup>
                            <Skeizai>経済的</Skeizai>
                            <Sfree>自由</Sfree>
                        </Skeizaigroup>
                        <SkarasuImg src={rich1} />
                    </Skeizaihead>

                    <StextBox>
                        <Stext>
                            お金に困らず<Sbr />
                            買いたいものが買える状態
                        </Stext>
                    </StextBox>
                </SkeizaiBox>

                <SbatuImg src={batu}/>

                <SkeizaiBox>
                    <Skeizaihead>
                        <Skeizaigroup>
                            <Skeizai>精神的</Skeizai>
                            <Sfree>自由</Sfree>
                        </Skeizaigroup>
                        <SkarasuImg src={rich2} />
                    </Skeizaihead>
                    <StextBox>
                        <Stext>
                            やりたいことができ<Sbr />
                            やりたくないことはやらない状態
                        </Stext>
                    </StextBox>
                </SkeizaiBox>

                <SbatuImg src={batu}/>

                <SkeizaiBox>
                    <Skeizaihead>
                        <Skeizaigroup>
                            <Skeizai>時間的</Skeizai>
                            <Sfree>自由</Sfree>
                        </Skeizaigroup>
                        <SkarasuImg src={rich3} />
                    </Skeizaihead>
                    <StextBox>
                        <Stext>
                            やりたいことに<Sbr />
                            時間が割ける状態
                        </Stext>
                    </StextBox>
                </SkeizaiBox>
            </SyutakaWrap>
            <Syutakatextflame>
                <SyutakaTextWrapper>
                    <SyutakaText>
                        経済、時間、精神の全てにおいて自由が高くなるほど「ゆたか」になります。<Sbr />
                        自分自身が「ゆたか」になったら家族も幸せにできるというのがRAVENの考え方です。
                    </SyutakaText>
                </SyutakaTextWrapper>
            </Syutakatextflame>

            <Showyutaka>
                <Showyutakahead>
                    <ShowyutakaImg src={rich4} />
                    <Showyutakaheadtext>
                        どのように「社員とその家族をゆたか」にするのか？
                    </Showyutakaheadtext>
                </Showyutakahead>
                <Showyutakacontents>
                    <Showyutakatext1>
                        RAVENでは、皆さんを成果が出せる
                        <Sbusiness>ビジネスマン</Sbusiness>に育てます！<Sbr />
                        そのために、成長できる環境と教育を用意し、成果に対する報酬を提供します。<Sbr />
                        そして、個人が成果をだし、会社が成果を出すことで会社は成長します。
                    </Showyutakatext1>
                    <Showyutakagroups>
                        <Syutakagroup>
                            <ShowyutakagroupImg src={free1}/>
                            <Showyutakagrouptext>
                                自分が成長すれば<Sbr />
                                報酬が上がる
                            </Showyutakagrouptext>
                        </Syutakagroup>
                        <Syutakagroup>
                            <ShowyutakagroupImg src={free2}/>
                            <Showyutakagrouptext>
                                成果を出せる人は<Sbr />
                                自分でやりたい仕事を<Sbr />
                                作り出せる<Sbr />
                            </Showyutakagrouptext>
                        </Syutakagroup>
                        <Syutakagroup>
                            <ShowyutakagroupImg src={free3}/>
                            <Showyutakagrouptext>
                                成果を出せる人は<Sbr />
                                効率よく仕事ができる
                            </Showyutakagrouptext>
                        </Syutakagroup>
                    </Showyutakagroups>
                    <Showyutakatext1>
                        ビジネスマンになった皆さんは、Ravenを利用して更にやりたいことをやる！<Sbr />
                        このように「社員とその家族をゆたか」にしようと考えています。
                    </Showyutakatext1>
                </Showyutakacontents>
            </Showyutaka>

            <SimgWrap>
                <SgroupImg src={Group} alt="" />
            </SimgWrap>

            <RecruitCard />
            <RecruitFooter 
                display="block"
            />
            
            <Footer />
        </>
    );
};

/* ======================================================================================================================================================= */  
/* ==================================================================== End Of Program =================================================================== */  
/* ======================================================================================================================================================= */ 



