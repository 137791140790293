/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Components ----- ----- */
import { Header } from "../../Components/Common/Header";
import { SubVisual } from "../../Components/Common/SubVisual";
import { ContactForm } from "../../Components/Contact/ContactForm";
import { Footer } from "../../Components/Common/Footer";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ------ img ------ */
import contactTile from "../../Images/3U5A4763.JPG";


/* ---------- Styled Compornents ---------- */
const Smargin = styled.div`
margin: 50px auto;
`;
export const Contact = () => {
    return(
        <>
            <Header color="white" />
            <SubVisual
            Image={contactTile}
            sub_titleEn="CONTACT"
            subTitleJa="お問い合わせ"
            />
            <Smargin>
                <ContactForm />
            </Smargin>
            <Footer />
            
        </>
    );
}