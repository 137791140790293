/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Style ---------- */
import styled from "styled-components";
/* ---------- components ---------- */
import { HeadBlock } from "../../HeadBlock";
import { KeyVisual } from "../../Components/Recruit/InterviewKeyVisual"
import { NamePlate } from "../../Components/Recruit/InterviewNamePlate"
import { BAnswer } from "../../Components/Recruit/InterviewContents"
import { Iimages } from "../../Components/Recruit/InterviewPhoto"
import { Header } from "../../Components/Common/Header"
import { Footer } from "../../Components/Common/Footer"
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { InterviewTransionList } from "../../Components/Recruit/InterviewTransionList";

/* ---------- Images ---------- */


/*----------キービジュアル----------*/
import KeyVisualImg from "../../Images/Recruit_image/appi02.jpg"
/*----------３つの連なる画像----------*/
import Timages1 from "../../Images/Recruit_image/appi03.jpg"
import Timages2 from "../../Images/Recruit_image/appi05.jpg"
import Timages3 from "../../Images/Recruit_image/appi06.jpg"

const Height = styled.div`
height: 8rem;
@media (max-width: 599px) {
height: 2rem;
}
`;
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Szk = (props) => {
  return (
    <>
      <HeadBlock title="RECRUIT - 鈴木 亜衣梨 - 株式会社レイヴン" description="インタビュー鈴木" path="Szk" />


      <Header color="white" />

      <KeyVisual
        image={KeyVisualImg}
      />

      <NamePlate
        EngName="Airi Suzuki"
        JpnName="鈴木 亜衣梨"
        year="2022年入社"
        division="PM/PMO事業部"
      />
      <Height />

      <BAnswer
        color="#FFEF61"
        Ctitle="休日はどう過ごしているか"
        Ctext="休日は、家事をしていることが多いです。スーパーに食材を買いに行き普段よりも凝った調理をしたり、初めてのメニューに挑戦するなど料理を楽しんでいます。他には、趣味が美容なのでスキンケアを試してみたり、いろいろな美容グッズを集めています。今年の夢は1人で韓国に行って、美容の施術を受けることです。"
        Dtitle="入社して感じたこと"
        Dtext="1つ目は、代表を身近に感じられることです。 代表と同じ案件に参画しており、現場の案件状況や技術を直に教えてもらうことで、とても成長に繋がっていると感じます。 私は中途入社でレイヴン以外の会社でも働いた経験があるのですが、前職では代表から直接アドバイスをもらう機会はありませんでした。代表が直接案件に参画して指導することもレイヴンの特徴だと思います。2つ目は、グループを跨いだ交流が大切だということです。グループ間の役割がしっかりしている分、共通の案件の中で関われる機会が少ないです。そのため、イベントやサークル活動等でのグループを跨いだ関わりを大切にしていると感じました。"
        Etitle="今どんな仕事をしているか"
        Etext="私は現在、企画書と業務フローを作成しています。 今回の案件から、事業部の企画書と業務フローの作成を代表や先輩方に教えてもらいながら勉強しています。 今回の案件では、全てが初めてなので、専門用語や作業の進め方、わからないことを誰に聞いたらいいのかなど、わからない事ばかりで大変だと感じています。企画書や業務フローの作成をできる人がレイヴンには多くないので、自分ができるようになって「これならまかせろ」と言えるようになりたいです。 そして、属人化しないよう、自分が身につけたノウハウを周りの人に広めていく力も身につけたいです。"
      />

      <Iimages
        images={Timages1}
        Bimages={Timages2}
        Cimages={Timages3}
      />

      <InterviewTransionList />
      <RecruitFooter
        display="block"
      />
      <Footer />
    </>
  )
};