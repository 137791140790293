/* ==================== Define import ==================== */

/* ---------- React ---------- */
import React from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";
import { ServiceTop } from "../../Components/Service/StrongText";
/* ---------- Styled Compornents ---------- */
const Texts = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const StrongOfRavenStrongText = (props) => {
  const FiveStrongText = [
    { id: '1', number: '01', color: '#E0B000', name: 'サービス企画', text: '一般消費者の需要を調査して、売れるサービスを企画します。調査の結果、どのようなサービスを企画するかを企画書にアウトプットします。' },
    { id: '2', number: '02', color: '#F08070', name: 'UI/UXデザイン', text: 'サービス企画書から利用者が良いと思えるデザインを考え、デザイン書にアウトプットします。' },
    { id: '3', number: '03', color: '#77B150', name: 'システム開発・保守', text: 'サービス企画書とUIデザイン書をもとにシステムを設計、プログラミング、テストをして依頼されたものを開発します。' },
    { id: '4', number: '04', color: '#599AD5', name: 'サービス運用構築・運用', text: 'サービスイン後のお客様の行動やサービスの問題を想定し、対応を用意します。' },
    { id: '5', number: '05', color: '#213A70', name: 'プロジェクトマネジメント', text: 'プロジェクト内の進捗管理、必要な情報の入手などプロジェクトを円滑に進めます。' }
  ];







    return(
      <>      
      <Texts>
        {FiveStrongText?.map((item) => (
          <div key={item.id}>
          <ServiceTop 
            number={item.number}
            color={item.color}
            name={item.name}
            text={item.text}
          />
          </div>
        ))}
      </Texts>   
      </>
    )
}