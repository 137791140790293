/*====== Define import ======*/

/*------ React ------*/
import React from "react";
/*------ CSS ------*/
import styled from "styled-components";



/*------ Styled Components ------*/

const TopContenrt2 = styled.div`
    width: 100%;
    padding-bottom: 80px;
    background-color:rgb(39,40,47);
    margin: -10px 0 0 0;
     // レスポンシブ 
     /* ### 599px以下#### */
    @media screen and (max-width:599px){
        width: 100%;
        padding-bottom:50px;
    
      }
    /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
        width: 100%;
        padding-bottom:50px;      
      }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        width: 100%;
        padding-bottom:50px;
     }
    /* ### 1170px以上 ### */
    @media (min-width: 1170px) {
       
     }
`;

const Sctttle = styled.h1`//タイトル
    z-index: 30;
    position: relative;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    color: #ffffff;
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    margin-left: 20%;
    padding: 10% 0 0 0;
    text-align: left;
     // レスポンシブ 
     /* ### 599px以下#### */
    @media screen and (max-width:599px){
        margin: 0 0 0 10%;
        letter-spacing: 0.2rem;
        font-size: 1rem;
      }
     /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
        margin: 0 0 0 10%;
        letter-spacing: 0.2rem;
        font-size: 1rem;
     } 
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        margin: 0 0 0 10%;
     }
      /* ### 1170px以上 ###
    @media (min-width: 1170px) {

    } */
`;

const Sctsubttl = styled.p`//サブタイトル
    z-index: 30;
    position: relative;
    letter-spacing: 0.1rem;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    margin:0 0 0 20%;
    padding: 2vw 0 0 0;
    text-align: left;
    white-space: pre-wrap;
     // レスポンシブ 
      /* ### 599px以下#### */
    @media screen and (max-width:599px){
        margin: 0 0 0 10%;
        font-size: 1.5rem;
      }
     /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
        margin: 0 0 0 10%;
        font-size: 1.5rem;
     }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        margin: 0 0 0 10%;
     }
      /* ### 1170px以上 ### */
    @media (min-width: 1170px) {
        
    }
`;


const Content = styled.div`//写真下文字(大)
    width:60%;
    margin: 0 auto;
    font-size: 2rem;
    text-align: center;
    color: white;
    letter-spacing: 0.1rem;
    line-height: 2rem;
     // レスポンシブ 
      /* ### 599px以下#### */
      @media screen and (max-width:599px){
        width: 80%;
        font-size: 1.2rem;
        margin: 2rem auto 0 auto;
        line-height: 1.5rem;
      }
        /* #### 600px以上～768px以下 ### */
        @media (min-width: 600px) and (max-width: 768px) {
        width: 80%;
        font-size: 1.8rem;
        margin: 2rem auto 0 auto;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        width:60%;
        margin: 2rem auto 0 auto;
        line-height: 2.5rem;
    }
     /* ### 1170px以上 ### */
     @media (min-width: 1170px) {
        margin-top: 100px;
    }
     
`;

const Content2 = styled.div` //写真下文字(小)
    width: 60%;
    letter-spacing: 0.1rem;
    text-align: center;
    color: white;
    line-height: 30px;
    font-size: 1rem;
    margin: 2rem auto 0 auto;
     // レスポンシブ 
      /* ### 599px以下#### */
      @media screen and (max-width:599px){
        width: 80%;
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 30px;
        font-size: 0.8rem;
        margin: 2rem auto 0 auto;
      }
        /* #### 600px以上～768px以下 ### */
        @media (min-width: 600px) and (max-width: 768px) {
        width: 80%;
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 30px;
        font-size: 1rem;
        margin: 2rem auto 0 auto;

    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        width:60%;
        margin: 2rem auto 0 auto;
    }
     /* ### 1170px以上 ### */
     @media (min-width: 1170px) {
        margin: 2rem auto 0 auto;

    }
`;



/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const ServiceInterviewVisual = (props) => {
    const { sctsubttl, content, content2 } = props; //propsで管理
    return (
        <>
            <TopContenrt2>
                <Sctttle>BUSINESS</Sctttle>
                <Sctsubttl>{sctsubttl}</Sctsubttl>
                {/* <TopContenre> */}
                    <Content>{content}</Content>
                    <Content2>{content2}</Content2>
                    {/* <TopImg src={Img} alt="Image" /> */}
                {/* </TopContenre> */}
            </TopContenrt2>
        </>
    );
}