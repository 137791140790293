/*====== Define import ======*/
/*------ React ------*/
import React from "react";
/* ==================== Define styles ==================== */
/* ---------- Styled Compornents ---------- */
import styled from "styled-components";

//質問、回答のセット
const Aarea = styled.div`
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
  letter-spacing: 0.1em;
  line-height: 1.6rem;
`;
//質問
const Title = styled.h2`
  font-size: 1.5em;
  /* margin: 5vh 0 3vh 0; */
  padding: 0 0 0.2em 0;
  border-bottom: 0.15em solid ${(props) => props.color};
  @media screen and (max-width:599px){
    font-size: 1rem;
    }
`;
//質問の答え
const Text = styled.p`
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1rem;
  padding: 1vw 0 0 0;
  margin-bottom: 2rem;
  font-size: 1rem;
  @media screen and (max-width:599px){
    font-size: 0.8rem;
    }
`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */
//質問が２つの時
export const Answer = (props) => {
  const {title, text, Btitle, Btext, color } = props;
  return(
    <>
      <Aarea>
        <Title color={color}>{title}</Title>
        <Text>{text}</Text>

        <Title color={color}>{Btitle}</Title>
        <Text>{Btext}</Text>
      </Aarea>
    </>
  )
};
//質問が３つの時
export const BAnswer = (props) => {
  const {Ctitle, Ctext, Dtitle, Dtext, Etitle, Etext, color} = props;
  return(
    <>
      <Aarea>
        <Title color={color}>{Ctitle}</Title>
        <Text>{Ctext}</Text>

        <Title color={color}>{Dtitle}</Title>
        <Text>{Dtext}</Text>

        <Title color={color}>{Etitle}</Title>
        <Text>{Etext}</Text>
      </Aarea>
    </>
  )
};



