/*====== Define import ======*/
/* ---------- react ---------- */
import React from "react";

/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
//名前と情報の大枠
const BackGround = styled.div`
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 600px;
  left: 15%;
  right: 15%;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 2vh 6vw 2vw 0;
  line-height: 3em;
  letter-spacing: 0.1em;
  @media screen and (max-width:599px){
    font-size: 0.8rem;
    top: 22rem;
    }
`;
//名前
const Names = styled.div`
  display:flex;
  justify-content: flex-end;
`;
//年数と事業部
const Info = styled.div`
  display:flex;
  justify-content: flex-end;
  font-size:1.0rem;
  @media screen and (max-width:599px){
    font-size: 0.6rem;
    }

`;
const Sspan = styled.span`
    color: #5CC6C0;
`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */
export const NamePlate = (props) => {
    const { EngName, JpnName, year, division} = props;
    return(
        <>
          <BackGround>
            {/*---------- 名前 ----------*/}
            <Names>
              <p>{EngName}<Sspan>/</Sspan></p>
              <p>{JpnName}</p>
              </Names>
            {/*---------- 年数と事業部 ----------*/}
            <Info>
              <p>{year}<Sspan>/</Sspan></p>
              <p>{division}</p>
              </Info>
          </BackGround>
        </>
    )
};

