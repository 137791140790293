/* ==================== Define import ==================== */

/* ---------- React ---------- */
import React from 'react';
import { AboutTile } from '../../Components/about/aboutTile3';
import { SectionTitleAnime } from "../../Components/Common/SectionTitleAnime";

import motto1 from "../../Images/motto1.jpg"
import motto2 from "../../Images/motto2.jpg"
import motto3 from "../../Images/motto3.jpg"
/* ==================== Define styles ==================== */
import styled from 'styled-components';
/* ---------- Styled Compornents ---------- */
const About = styled.div`
 width: 100%;
 margin-top: 240px;
 @media (max-width: 768px) {
      margin-top: 120px;
    }
`;
const Sdiv = styled.div`
max-width: 1250px;
margin: 0px auto 0px auto;
`;
const All = styled.div`
max-width: 1170px;
width: 100%;
margin: 60px auto 0px auto;
display: flex;
flex-wrap: wrap;
opacity:1;
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const CorporateP = (props) => { 
    const Tiles = [
      { id: '1', img:motto1, title: 'ビジネスマンになれ', text1: '世界を楽しむための考え方', text2: '出来るビジネスマンだからこそ、世界中の楽しみが向こうからやってくる', width: 'calc(50% - 10px)', height: 'auto' },
      { id: '2', img:motto2, title: '先手必勝の先に成功がある', text1: '成功するための考え方', text2: '先回りするためのスキル・ノウハウを持ったものが成功を手に入れることができる', width: 'calc(50% - 10px)', height: 'auto' },
      { id: '3', img:motto3, title: '常に最強を目指し、いかなる困難も乗り越えろ', text1: '成長するための考え方', text2: '様々な困難とは成長するための糧として考える', width: '100%', height: 'auto' }
    ];

  return(
      <>
      <About>
                <SectionTitleAnime
                    TitleName="COMPANY  MOTTO"
                    fontSize="32px"
                    fontFamily="'Roboto Condensed', sans-serif;"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <br />
                <SectionTitleAnime
                    TitleName="社訓"
                    fontSize="20px"
                    fontFamily=" 'Noto Sans JP', sans-serif"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <Sdiv>
      <All>
        {Tiles.map((item) => (
          <AboutTile key={item.id}
              title={item.title}
              img={item.img}
              text1={item.text1}
              text2={item.text2}
              text3={item.text3}
              width={item.width}
              height={item.height}
          />
        ))}
        </All>
        </Sdiv>
        </About>
      </>
  )
}