/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React, { useEffect } from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/*------ component ------*/
import { XSlider2 } from "../Common/XSlider2";
/* ------ Library ------ */
import Vivus from "vivus";


/*------ image ------*/
import Image2401 from "../../Images/HP_ replace_2024/kenshu/train24-1.jpeg";
import Image2402 from "../../Images/HP_ replace_2024/kenshu/train24-2.jpeg";
import Image2403 from "../../Images/HP_ replace_2024/kenshu/train24-3.jpeg";
import Image2404 from "../../Images/HP_ replace_2024/kenshu/train24-4.jpeg";
import Image2405 from "../../Images/HP_ replace_2024/kenshu/train24-5.jpeg";
import Image2406 from "../../Images/HP_ replace_2024/kenshu/train24-6.jpeg";
import Image2407 from "../../Images/HP_ replace_2024/kenshu/train24-7.jpeg";
import Image2408 from "../../Images/HP_ replace_2024/kenshu/train24-8.jpeg";

/* ---------- Styled Compornents ---------- */
const Two = styled.div`
    position: relative;
`;


const SvgA = styled.svg`
    max-width:80rem;/*SVGタグの横幅*/
    width:100%;/*レスポンシブ対応にするため100%を指定*/
    height:auto;
    position: absolute;
    top: 10%;
    left: -20%;
    z-index: 5;
`;


/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */


export const RecruitTraining24 = () => {
    useEffect(() => {
        new Vivus(
            "Svg24",
            {
                duration: 100,
                // file: Svg24,
                type: "delayed",
                forceRender: false,
                animTimingFunction: Vivus.EASE,
            })
    }, []);

    return (
        <>
            <Two>
                <SvgA id="Svg24" viewBox="0 0 2 0.4" />
                <XSlider2
                    Image01={Image2401}
                    Image02={Image2402}
                    Image03={Image2403}
                    Image04={Image2404}
                    Image05={Image2405}
                    Image06={Image2406}
                    Image07={Image2407}
                    Image08={Image2408}
                    width={"10rem"}
                >
                </XSlider2>
            </Two>

        </>
    );
};