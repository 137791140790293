// /* ==================== Define import ==================== */

// /* ---------- React ---------- */
import React from "react";
//import { Nakao } from "../../Pages/WorkSpace/feature_Nakao";
// /* ==================== Define styles ==================== */s
import styled from "styled-components";
const Tile = styled.div`
  max-width: 1170px;
  background-color: white;
  width: ${(props) => props.width};
  margin: 0 auto 0 auto;
  height: ${(props) => props.height};
  /* #### 768px以下 ### */
    @media (max-width: 1250px) {
    width:100%;
    margin: 0 40px 40px 40px;
    }
`;
const Contents = styled.div`
    padding: 0 ;
`;
const Title = styled.h1`
  font-size: 32px;
  font-weight: 900;
  margin-top: 0px;
  @media (max-width: 900px) {
    font-size: 25px;
    }
  @media (max-width: 430px) {
    font-size: 19px;
    }
`;
const Title2 = styled.h1`
  font-size: 1.5rem;
  margin: 10px 0;
  font-weight: 900;
  @media (max-width: 900px) {
    font-size: 25px;
    }
  @media (max-width: 430px) {
    font-size: 19px;
    }
`;
const Title3 = styled.h1`
  font-size: 16px;
  font-weight: 100;
  margin-top: 40px;
`;
const Title4 = styled.h1`
  font-size: 32px;
  font-weight: 100;
  @media (max-width: 900px) {
    font-size: 25px;
    }
  @media (max-width: 430px) {
    font-size: 19px;
    }
`;
const Texts = styled.div`
    /* margin-top: 2rem; */
    font-size: 20px;
    line-height: 1.5rem;
    padding: 0;
    @media (max-width: 1250px) {
        padding: 0 0 0 0;
}
@media (max-width: 900px) {
    font-size: 17.5px;
    }
  @media (max-width: 440px) {
    font-size: 15px;
    }
`;
const Text1 = styled.p``;
const Text2 = styled.p``;
const Text3 = styled.p``;
const Text4 = styled.p``;
const Text5 = styled.p``;



/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const AboutTile = (props) => {
  const { title, title2,title3, title4, text1, text2, text3, text4, text5, width, width2, height, width3 } = props;

  return (
    <>    
        <Tile width={width}>
          <Contents>
            <Title>{title}</Title>
            <Title2>{title2}</Title2>
            <Title3>{title3}</Title3>
            <Title4>{title4}</Title4>
            <Texts>
              <Text1>{text1}</Text1>
              <Text2>{text2}</Text2>
              <Text3>{text3}</Text3>
              <Text4>{text4}</Text4>
              <Text5>{text5}</Text5>
            </Texts>
          </Contents>
        </Tile>
    </>
  )
}
