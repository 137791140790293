/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
/* ------ img ------ */
import serviceImg from "../../Images/HUGIN.svg";
import systemImg from '../../Images/HP_ replace_2024/Interview/Mizoguti03.jpg';
import businesImg from "../../Images/SuzukiFace.jpg";
import { Tile } from "../Common/Tile";
/*====== Define styles ======*/
/*------ Styled Components ------*/
const SWrapper = styled.div`
    max-width: 1170px;
    width: auto;
    margin: 80px auto 0 auto;
`;
const SList = styled.ul`
    list-style: none;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
`;







/*------ Main ------*/
export const ServiceInterviewBtn = (props) => {

    // const { displayP, displayS, displayB } = props;

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/

    return (
        <>

            <SWrapper>
                <SList>
                    {/* <Tiles href='/service'><Text>サービスページへ戻る（仮）</Text></Tiles> */}
                    {/* <Tile 
                        width="30%"
                        paddingTop="30%"
                        title="PM・PMO事業"
                        text="サービスページに戻る"
                        alt="PM・PMO事業の画像"
                        href="/pmpmo"
                    /> */}
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        title="システム開発事業"
                        text="UI/UXデザイン/システム開発・システム保守"
                        img={systemImg}
                        alt="システム開発事業の画像"
                        href="/system"
                        marginTop='70%'
                    />
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        title="ビジネス構築事業"
                        text="サービス企画/サービス運用構築・サービス運用"
                        img={businesImg}
                        alt="ビジネス構築事業の画像"
                        href="/business"
                        marginTop='70%'
                    />
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        title="SERVICE"
                        text="サービスページへ戻る"
                        img={serviceImg}
                        alt="システム開発事業の画像"
                        href="/service"
                        marginTop='70%'
                    />
                </SList>
            </SWrapper>

        </>
    );
}