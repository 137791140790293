// /* ==================== Define import ==================== */

// /* ---------- React ---------- */
import React from "react";
//import { Nakao } from "../../Pages/WorkSpace/feature_Nakao";
// /* ==================== Define styles ==================== */s
import styled from "styled-components";
// /* ---------- Styled Compornents ---------- */
const Tile = styled.div`
  max-width: 1170px;
  background-color: white;
  margin: 0 auto 2rem auto;
  /* #### 768px以下 ### */
  width: ${(props) => props.width};
  @media (max-width: 1250px) {
    width:100%;
    margin: 0 40px 40px 40px ;
    }

`;
const Contents = styled.div`
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 900;
  margin: auto 0;
  @media (max-width: 900px) {
    font-size: 23px;
    }
  @media (max-width: 430px) {
    font-size: 18px;
    }
`;
const SdivTop = styled.div`
display: flex;
height: 50px;
`;
const Simg = styled.img`
margin-right: 20px;
`;
const Texts = styled.div`
    font-size: 20px;
    line-height: 1.5rem;
    padding: 30px 0 0 0;
    @media (max-width: 1170px) {
    }
    @media (max-width: 900px) {
    font-size: 17.5px;
    }
  @media (max-width: 440px) {
    font-size: 15px;
    }
`;
const Text1 = styled.p``;
const Text2 = styled.p``;
const Text3 = styled.p``;
const Text4 = styled.p``;
const Text5 = styled.p``;



/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const AboutTile = (props) => {
  const { title, text1, text2, text3, text4, text5, width, img } = props;

  return (
    <>    
        {/* <Tile > */}
        <Tile
            // style={{ width, height}}
            width={width}
        >
          <Contents>
          <SdivTop>
            <Simg src={img}/>
            <Title>{title}</Title>
            </SdivTop>
            <Texts>
              <Text1>{text1}</Text1>
              <Text2>{text2}</Text2>
              <Text3>{text3}</Text3>
              <Text4>{text4}</Text4>
              <Text5>{text5}</Text5>
            </Texts>
          </Contents>
        </Tile>
    </>
  )
}
