/* ==================== Define import ==================== */

/* ---------- React ---------- */
import React from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";
import { WACCBtn } from "./WACCBtn";
/* ---------- Styled Compornents ---------- */
const Parts = styled.div`
  height: 280px;
  max-width: 800px;
  width: 100%;
  //background-color: #EFEFEF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto 80px auto;
  /* border: solid 1px #27282F; */
  //transition: 0.5s all;
  @media screen and (max-width: 820px) {
        width: 500px;
        height: 200px;
    }    
    @media screen and (max-width: 569px) {
        width: 400px;
        height: 200px;

    }  
    @media screen and (max-width: 432px) {
        width: 300px;
        height: 200px;
    }  
    @media screen and (max-width: 315px) {
        width: 200px;
        height: 200px;
    }  
`;

const Img = styled.img`
/* border: solid 1px black; */
    width: 30%;
    @media screen and (max-width: 820px) {
        width: 150px;
    }
    @media screen and (max-width: 520px) {
        width: 115px;
    }  
`;
// const Imgdiv = styled.div`
//   border: solid 1px #27282F;
//   margin: 0 auto;
//   height: 250px;
//   width: 250px;
//   position: relative;
// `


const Title = styled.h1`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 820px) {
        font-size: 1rem;
    }
    @media screen and (max-width: 565px) {
        font-size: 1rem;
    }  
`;

const Text = styled.p`
    width: 500px;
    line-height: 1.8rem;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    @media screen and (max-width: 820px) {
        width: 300px;
        font-size: 10px;
        line-height: 20px;
        /* display: none; */
    }
    @media screen and (max-width: 565px) {
        width: 100%;
        font-size: 15px;
        /* display: none; */
    }  

`;
const Text2 = styled.p`
    width: 500px;
    line-height: 1.8rem;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    @media screen and (max-width: 820px) {
        display: none;
        width: 300px;
        font-size: 10px;
        line-height: 20px;
    }
    @media screen and (max-width: 565px) {
        display: none;
        width: 250px;
        font-size: 15px;
    }  

`;

const Moji = styled.div`
    margin-left: 2rem;
    width: 70%;
`;

const A = styled.a`
    color : inherit;
    text-decoration: none;
`;

const Texts = styled.div`
    //margin-top: 1rem;
`;

const BoxCenter = styled.div`
    text-align: right;
    margin-top: 1rem;
    @media screen and (max-width: 820px) {
        text-align: right;
    }
    
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const WACC = (props) => {
  const { title, text1, text2, img, alt,  width, height, href } = props;
  return(
      <>
        <Parts>
            {/* <Imgdiv> */}
            <Img src={img} alt={alt} />
            {/* </Imgdiv> */}
            <Moji>
                <Texts>
                    <Title>{title}</Title>
                   <Text>{text1}</Text>
                   <Text2>{text2}</Text2>
                </Texts>
                <BoxCenter >
                <A href={href} target="_blank">
                   <WACCBtn 
                       btn_text="MORE 〉"
                       width={width}
                       height={height}
                       hoverBackgroundColor="#FBE04F"
                    //    url={url}
                    //    navi={navi}
                   />
                </A>
              </BoxCenter>
            </Moji>
        </Parts>
      </>
    )
}