/*====== Define import ======*/
/*------ React ------*/
import React from 'react';
import { Transition } from "../../Components/News/NewsTransition";
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { SubVisual } from '../../Components/Common/SubVisual';
import Image from '../../Images/newssub.JPG';
// const NewsArticle = styled.div`
//     background-color: lightgray;  
// `
/*====================*/
/*======  Main  ======*/
/*====================*/
export const NewsPages = () => {
    return (
        <>
        
        <Header color="white" />
        <SubVisual
                    Image={Image}
                    sub_titleEn="NEWS"
                    subTitleJa="ニュース"
                />
               
        <Transition />
       
        <Footer />
        </>
    );
};