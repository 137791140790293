/*====== Define import ======*/
import React from "react";
import styled from "styled-components";

/* ------ styled components ------ */
const Sth = styled.th`
padding: 1rem;
border-bottom: 1px solid #ebecf0;
/* white-space: nowrap; */
font-size: 1rem;
vertical-align: middle;
text-align: left;
width: 25rem;
/* #### 900px以下 ### */
@media (max-width: 900px) {
    padding: 0 10vw 0 1rem;
    font-size: 0.8rem;
    width: 20rem;
}
@media (max-width: 400px) {
    font-size: 0.8rem;
    width: 10rem;
    padding: 0 10vw 0 1rem;
}
`;

const Std = styled.td`
padding: 2vh 0;
border-bottom: 1px solid #ebecf0;
width: 60rem;
line-height: 1.5em;
font-size: 1rem;
/* #### 768px以下 ### */
    @media (max-width: 900px) {
        font-size: 0.8rem;
        width: 30rem;
    }
@media (max-width: 400px) {
    font-size: 0.8rem;
    width: 15rem;
}
`;

const Simg = styled.img`
width: 100%;
`;

/*====================*/
/*====== Main ======*/
/*====================*/
export const CompanyTable = (props) => {

    /*====== variables ======*/
    const { th, td, img } = props;

    return (
        <>
        
                    <tr>
                        <Sth>
                            {th}
                        </Sth>
                        {
                            img ? (
                                <Std ><Simg src={td} alt="組織図" /></Std>
                            ) : (

                                <Std>
                                    {td}
                                </Std>
                            )
                        }
                    </tr>
        </>
    );
};
