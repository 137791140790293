/* ==================== Define import ==================== */
/* ---------- 動くタイトル ----- ----- */
import { SectionTitleAnime } from "../../Components/Common/SectionTitleAnime";
/* ---------- modal ----- ----- */
import { SubVisual } from "../../Components/Common/SubVisual";
/* ---------- footer ----- ----- */
import { Footer } from "../../Components/Common/Footer";
import { Header } from "../../Components/Common/Header";
/* ---------- Carｄ ----- ----- */
import { Tile } from "../../Components/Common/Tile";
/* ---------- Images ----- ----- */
import PmpmoImage from "../../Images/HP_ replace_2024/Interview/Kato01.jpg";
import DevelopImage from '../../Images/HP_ replace_2024/Interview/Mizoguti03.jpg';
import BisinessImage from "../../Images/SuzukiFace.jpg";
import { StrongOfRavenStrongText } from "../../Components/Service/StrongOfRavenStrongText";
import Arrow from "../../Images/Group 32.png"
import { WACC } from "../../Components/Common/WACC";
import fivestrong from "../../Images/fivestrong2.svg"
import RMATCH from "../../Images/Rmatch.svg"
import serviceHeaderImg2 from '../../Images/serviceHeaderImg.png';
import Frame4 from '../../Images/Frame4.svg'
import { HeadBlock } from "../../HeadBlock";
/* ---------- React ---------- */
import React from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const SWrapper = styled.ul`
    max-width: 1170px;
    width: auto;
    margin: 0 auto;
`;

const ScenterInner = styled.div`
    margin: 0 auto;
    text-align: center;
`;

const SsmallP = styled.p`
    line-height: 1.8rem;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    margin-top: 2.5rem;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        font-size: 0.7rem;
    }
`;

// ------------------------------------------------
const SthreeP = styled.p`
    line-height: 1.8rem;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    text-align: center;
    margin-top: 2.5rem;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        font-size: 0.7rem;
    }
`;

const SList = styled.ul`
    list-style: none;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
`;

// -----------------------------

// margin.padding---------------------------
const Smargin3 = styled.div`
    margin: 3vh 0;
`;



const Smargin10 = styled.div`
    margin: 10vh 0;
`;




const Worries = styled.div`
    width: 100%;
    background-color: #27282F;
    padding-top: 80px;
    padding-bottom: 40px;
    @media screen and (max-width: 600px) {
        //height: 20rem;
    }
`;
const SWorries = styled.div`
    width: 100%;
    //height: 42rem;
    background-color: #27282F;
    padding-top: 3rem;
    padding-bottom: 20px;
    /* @media screen and (max-width: 969px) {
        height: 33rem;
    }
    @media screen and (max-width: 600px) {
        height: 38rem;
    }
    @media screen and (max-width: 462px) {
        height: 40rem;
    } */
`;



const One = styled.h1`
    width: 100%;
    margin: 0px auto 3rem auto;
    /* margin-top: 5rem;
    margin-bottom: 8rem; */
    font-size: 2rem;
    color: white;
    letter-spacing: 0.1rem;
    @media screen and (max-width: 600px) {
        width:80%;
        font-size: 1.3rem;
        margin: 0rem auto 3rem auto;
    }
    @media screen and (max-width: 447px) {
        width:90%;
        font-size: 1rem;
        margin: 0 auto 3rem auto;
        line-height: 2rem; 
    }
`;

const Two = styled.h2`
    width: 100%;
    margin: 0 auto;
    color: white;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    letter-spacing: 0.1rem;
    @media screen and (max-width: 969px) {
       font-size: 1rem;
       width: 90%;
       margin-bottom: 2rem;
    }
    @media screen and (max-width: 693px) {
       font-size: 1rem;
       width: 90%;
       margin-bottom: 2rem;
    }
    @media screen and (max-width: 584px) {
       font-size: 0.7rem;
       width: 90%;
       margin-bottom: 1rem;
       line-height: 2rem; 
    }
`;

const Three = styled.h2`
    width: 100%;
    margin: 0 auto;
    color: white;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    letter-spacing: 0.1rem;
    @media screen and (max-width: 969px) {
       font-size: 1rem;
       margin-bottom: 2rem;
    }
    @media screen and (max-width: 693px) {
       font-size: 1rem;
       width: 90%;
       margin-bottom: 2rem;
    }
    @media screen and (max-width: 584px) {
       font-size: 0.7rem;
       width: 90%;
       margin-bottom: 1rem;
       line-height: 2rem; 
    }
`;
const Four = styled.h2`
    width: 100%;
    margin: 0 auto;
    color: white;
    font-size: 1.5rem;
    margin-bottom: 5rem;
    letter-spacing: 0.1rem;
    @media screen and (max-width: 969px) {
       font-size: 1rem;
       margin-bottom: 2rem;
    }
    @media screen and (max-width: 693px) {
       font-size: 1rem;
       width: 90%;
       margin-bottom: 2rem;
    }
    @media screen and (max-width: 584px) {
       font-size: 0.7rem;
       width: 90%;
       margin-bottom: 1rem;
       line-height: 2rem; 
    }
`;
const Five = styled.h2`
    width: 100%;
    margin: 0 auto;
    color: white;
    font-size: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 20px;
    letter-spacing: 0.1rem;
    @media screen and (max-width: 969px) {
       font-size: 1rem;
       width: 90%
    }
    @media screen and (max-width: 693px) {
       font-size: 1rem;
       width: 90%
    }
    @media screen and (max-width: 584px) {
       font-size: 0.7rem;
       width: 90%;
       /* margin-bottom: 1rem; */
       line-height: 2rem; 
    }
`;
const TSentence = styled.h2`
    width: 80%;
    margin: 0 auto;
    font-size: 1.5rem;
    line-height: 3rem;
    letter-spacing: 0.1rem;
    color: white;
    @media screen and (max-width: 896px) {
       font-size: 1rem;
    }
    @media screen and (max-width: 613px) {
       font-size: 0.7rem;
    }
    @media screen and (max-width: 446px) {
        width: 100%;
       font-size: 0.7rem;
    }
    @media screen and (max-width: 357px) {
        width: 100%;
       font-size: 0.5rem;
       line-height: 1.3rem;
    }
`;

const AImg = styled.img`
width: 30px;
    @media screen and (max-width: 480px) {
       margin-top: 2rem;
    }
`;


const Svg = styled.svg`
max-width: 520px;
    width: 30vw;
    height: 0 auto;
    margin: 0 auto;
    @media screen and (max-width: 1170px) {
          width: 38vw;
      }
      @media screen and (max-width: 457px) {
          width: 67vw;
      }
      @media screen and (max-width: 457px) {
          width: 80vw;
      }
`;
const SvgS = styled.svg`
    max-width: 700px;
    width: 50vw;
   // height: 0 auto;
    margin: 0 auto;
    margin-top:2rem;
    @media screen and (max-width: 1170px) {
          width: 50vw;
      }
      @media screen and (max-width: 457px) {
          width: 75vw;
      }
      @media screen and (max-width: 457px) {
          width: 80vw;
      }
`;
const Cmargin8 = styled.div`
    margin-top: 80px;
`;
const SIssue = styled.div`
    margin-top: 20px;
`;
const SmarginA = styled.div`
    margin-top: 80px;
`;
/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const Service = () => {

    return (
        <>
        <HeadBlock title='SERVICE - 株式会社レイヴン' description='レイヴンの強み、自社サービス、PM・PMO事業、システム開発事業、ビジネス構築事業についてご覧いただけます。' path='Service' />
            <Header color="white" />
            <SubVisual
                Image={serviceHeaderImg2}
                sub_titleEn="SERVICE"
                subTitleJa="サービス"
            />


            <ScenterInner>
                <Worries>
                    <One>ITを使ったサービスをまるっと構築</One>
                    <TSentence>
                        私たちはサービス創りにおける５つの要素を大切にしており、<br />
                        自社で一貫してサービスを提供できる事業体制をしています。<br />
                        これがレイヴンの最大の強みです。
                    </TSentence>
                </Worries>
            </ScenterInner>


            <SWrapper>
                <Cmargin8 />
                <SectionTitleAnime
                    TitleName="STRONG OF RAVEN"
                    fontSize="2rem"
                    fontFamily="'Roboto Condensed', sans-serif;"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <br />
                <SectionTitleAnime
                    TitleName="レイヴンの強み"
                    fontSize="0.8rem"
                    fontFamily=" 'Noto Sans JP', sans-serif"
                    fontColor="black"
                    backGroundcolor="black"
                />


                <ScenterInner>
                    <SsmallP>ITを使ったサービス創りで不可欠な５つの要素</SsmallP>
                    <ScenterInner>
                        <Svg viewBox="0 0 17078 10864">
                            <use xlinkHref={`${fivestrong}#fivestrong2`}></use>
                        </Svg>
                    </ScenterInner>
                    <StrongOfRavenStrongText />
                </ScenterInner>
            </SWrapper>

            <Cmargin8 />

            <SWrapper>

                <Smargin3 />
                <SectionTitleAnime
                    TitleName="THREE BUSINESSES"
                    fontSize="2rem"
                    fontFamily="'Roboto Condensed', sans-serif;"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <br />
                <SectionTitleAnime
                    TitleName="3つの事業"
                    fontSize="0.8rem"
                    fontFamily=" 'Noto Sans JP', sans-serif"
                    fontColor="black"
                    backGroundcolor="black"
                />
                

                <SthreeP>私たちは５つの領域を３つの事業体制で磨きあげています。</SthreeP>
                <ScenterInner>
                        <SvgS viewBox="0 0 23289 12855">
                        <use xlinkHref={`${Frame4}#Frame4`}></use>
                        </SvgS>
                </ScenterInner>


                <Smargin3 />

                <SList>
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        marginTop="70%"
                        title="PM/PMO事業"
                        text="プロジェクトマネジメント"
                        img={PmpmoImage}
                        alt="PM・PMO事業の画像"
                        navi="/pmpmo"
                    />
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        marginTop="70%"
                        title="システム開発事業"
                        text="UI/UXデザイン/システム開発・システム保守"
                        img={DevelopImage}
                        alt="システム開発事業の画像"
                        navi="/system"
                    />
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        marginTop="70%"
                        title="ビジネス構築事業"
                        text="サービス企画/サービス運用構築・サービス運用"
                        img={BisinessImage}
                        alt="ビジネス構築事業の画像"
                        navi="/business"
                    />
                </SList>
            </SWrapper>

            <Smargin10 />

            <ScenterInner>
                <SWorries>
                    <SectionTitleAnime
                        TitleName="ISSUE"
                        fontSize="2rem"
                        fontFamily="'Roboto Condensed', sans-serif;"
                        fontColor="white"
                        backGroundcolor="white"
                    />
                    <br />
                    <SectionTitleAnime
                        TitleName="こんなお悩みに"
                        fontSize="0.8rem"
                        fontFamily=" 'Noto Sans JP', sans-serif"
                        fontColor="white"
                        backGroundcolor="white"
                    />
                    <SIssue>
                        <Two>ITを使ったサービスを作りたいけどどうして良いのかわからない</Two>
                        <Three>クラウドを使った開発ができる会社を探しているが見つからない</Three>
                        <Four>開発プロジェクトがうまく進んでいなくて困っている</Four>
                    </SIssue>

                    <AImg src={Arrow} alt="三つの矢印" />
                    <Smargin3 />
                    <Five>RAVENが５つの領域と３つの事業体制で解決いたします！</Five>
                </SWorries>
            </ScenterInner>

            <SWrapper>

            </SWrapper>

            <SWrapper >
                <SmarginA />
                <SectionTitleAnime
                    TitleName="WORKS"
                    fontSize="2rem"
                    fontFamily="'Roboto Condensed', sans-serif;"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <br />
                <SectionTitleAnime
                    TitleName="自社サービス"
                    fontSize="0.8rem"
                    fontFamily=" 'Noto Sans JP', sans-serif"
                    fontColor="black"
                    backGroundcolor="black"
                />
                
                <WACC
                    title="R MATCH"
                    text1="株式会社ロード様と共創事業としてサービス・運営をおこなっている介護マッチングプラットフォーム。"
                    text2="「介護保険サービス・障害福祉サービスをお願いしたい方」と「介護保険サービス・障害福祉サービスを行う事業所」を繋げ、ユーザの予定調整にかかる時間を削減しています。"
                    img={RMATCH}
                    width="12rem"
                    height="50px"
                    href="https://www.rmatch.jp/"
                />

            </SWrapper>

            <Footer />
        </>
    );
};