/*====== Define import ======*/
/*------ React ------*/
import React from "react";
import styled from "styled-components";

/*------ Styled Components ------*/

const Snummber = styled.h2`
    position: relative;
    top:-20px;
    text-align: left;
    /* z-index: 100; */
    display: block;
    padding: 2rem 0 2rem 0rem;
    font-size: 3rem;
`
const Border = styled.div`
    position: absolute;
    top:65px;
    left: -10px;
    z-index: -1;
    opacity: 0.5;
    width: 50px;
    height: 20px;
    background-color: #FBE04F;

`
const Stext = styled.h2`
    text-align: left;
    display: block;
    padding: 2rem 0 0 2rem;
    /* padding: 2vw 0 2vw 3vw; */
    font-size: 1.5rem;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        font-size: 1.2rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        font-size: 1.5rem;
    }
    /* ########### 769px以上 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
        font-size: 1.5rem;
    }
`
const Title = styled.div`
    display: flex;
`
const Sp = styled.div`
    letter-spacing: 0.1rem;
    line-height: 1.8rem;
    color: #1C1C1C;
    text-align: left;
    padding: 2rem 0 1rem 2rem;
    /* ########### 599px以下 ########### */
@media (max-width: 599px) {
    line-height: 1.5rem;
    font-size: 0.8rem;
}
/* ########### 600px以上～768px以下 ########### */
@media (min-width: 600px) and (max-width: 768px) {
    line-height: 1.5rem;
    font-size: 1rem;
}
/* ########### 769px以上 ########### */
@media (min-width: 769px) and (max-width: 1169px) {
    line-height: 1.5rem;
    font-size: 1rem;
}
`;


const Sp2 = styled.div`
    letter-spacing: 0.1rem;
    color: #1C1C1C;
    text-align: left;
    padding: 0 0 2rem 2rem;
    
`
const Text = styled.div`
`
const Ttext = styled.div`
    font-size: 1.5rem;
`
const Img = styled.img`
     width: 80%;
     height: auto;
     margin-left: 3rem;
`;
const All = styled.div`
    width: 100%;
    max-width: 1170px;
    margin: 80px auto 0 auto;
`
const Set = styled.div`
    margin: 0 auto;
`;

const Sn = styled.div`
    
`
export const ServiceInterviewBusiness = (props) => {
    // const Nummber = ({ children }) => {
    //     return (
    //         <ServiceInterviewNummber title="aaaa">{children}</ServiceInterviewNummber>
    //     )
    // }
    const { nummber, text, p, pp, text2, img } = props;

    return (
        // Nummber
        <>
            <All>
                <Set>
                    <Title>
                        <Sn>
                            <Snummber>
                            <Border />
                                {nummber}
                            </Snummber>
                        </Sn>

                        <Text>
                            <Stext>{text}</Stext>
                            <Sp>{p}</Sp>
                            <Sp2>{pp}</Sp2>
                            <Ttext>{text2}</Ttext>
                        </Text>
                    </Title>
                    <Img src={img} />
                </Set>
            </All>
        </>
    );
};