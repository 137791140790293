/*====== Define import ======*/
/*------ React ------*/
import { useInView } from "react-intersection-observer";
/*------ CSS ------*/
import styled from "styled-components"
/*====== Define styles ======*/
/*------ Styled Components ------*/
const Ttl = styled.div`
  font-size: ${(props) => props.fontSize};//font-sizeを使い回したい場合があるのでpropsで管理します
  font-family: ${(props) => props.fontFamily};//font-familyを使い回したい場合があるのでpropsで管理します
  text-align: center;
	font-weight:bold;
	color: ${(props) => props.fontColor};
	letter-spacing: 0.07em;
`;
const Trigger1 = styled.span`
	animation-name:bgextendAnimeBase;
	animation-duration:1s;
	animation-fill-mode:forwards;
	position: relative;
	overflow: hidden;//はみ出た要素を隠す
	opacity:0.5;
  
    /*---背景色の動きのCSS---*/
	@keyframes bgextendAnimeBase{
    from{
      opacity:0.5;
    }
    to{
      opacity:1;  
  }
}
`;
/*---背景色伸びてを表示させる---*/
const Trigger2 = styled.span`
&::before{
	content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.backGroundcolor};
    animation:${(props) => props.inView &&'bgLRextendAnime 1s forwards'};
	/*---左から右へ---*/
    @keyframes bgLRextendAnime{
    	0% {
          transform-origin:left;
          transform:scaleX(0);
        }
        50% {
          transform-origin:left;
          transform:scaleX(1);
        }
        50.001% {
          transform-origin:right;
        }
        100% {
          transform-origin:right;
          transform:scaleX(0);
        }
   	}
}
`;
const Trigger3 = styled.span`
	animation-name:bgextendAnimeSecond;//bgextendAnimeSecondというクラス名
	animation-fill-mode:forwards;
	animation-duration:1s;
	animation-delay:0.6s;
  opacity: 0;
	display:${(props) => props.inView ? 'inline-block' : 'none'};
   @keyframes bgextendAnimeSecond{
    	from{
      opacity: 0;  
    	}
     to{
      opacity:1 ;
    	}
  }  
`;

/*====================*/
/*======  Main  ======*/
/*====================*/
export const SectionTitleAnime = (props) => {
	/*====== State ======*/ 
	const { fontSize ,fontFamily, TitleName, fontColor, backGroundcolor } = props; //タイトル名を受け取る

	/*====== function ======*/
  const { ref, inView } = useInView({
    rootMargin:"0px",
    triggerOnce: true
  })

	/*====== JSX ======*/
	/*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return(
        <>
          <Ttl fontSize={fontSize} fontFamily={fontFamily} fontColor={fontColor}>
            <Trigger1>
                <Trigger2 inView={inView} ref={ref} backGroundcolor={backGroundcolor}>
                  <Trigger3 inView={inView} ref={ref}> 
                  {TitleName}
                  </Trigger3>
                </Trigger2> 
            </Trigger1>
          </Ttl>
        
        </>
    );
};
