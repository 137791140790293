/* ==================== Define import ==================== */

/* ---------- React ---------- */
import React from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";


/* ---------- Styled Compornents ---------- */
const Parts = styled.div`
  height: 280px;
  max-width: 800px;
  width: 100%;
  //background-color: #EFEFEF;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin: 0 auto;
  text-align: center;
  /* border: solid 1px #27282F; */
  //transition: 0.5s all;
  @media screen and (max-width: 820px) {
        width: 500px;
        height: 200px;
    }
    @media screen and (max-width: 520px) {
        width: 300px;
        height: 200px;
    }  

`;

const Img = styled.img`
    width: 100%;
    object-fit: cover;
    transition: 0.5s all;

        &:hover{
        transform:scale(1.2,1.2);
        opacity: 0.7;
        transition:0.7s all;
    }

    @media screen and (max-width: 820px) {
        width: 150px;
    }
    @media screen and (max-width: 520px) {
        width: 115px;
    } 
    
`;



const Title = styled.h1`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
    @media screen and (max-width: 820px) {
        font-size: 1rem;
    }
    @media screen and (max-width: 520px) {
        font-size: 1rem;
    }
  
`;



const Moji = styled.div`
    margin: 2;
    /* width: 70%; */
`;

const A = styled.a`
    color : inherit;
    text-decoration: none;
`;

const Texts = styled.div`
    margin-top: 2rem;
`;
const Sdiv = styled.div`
overflow: hidden;
width: 30%;
border: solid 1px gray;
margin: 0 auto;
@media screen and (max-width: 520px) {
width: 40%;
    }  

`;



/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const WACC2 = (props) => {
    const { title, img, alt, href } = props;
    return (
        <>
            <Parts>
                <Sdiv >
                    <A href={href} target="_blank">
                        <Img src={img} alt={alt} />
                    </A>
                </Sdiv>
                <Moji>
                    <Texts>
                        <Title>{title}</Title>
                    </Texts>
                </Moji>
            </Parts>
        </>
    )
}