/* ==================== Define import ==================== */
/* ---------- React ---------- */
import { React, useState } from 'react';
/* ---------- CSS ---------- */
import styled from 'styled-components'
/* ------ Components ------ */
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
/* ---------- useHistory ---------- */
import { useNavigate, useLocation} from "react-router-dom";
/* ---------- module ---------- */
import {  useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios'
import S2 from '../../Images/S2.svg'
import { HeadBlock } from '../../HeadBlock';
import { ContactInputBtn } from './ContactInputButton';
/*====== Define styles ======*/
/*------ Styled Components ------*/
const ContactDev = styled.div`
	width:100%;
    margin: 80px 0;
    min-height:70vh;
    height: fit-content;
`
//フォーム説明文
const FormExplain = styled.p`
    font-size: 1rem;
	letter-spacing: 0.1rem;
	line-height: 20px;
	width:100%;
	text-align: center;
	margin:40px 0 20px 0;
	font-family: 'Noto Sans JP', sans-serif;
	@media(max-width: 699px){
  		font-size:0.7rem;
		width: 80%;
		text-align: left;
		margin: 20px auto;
    }
`;
//確認画面のテーブル大きさ・配置
const ConfirmTable = styled.table`
    max-width:1170px;
    width: 60%;
    font-weight: normal;
    margin: 0 auto;
    font-family: 'Noto Sans JP', sans-serif;
    border-collapse: separate;
    border-spacing: 20px;
    
   
    @media(max-width: 699px){
		width: 90%;
	}
	@media(min-width: 700px) and (max-width: 1170px) {
		width: 60%;

  	
	}
`;
//ステップ画像
const ConfirmFormName = styled.th`
    font-weight: normal;
    text-align: right;
    padding-top: 0;
    padding-bottom: 0;
    width: 180px;
    font-size: 0.7rem;
    letter-spacing:0.1em;
    border-bottom:  1px solid white;
    font-family: 'Noto Sans JP', sans-serif;
@media(max-width: 1170px){
		display:block;
  		border-collapse: collapse;
  		width:90%;
		  margin: 0.5rem auto;
          text-align:left;
	}
`;
//記入した内容のデザイン
const SelectConfirmEntryField = styled.td`
    padding: 12px;
    word-wrap: break-word;
    width: 500px;
    float:center;
    font-size: 0.5rem;
    font-weight: normal;
    border-left:   1px solid #D9D9D9;
    border-top:    1px solid #D9D9D9;
    border-bottom:  1px solid #D9D9D9;
    border-right:   1px solid #D9D9D9;
    margin-bottom:0.5rem;


@media(max-width: 1170px){
		display:block;
  		border-collapse: collapse;
  		width:90%;
		  margin: 0.5rem auto;
		 
	}
`;
//記入した内容のデザイン
const ConfirmEntryField = styled.td`

    padding: 12px;
    word-wrap: break-word;
    width: 500px;
    float:center;
    font-size: 0.5rem;
    font-weight: normal; 
    border-top:    1px solid #D9D9D9;
    border-left:   1px solid #D9D9D9;
    border-bottom:   1px solid #D9D9D9;
    border-right:   1px solid #D9D9D9; 

@media(max-width: 1170px){
		display:block;
  		border-collapse: collapse;
  	
  		width:90%;
		margin: 0.5rem auto;
		 
	}
`;
const ButtonAll = styled.div`
	margin: 0 auto ;
	text-align: center;
`;
//送信ボタン・戻るボタン配置
const ConfirmButtonPlace = styled.div`
    max-width:1170px;
	margin: 60px auto 40px auto;
    display:flex;
    flex-direction:row-reverse;
    justify-content: center;
    @media screen and (max-width:550px){
		display: block;
    }
`;



//戻るボタン配デザイン


//recapcha 規約用
const RecapchaTxt = styled.p`
    font-size:0.5rem;
    color: #747474;
    width:100%;
    text-align: center;
    margin: 10px;
    font-family: 'Noto Sans JP', sans-serif;
`;

const RecapchaLink = styled.a`
    font-size:0.5rem;
    color: #5353ff;
    width:100%;
    text-align: center;
    margin: 10px;
    font-family: 'Noto Sans JP', sans-serif;
`;

const Ssvg = styled.svg`

    height:60px;
    margin-top:70px;
	@media screen and (max-width:899px){
		width: 60%;
    }
	@media screen and (max-width:699px){
		width: 90%;
        margin:50px auto;
        
    }
`;
const Frow = styled.div`
    display: flex;
    justify-content: center;
    	@media screen and (max-width:699px){
		margin: -50px auto;
    }
`;


/*====================*/
/*======  Main  ======*/
/*====================*/
export const ContactConfirmform = (props) => {
    //reCapcha v3
    const [token, setToken] = useState('');             // token
    const { executeRecaptcha } = useGoogleReCaptcha();  // reCapcha execute

    // 入力情報保持
    const location = useLocation();
    // 画面遷移
    const navigate = useNavigate();
    // 入力されたデータを返す
    const setSelectValues = location.state.SelectValues;
    const setNameValues = location.state.NameValues;
    const setFuriganaValues = location.state.FuriganaValues;
    const setCompanyValues = location.state.CompanyValues;
    const setPhoneValues = location.state.PhoneValues;
    const setEmailValues = location.state.EmailValues;
    const setContentsValues = location.state.ContentsValues;


    //================================================
    // メール送信処理
    //================================================
    const sendEmail = async () => {
        // reCapcha execute
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        // get reCapcha token
        const reCaptchaToken = await executeRecaptcha('contact');
        setToken(reCaptchaToken);
        // reCapcha authrization
        axios.post(`${process.env.REACT_APP_PHP_SEND_MAIL_ENDPOINT}/recaptcha.php`, JSON.stringify({ recaptcha_token: reCaptchaToken }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                // reCapcha authrization success
                if (res.data.status === '200') {
                    axios.post(`${process.env.REACT_APP_PHP_SEND_MAIL_ENDPOINT}/send_email.php`, JSON.stringify(location.state), {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                        .then(res => {
                            // send mail success
                            if (res.data.status === '200') {
                                navigate('/contactcomplete');
                                // send mail failed
                            } else if (res.data.status === '401' || res.data.status === '404') {
                                alert(res.data.message)
                            }
                        })
                        // API error
                        .catch(error => {
                            console.error('Request failed:', error);
                        });
                    // reCapcha authrization failed
                } else if (res.data.status === '401' || res.data.status === '404') {
                    alert(res.data.message)
                }
            })
            // API error
            .catch(error => {
                console.error('Request failed:', error);
            });
    }

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/
    return (
        <>
        <HeadBlock title ="CONTACT - 確認 - 株式会社レイヴン" description="確認" path="ContactConfirmform" />
            <Header color="black" />
            <ContactDev>
                <div>
                <Frow><Ssvg viewBox="0 0 982 90"><use xlinkHref={`${S2}#S2`}></use></Ssvg></Frow>
                </div>
                <div>
                <FormExplain>内容をご確認の上、「送信」ボタンをクリックして下さい。</FormExplain>
                </div>
                <ConfirmTable>
                    <thead>
                        <tr>
                            <ConfirmFormName>お問い合わせ種類</ConfirmFormName>
                            <SelectConfirmEntryField>{location.state.SelectValues}</SelectConfirmEntryField>
                        </tr>
                        <tr>
                            <ConfirmFormName>氏名</ConfirmFormName>
                            <ConfirmEntryField>{location.state.NameValues}</ConfirmEntryField>
                        </tr>
                        <tr>
                            <ConfirmFormName>フリガナ</ConfirmFormName>
                            <ConfirmEntryField>{location.state.FuriganaValues}</ConfirmEntryField>
                        </tr>
                        <tr>
                            <ConfirmFormName>会社名・学校名</ConfirmFormName>
                            <ConfirmEntryField>{location.state.CompanyValues}</ConfirmEntryField>
                        </tr>
                        <tr>
                            <ConfirmFormName>電話番号</ConfirmFormName>
                            <ConfirmEntryField>{location.state.PhoneValues}</ConfirmEntryField>
                        </tr>
                        <tr>
                            <ConfirmFormName>メールアドレス</ConfirmFormName>
                            <ConfirmEntryField>{location.state.EmailValues}</ConfirmEntryField>
                        </tr>
                        <tr>
                            <ConfirmFormName>お問い合わせ内容</ConfirmFormName>
                            <ConfirmEntryField>{location.state.ContentsValues}</ConfirmEntryField>
                        </tr>
                    </thead>
                </ConfirmTable>

                <ButtonAll>
                    <ConfirmButtonPlace>
                        <ContactInputBtn 
                            	type="submit"
                            	btn_text="送信"
                            	hoverBackgroundColor="#FBE04F"
                            	width='12rem'
                            	height='3rem'
                                onClick={() => {
                                    sendEmail();
                                }}
                            />
                        <ContactInputBtn 
                        	type="submit"
                        	btn_text="戻る"
                        	hoverBackgroundColor="#FBE04F"
                        	width='12rem'
                        	height='3rem'
                            onClick={() => navigate('/contact', {
                                state: {
                                    setSelectValues,
                                    setNameValues,
                                    setFuriganaValues,
                                    setCompanyValues,
                                    setPhoneValues,
                                    setEmailValues,
                                    setContentsValues,
                                }
                            })} 
                        />
                    </ConfirmButtonPlace>
                </ButtonAll>
                <RecapchaTxt>
                    This site is protected by reCAPTCHA and the Google
                    <RecapchaLink href="https://policies.google.com/privacy" target="blank">Privacy Policy</RecapchaLink> and
                    <RecapchaLink href="https://policies.google.com/terms" target="blank">Terms of Service</RecapchaLink> apply.
                </RecapchaTxt>
            </ContactDev>
            <Footer />

        </>
    );
};