/* ==================== Define import ==================== */

/* ---------- React ---------- */
import React from "react";
import { AboutTile } from "../../Components/about/aboutTile2";
import { SectionTitleAnime } from "../../Components/Common/SectionTitleAnime";

import action1 from "../../Images/action1.jpg"
import action2 from "../../Images/action2.jpg"
import action3 from "../../Images/action3.jpg"
import action4 from "../../Images/action4.jpg"
import action5 from "../../Images/action5.jpg"
import action6 from "../../Images/action6.jpg"
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const About = styled.div`
 width: 100%;
 margin-top: 240px;
 @media (max-width: 768px) {
      margin-top: 120px;
    }
`;
const All = styled.div`
max-width: 1170px;
margin: 60px auto 0px auto;
  gap: 6px 2rem;
`;

const Sdiv = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    @media (min-width: 920px) {
      justify-content: space-between;
    }
    @media (max-width: 920px) {
      justify-content: space-evenly;
    }
    @media (max-width: 620px) {
      justify-content: center; 
    }
`;
const Sdiv2 = styled.div`
  max-width: 1250px;
  margin: 0 auto;
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const AgendaMiniTile = (props) => {
  const Dignity = [
    { key: '1', title: '品格', text1: 'リーダーとして毅然とした態度をとり、会社・人を正しく素早く牽引する', width: '300px', height: '200px', margin:'0 40px 40px 0',img:action1 },
    { key: '2', title: '先見', text1: '物事の先の先をみて、目配り、気配り、根回し、段取りに対して先手を打つ', width: '300px', height: '200px', margin:'0 0 40px 40px',img:action2 },
    { key: '3', title: '協調', text1: '信頼関係を大切にし、全員で協力し合うことを怠らない', width: '300px', height: '200px' , margin:'0 40px 40px 0',img:action3 },
    { key: '4', title: '成果', text1: '常に一石三鳥を考え、誰よりも効率的に成果を出す', width: '300px', height: '200px',margin:'0 0 40px 40px' ,img:action4 },
    { key: '5', title: '成長', text1: '過去の自分にとらわれることなく、常に出来る限りのことをする', width: '300px', height: '200px' , margin:'0 40px 40px 0',img:action5 },
    { key: '6', title: '自力', text1: '人に期待し過ぎず、依存せず、何事も自分で考え、自分で行動する', width: '300px', height: '200px' ,margin:'0 0 40px 40px',img:action6 }
  ]
  return (
    <>
      <Sdiv2>
    <About>
    <SectionTitleAnime
        TitleName="ACTION  POINTER"
        fontSize="32px"
        fontFamily="'Roboto Condensed', sans-serif;"
        fontColor="black"
        backGroundcolor="black"
    />
    <br />
    <SectionTitleAnime
        TitleName="行動指針"
        fontSize="20px"
        fontFamily=" 'Noto Sans JP', sans-serif"
        fontColor="black"
        backGroundcolor="black"
    />
        <All>
          <Sdiv>
            {Dignity.map((item, index) => (
              <AboutTile
                img={item.img}
                title={item.title}
                text1={item.text1}
                width={item.width}
                height={item.height}
                key={item.key}
                margin={item.margin}
              />
              ))}
          </Sdiv>
        </All>
      </About>
        </Sdiv2>
    </>
  )
}

export const Kari = () => {
  return
}