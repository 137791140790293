import React from "react";
import styled from "styled-components";

// components
import { PrivacyTable } from "../../Components/Other/PrivacyTable";
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { HeadBlock } from "../../HeadBlock";

// css

const SPrivacyAll = styled.div`
	max-width: 1170px;
  	width: 90%;
  	margin: 10% auto 15% auto;
  	line-height: 2em;
  	font-size: 0.9rem;
  	font-family: 'Noto Sans JP', sans-serif;
	/* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        margin: 25% auto 15% auto;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
		margin: 15% auto 15% auto;
    }
`;

const SPrivacyTitle = styled.h1`
	text-align: center;
	font-size: 2rem;
  margin-top: 5%;
	margin-bottom: 5%;
	font-weight: bold;
`;

const SPrivacyTime = styled.p`
	text-align: right;
  margin-bottom: 5%;
`;

const SPrivacySubTitle = styled.h2`
	text-align: center;
	font-size: 1.5rem;
	margin-bottom: 5%;
	margin-top: 5%;
	font-weight: bold;
`;

const SPrivacySubSubTitle = styled.h3`
	margin-top: 3%;
`;

const SMg50 = styled.p`
	margin: 5% 0;
	list-style-type: decimal;
`;

const SLeftP = styled.p`
	text-align: right;
`;

const SmarginP = styled.p`
	margin: 3% 0;
`;

const Stable = styled.table`
	border: 1px solid black;
	font-weight: normal;
	border-collapse: collapse;
	width: 100%;
`;

const Sthead = styled.thead`
	background-color: #EAEBF0;
`;

export const Privacy = () => {

	/*------ ナビの部品化 ------*/

	const tableList0301 = [
		{id:"1", th:"お客さま情報", td:"コンタクトセンター事業における商品の発送、関連するアフターサービス、新商品・サービスの案内のため"},
		{id:"2", th:"株主情報", td:"情報公開、配布物の送付、連絡のため"},
		{id:"3", th:"取引先情報", td:"商談及び業務上の諸連絡、受発注業務、請求支払業務のため"},
		{id:"4", th:"従業者情報", td:"従業者の人事労務管理、業務管理、健康管理、セキュリティ管理のため"},
		{id:"5", th:"採用応募者情報", td:"採用業務のため"}
	]

	const tableList0302 = [
		{id:"6", th:"お客さま情報", td:"受託業務のコンタクトセンター業務のため"},
		{id:"7", th:"協力会社要員情報", td:"委託先の協力会社要員情報を取得する場合"},
		{id:"8", th:"採用応募者情報", td:"就職斡旋サイトや人材紹介会社からの採用応募者は採用業務のため"}
	]

	const tableList0401 = [
		{id:"9", th:"（１）第三者に提供する目的", td:"新商品・サービスに関するお知らせのため"},
		{id:"10", th:"（２）提供する個人情報の項目", td:"氏名、住所、会員番号、メールアドレス"},
		{id:"11", th:"（３）提供を受ける者の組織の種類、属性", td:"当社グループ企業のWeb広告掲載会社"},
		{id:"12", th:"（４）個人情報の取りあつかいに関する契約", td:"機密保持契約によって提供先が適切に個人情報保護を行なうよう義務づけています"}
	]

	return (
		<>
		<HeadBlock title ="個人情報保護方針 - 株式会社レイヴン" description="個人情報保護方針" path="Privacy" />
     		<Header color="black" />
			<SPrivacyAll>
					<SPrivacyTitle>個人情報保護方針</SPrivacyTitle>
					<SPrivacyTime>制定日　2018年4月20日</SPrivacyTime>
					<p>当社は、サービス運用構築、コンタクトセンター運用、これらのトータルマネジメントに関するサービスの提供をつうじて、お客さまと社会に貢献することを企業理念として、当社が取りあつかうすべての個人情報の保護について、社会的使命をじゅうぶんに認識し、本人の権利の保護、個人情報に関する法規制等を遵守いたします。</p>
					<p>また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等をつねに認識しながら、その継続的改善に全社を挙げて取り組むことをここに宣言いたします。</p>
					<SMg50>
						<li>当社は、コンタクトセンター運用等に関する事業、ならびに従業者の雇用、人事管理等において取りあつかう個人情報について、適切な取得・利用および提供し、特定された利用目的の達成に必要な範囲を超えた個人情報の取りあつかいを行ないません。また、そのための措置を講じます。</li>
						<li>当社は、個人情報の取りあつかいに関する法令、国が定める指針その他の規範を遵守いたします。</li>
						<li>当社は、個人情報の漏えい、滅失、毀損などのリスクにたいしては、合理的な安全対策を講じて防止すべく、事業の実情に合致した経営資源を注入し、個人情報のセキュリティ体制を継続的に向上させていきます。また、万一の際には、速やかに是正措置を講じます。</li>
						<li>当社は、個人情報取り扱いに関する苦情および相談に対しては、迅速かつ誠実に対応いたします。</li>
						<li>個人情報保護マネジメントシステムは、当社を取り巻く環境の変化と実情をふまえ、適時・適切に見直して継続的に改善をはかります。</li>
					</SMg50>
					<p>本方針は、すべての従業者に配付して周知させるとともに、当社のホームページ等に掲載することにより、いつでもどなたにも入手可能な措置を取るものとします。</p>
					<SLeftP>株式会社 レイヴン<br />代表取締役　山田 浩一郎</SLeftP>
					<p>【個人情報に関する苦情・相談窓口】<br />〒460-0003　愛知県名古屋市中区錦2丁目20番15号 広小路クロスタワー14F<br />株式会社 レイヴン　コーポレート部　個人情報お問い合わせ窓口<br />メールアドレス：rv-PMS@mail.raven-g.com<br />TEL：052‐228‐6589（受付時間 9:00 ～ 18:00）<br />FAX：052‐228‐6590</p>
				<section>
					<SPrivacySubTitle>個人情報の取りあつかいについて</SPrivacySubTitle>
					<dl>
						<dt>
							<SPrivacySubSubTitle>１．事業者の名称</SPrivacySubSubTitle>
						</dt>
						<dd>株式会社 レイヴン</dd>
						<dt>
							<SPrivacySubSubTitle>２．管理者（もしくはその代理人）の氏名または職名、所属および連絡先</SPrivacySubSubTitle>
						</dt>
						<dd>管理者名：個人情報保護管理者<br />所属部署：コーポレート部</dd>
						<dd>TEL：052‐228‐6589（受付時間 9:00 ～ 18:00）</dd>
						<dt>
							<SPrivacySubSubTitle>３．個人情報の利用目的</SPrivacySubSubTitle>
						</dt>
						<dd>
							<SmarginP>（１）本人より直接書面等（ホームページや電子メール等によるものをふくむ。以下「書面」という）に記載された個人情報を取得する場合の利用目的および開示対象個人情報の利用目的</SmarginP>
							<Stable>
								<Sthead>
									<tr>
										<th>分類</th>
										<th>利用目的</th>
									</tr>
								</Sthead>
								<tbody>
								{
									tableList0301.map((tableItem) => {
										return(
											<PrivacyTable th={tableItem.th} td={tableItem.td} key={tableItem.id}/>
										)
									})
								}
								</tbody>
							</Stable>
							<SmarginP>（２）ご本人より直接書面以外の方法で個人情報を取得する際の利用目的</SmarginP>
							<Stable>
								<Sthead>
									<tr>
										<th>分類</th>
										<th>利用目的</th>
									</tr>
								</Sthead>
								<tbody>
								{
									tableList0302.map((tableItem) => {
										return(
											<PrivacyTable th={tableItem.th} td={tableItem.td} key={tableItem.id}/>
										)
									})
								}
								</tbody>
							</Stable>
						</dd>
						<dt>
							<SPrivacySubSubTitle>４．個人情報の第三者提供</SPrivacySubSubTitle>
						</dt>
						<dd>
							<SmarginP>当社では取得した個人情報を、業務目的の達成に必要な範囲において、以下の要領で第三者に提供する場合があります。</SmarginP>
							<Stable>
								<tbody>
								{
									tableList0401.map((tableItem) => {
										return(
											<PrivacyTable th={tableItem.th} td={tableItem.td} key={tableItem.id}/>
										)
									})
								}
								</tbody>
							</Stable>
						</dd>
						<dt>
							<SPrivacySubSubTitle>５．個人情報の取りあつかいの委託</SPrivacySubSubTitle>
						</dt>
						<dd>当社は事業運営上、お客さまにより良いサービスを提供するために業務の一部を外部に委託しています。業務委託先にたいしては、個人情報を預けることがあります。この場合、個人情報を適切に取りあつかっていると認められる委託先を選定し、契約等において個人情報の適正管理・機密保持などによりお客さまの個人情報の漏洩防止に必要な事項を取り決め、適切な管理を実施させます。</dd>
						<dt>
							<SPrivacySubSubTitle>６．個人情報の開示等の請求</SPrivacySubSubTitle>
						</dt>
						<dd>お客さまは、当社にたいしてご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止）に関して、当社「個人情報お問い合わせ窓口」に申し出ることができます。その際、当社はお客さまご本人を確認させていただいたうえで、合理的な期間内に対応いたします。開示等の申し出の詳細につきましては、下記の「個人情報お問い合わせ窓口」までお問い合わせください。</dd>
						<dt>
							<SPrivacySubSubTitle>７．個人情報を提供されることの任意性について</SPrivacySubSubTitle>
						</dt>
						<dd>お客さまが当社に個人情報を提供されるかどうかは、お客さまの任意によるものです。ただし、必要な項目をいただけない場合、各サービス等が適切な状態で提供できない場合があります。</dd>
						<dt>
							<SPrivacySubSubTitle>８．ご本人が容易に認識できない方法による取得する場合について</SPrivacySubSubTitle>
						</dt>
						<dd>クッキー（Cookies）は、お客さまが当社のサイトに再度訪問された際、より便利に当サイトを閲覧していただくためのものであり、お客さまのプライバシーを侵害するものではなく、またお客さまのコンピューターへ悪影響をおよぼすことはありません。　また当社のサイトでは、個人情報を入力していただく部分にはすべてSSL（Secure Sockets Layer）のデータ暗号化システムを利用しております。さらに、サイト内における情報の保護にもファイアーウォールを設置するなどの方策をとっております。ただし、インターネット通信の性格上、セキュリティを完全に保証するものではありません。あらかじめご了承ください。</dd>
						<dt>
							<SPrivacySubSubTitle>９．認定個人情報保護団体について</SPrivacySubSubTitle>
						</dt>
						<dd>当社が加入する認定個人情報保護団体はありません。</dd>
					</dl>
					<p>【個人情報に関する苦情・相談窓口】<br />お客さまの個人情報に関するお問い合わせにつきましては、下記窓口で受け付けております。</p>
					<p>〒460-0003　愛知県名古屋市中区錦2丁目20番15号 広小路クロスタワー14F<br />株式会社 レイヴン　コーポレート部　個人情報お問い合わせ窓口<br />メールアドレス：rv-PMS@mail.raven-g.com<br />TEL：052-228-6589（受付時間 9:00 ～ 18:00）<br />FAX：052-228-6590</p>
				</section>
				<section>
					<SPrivacySubTitle>開示対象個人情報の請求手続きのご案内</SPrivacySubTitle>
					<p>当社で保有している開示対象個人情報に関して、本人またはその代理人からの利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、当社所定の請求書により、以下の手続きにて対応させていただきます。</p>
					<p> </p>
					<dl>
						<dt>
							<SPrivacySubSubTitle>１．「開示等の請求」申出先</SPrivacySubSubTitle>
						</dt>
						<dd>「開示等の請求」は「個人情報お問い合わせ窓口」宛、当社所定の請求書に必要書類・手数料を同封のうえ、郵送によりお願い申し上げます。</dd>
						<dd></dd>
						<dt>
							<SPrivacySubSubTitle>２．「開示等の請求」に際してご提出いただく書類</SPrivacySubSubTitle>
						</dt>
					</dl>
					<dl>
						<dd>「開示等の請求」を行う場合は、(１)の請求書に所定の事項をすべてご記入のうえ、郵送ください。請求書用紙につきましては、下記の該当する用紙を送付いたします。なお、本人確認のため、運転免許証、パスポート等の本人確認書類の写しの同封が必要になります。</dd>
					</dl>
					<br />
					<dl>
						<dd>（１） 当社所定の請求書<br />① 「個人情報に関する開示等の請求票」</dd>
						<SPrivacySubSubTitle><br />３．代理人による「開示等の請求」の場合</SPrivacySubSubTitle>
						<dd>「開示等の請求」をする方が代理人である場合は、２．の（１）の書類に加えて、下記３．（２）の代理人であることを証明する書類の写しのいずれか、および３．（１）代理人ご自身を証明する書類の写しのいずれかを同封してください。</dd>
					</dl>
					<br />
					<dl>
						<dd>（１）代理人であることを証明する書類<br />■ 開示等の求めをすることにつき本人が委任した代理人の場合<br />① 本人の委任状（原本）</dd>
					</dl>
					<dl>
						<dd>■ 代理人が未成年者の法定代理人の場合<br />① 戸籍謄本<br />② 住民票（続柄の記載されたもの）<br />③ その他法定代理権の確認ができる公的書類</dd>
					</dl>
					<dl>
						<dd>■ 代理人が成年被後見人の法定代理人の場合<br />① 後見登記等に関する登記事項証明書<br />② その他法定代理権の確認ができる公的書類</dd>
					</dl>
					<br />
					<dl>
						<dd>（２）代理人ご自身を証明する書類の写し<br />① 運転免許証<br />② パスポート<br />③ マイナンバーカード(表面)<br />④ 健康保険の被保険者証<br />⑤ 住民票<br />⑥ 住民基本台帳カード<br />※ なお、本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください</dd>
						<dt>
							<SPrivacySubSubTitle>４．「開示等の請求」の手数料およびその徴収方法</SPrivacySubSubTitle>
						</dt>
						<dd>利用目的の通知または開示の請求の場合にのみ、１回の請求につき、以下の金額（当社からの返信費をふくむ）を申し受けます。下記金額ぶんの郵便定額小為替を請求書類の郵送時に同封してください。<br />手数料金額：1,000円</dd>
						<dt>
							<SPrivacySubSubTitle>５．「開示等の請求」にたいする回答方法</SPrivacySubSubTitle>
						</dt>
						<dd>原則として、請求書記載の本人の住所宛に書面（封書郵送）にて回答いたします。<br />※ 「開示等の請求」にともない取得した個人情報は、開示等の請求への対応に必要な範囲にかぎり取りあつかいます。
							<p> </p>
							<p>以下の場合には、「開示等の請求」にお応えできない場合があります。その場合は、その旨と理由をお伝えいたします。また、不開示の場合についても手数料をいただきますのでご承知おきください。<br />① 本人、または代理人の確認ができない場合<br />② 所定の申請書類に不備があった場合<br />③ 開示等の請求の対象が「開示対象個人情報」（※）に該当しない場合<br />④ 利用目的を本人に通知し、または公表することによって本人または第三者の生命、身体、財産そのほかの権利利益を害するおそれがある場合<br />⑤ 利用目的を本人に通知し、または公表することによって当社の権利または正当な利益を害するおそれがある場合<br />⑥ 国の機関または地方公共団体が法令の定める事務を遂行することにたいして協力する必要がある場合であって、利用目的を本人に通知し、または公表することによって当該事務の遂行に支障をおよぼすおそれがある場合</p>
							<p>※ 開示対象個人情報とは、体系的に構成した情報の集合物を構成する個人情報であって、当社が、本人から求められる開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止の求めのすべてに応じることができる権限を有するものです。ただし、以下 a) ～ d) のいずれかに該当する場合は開示対象個人情報には該当しません。<br /><br />a) 当該個人情報の存否があきらかになることによって、本人または第三者の生命、身体または財産に危害がおよぶおそれのあるもの<br />b) 当該個人情報の存否があきらかになることによって、違法または不当な行為を助長し、または誘発するおそれのあるもの<br />c) 当該個人情報の存否があきらかになることによって、国の安全が害されるおそれ、他国もしくは国際機関との信頼関係が損なわれるおそれ、または他国もしくは国際機関との交渉上不利益を被るおそれのあるもの<br />d) 当該個人情報の存否があきらかになることによって、犯罪の予防、鎮圧または捜査そのほかの公共の安全と秩序維持に支障がおよぶおそれのあるもの</p>
						</dd>
					</dl>
				</section>
			</SPrivacyAll>
      <Footer />
		</>
	);
}
