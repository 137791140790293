/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";

/* ------ Icon ------ */
/* ------ Library ------ */
/* ------ Images ------ */
import name from "../../Images/Recruit_png/スクリーンショット_2023-06-15_14.29.25-removebg-preview.png";
/* ------ CSS ------ */
import styled from "styled-components";
/*====== Define styles ======*/
/*------ Styled Components ------*/

const Smessege = styled.h2`
    font-size: 2.5rem;
    line-height: 2em;
    font-weight: bold;
    margin-bottom: 60px;
    @media (max-width: 1000px) {
    font-size: 1.6rem;
    margin-left: 2vw;
  }
    @media (max-width: 670px) {
    font-size: 1rem;
    margin-left: 2vw;
    margin-bottom: 20px;
  }
  @media (max-width: 400px) {
    font-size: 1rem;
    margin-left: 2vw;
  }
`;
//山田さんの名前
const TName = styled.div`
  /* text-align: right; */
  /* margin: 0 auto; */
  margin-top:5rem ;
  float: right;
  width: 300px;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
      width: 120px;
        margin-top:2rem ;
      }
      /* #### 769px以上 #### */
      @media (min-width: 769px) and (max-width: 1169px)  {
      margin-top:3rem ;
    }
`;
//全体
const All = styled.div`
 margin: 0 auto;
 background-color: #FFEF61;
 box-shadow: 0 10px #5CC6C0;
`;
//トップメッセージ全体
const MessageArea = styled.div`
    max-width: 1170px;
    width: 90%;
    padding: 0 0 60px 0;
    margin: 0 auto;
    @media (max-width: 670px) {
      padding: 0 0 20px 0;
  }
`;
const Simg = styled.img`
  width: 80%;
 
`;

/*====================*/
/*======  Main  ======*/
/*====================*/
export const TMessage = () => {

    return(
        <>
        <All>
          <MessageArea>
            <Smessege>
              ITって特別難しいわけではない。<br />
              どんな仕事も続けるからうまくなっていく。<br/>
              ITのメリットは、<br/>
              自分ひとりいればサービスが作れてしまう。<br/>
              そのポテンシャルを活かすならば、<br/>
              そういった機会が必要。<br/>
              うちではその機会を与えるという事を<br/>
              ポリシーにしている。<br/>
              なので、
              ITのポテンシャルを発揮したい人は、<br/>
              是非 RAVEN に！
            <TName><Simg src={name} alt="山田 浩一郎" /></TName>
            </Smessege>
          </MessageArea>
        </All>
        </>
    )
}