/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
import { useState } from "react";
/* ------ image ------ */
import companyVisual from "../../Images/3438754_l.jpg";
/* ------ Components ------ */
import { Header } from "../../Components/Common/Header";
import { SubVisual } from "../../Components/Common/SubVisual";

import { Footer } from "../../Components/Common/Footer";
import styled from "styled-components";

import { CeoMessage } from "../../Components/about/ceoMessage";
import { CorpoPhi } from "../../Components/about/about_corpo_phi";
import { CorporateP } from '../../Components/about/corpophi';
import { AgendaMiniTile } from '../../Components/about/action_agenda_tile';
import { HeadBlock } from "../../HeadBlock";
/*====== Define styles ======*/
const BgC = styled.div`
    background-color: #fff;
    padding: 80px 0 0 0;
    min-height: 70vh;
    margin-bottom: 80px;
`;

const Button = styled.div`
margin: 0 10px 0px 10px;
`
const ButtonBox = styled.div`
    display:flex;
    justify-content: center;
    margin: 0 0 70px 0;
    @media screen and (max-width: 900px) {
        margin: 40px 0 40px 0;
    }
`
const ButtonFlex = styled.div`
display: flex;
 @media screen and (max-width: 900px) {
     display: block;
    }
`;

const DataBox = styled.div`
    margin: 0 0 80px 0;
    opacity:1;
    animation-name:sample01;
    animation-duration:2s; 
    animation-iteration-count:1;
`;
/*====================*/
/*======  Main  ======*/
/*====================*/
export const About = () => {
    const Categorydata = sessionStorage.getItem("categorydata") ?? 'CorpoPhi';
    const [data, setData] = useState(Categorydata)
    const buttonData = [
        { key: '1', id: 'CorpoPhi', height: '3rem', color: '#27282F', hoverBackgroundColor: '#FBE04F', text: ' 企業理念 〉' },
        { key: '2', id: 'AgendaMiniTile', height: '3rem', color: '#27282F', hoverBackgroundColor: '#FBE04F', text: ' 行動指針 〉' },

    ]
    const buttonData2 = [
        { key: '1', id: 'CorporateP', height: '3rem', color: '#27282F', hoverBackgroundColor: '#FBE04F', text: ' 社訓 〉' },
        { key: '2', id: 'ceoMessage', height: '3rem', color: '#27282F', hoverBackgroundColor: '#FBE04F', text: ' 代表メッセージ 〉' },
    ]





    const onClickCategory = (item) => {
        const buttonDataCategory = item


        if (buttonDataCategory === 'CorpoPhi') {
            setData('CorpoPhi')
        } else if (buttonDataCategory === 'ceoMessage') {
            setData('ceoMessage')
        } else if (buttonDataCategory === 'CorporateP') {
            setData('CorporateP')
        } else if (buttonDataCategory === 'AgendaMiniTile') {
            setData('AgendaMiniTile')
        }
        sessionStorage.setItem("categorydata", data);

    }
    return (
        <>
            <HeadBlock title='	ABOUT - 株式会社レイヴン' description='レイヴンの企業理念、行動指針、社訓、代表メッセージをご覧いただけます。' path='About' />
            <Header color="white" />
            <SubVisual Image={companyVisual} sub_titleEn="ABOUT" subTitleJa="レイヴンについて" />
            <BgC>
                {/* <ButtonBox>
                    <ButtonFlex>
                        {buttonData?.map((item, index) => (
                            <div key={index}>
                                <Button onClick={() => onClickCategory(item.id)}>
                                    <AboutBtn
                                        // width={item.width}
                                        height={item.height}
                                        // id={item.id}
                                        color={item.color}
                                        // backColor={item.backColor}
                                        hoverColor={item.hoverColor}
                                        hoverBackgroundColor={item.hoverBackgroundColor}
                                        btn_text={item.text}
                                        id={data === item.id ? 'true' : 'false'}
                                        margin=' 0 0 10px 0'
                                    />
                                    <div>{onClickCategory()}</div>
                                </Button>
                            </div>
                        ))}

                        {buttonData2?.map((item, index) => (
                            <div key={index}>
                                <Button onClick={() => onClickCategory(item.id)}>
                                    <AboutBtn
                                        // width={item.width}
                                        height={item.height}
                                        // id={item.id}
                                        color={item.color}
                                        // backColor={item.backColor}
                                        hoverColor={item.hoverColor}
                                        hoverBackgroundColor={item.hoverBackgroundColor}
                                        btn_text={item.text}
                                        id={data === item.id ? 'true' : 'false'}
                                        margin=' 0 0 10px 0'
                                    />
                                    <div>{onClickCategory()}</div>
                                </Button>
                            </div>
                        ))}
                    </ButtonFlex>
                </ButtonBox> */}
                <DataBox>
                    <CorpoPhi />
                    <AgendaMiniTile />
                    <CorporateP />
                    <CeoMessage />
                </DataBox>
            </BgC>
            <Footer />
        </>
    );
}