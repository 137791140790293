/*====== Define import ======*/
/*------ React ------*/
import React from "react";
/* ==================== Define styles ==================== */
/* ---------- Styled Compornents ---------- */
import styled from "styled-components";
//全体
const Ilist = styled.div`
  /* margin: 5vh auto 5vh auto; */
  margin: 0 auto;
  max-width: 1170px;
  width: 80%;
  text-align: center;
  @media screen and (min-width:599px){
    width: 100%;
    }
`;
//横並べ
const Iul = styled.ul`
text-align: center;
margin: 0 auto;
  @media screen and (min-width:599px){
    display: flex;
  flex-wrap: wrap;
    }
`;
//写真のレイアウト
const Ili = styled.li`
  list-style: none;
  text-align: center;
  padding-bottom: 2vh;
  margin: 0 auto;
  @media screen and (min-width:599px){
    width: 32%;
    padding-top: 2vh;
    }
`;
//写真サイズ
const Aimg = styled.img`
  width:100%;
`;
//写真サイズ
const Bimg = styled.img`
  width:100%;
`;
//写真サイズ
const Cimg = styled.img`
  width:100%;
`;
//写真サイズ
const Dimg = styled.img`
  width:90%;
`;
//写真サイズ
const Eimg = styled.img`
  width:90%;
`;
//写真サイズ
const Fimg = styled.img`
  width:90%;
`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */
//画像が３枚の時
export const Iimages = (props) => {
  const { images, Bimages, Cimages } = props;
  return(
    <>
    <Ilist>
      <Iul>
        <Ili>
        <Aimg src={images} />
        </Ili>

        <Ili>
        <Bimg src={Bimages} />
        </Ili>

        <Ili>
        <Cimg src={Cimages} />
        </Ili>
      </Iul>
    </Ilist>
    </>
  )
};

//画像が６枚の時
export const BIimages = (props) => {
  const { images, Bimages, Cimages, Dimages, Eimages, Fimages } = props;
  return(
    <>
    <Ilist>
      <Iul>
        <Ili>
        <Aimg src={images} />
        </Ili>

        <Ili>
        <Bimg src={Bimages} />
        </Ili>

        <Ili>
        <Cimg src={Cimages} />
        </Ili>
        <Ili>
        <Dimg src={Dimages} />
        </Ili>

        <Ili>
        <Eimg src={Eimages} />
        </Ili>

        <Ili>
        <Fimg src={Fimages} />
        </Ili>
      </Iul>
    </Ilist>
    </>
  )
};