/* ==================== Define import ==================== */

/* ---------- React ---------- */
import React from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const Pink = styled.div`
  font-size: bold;
`;

const PlanText = styled.p`
  width: 250px;
  margin-top: 10px;
  text-align: left;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  font-size: 1rem;
`;

const Set = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 30px;
    //margin-left: 50px;
    @media screen and (max-width: 566px) {
       margin-right: 0px;
    }
`;

const BSet = styled.div`
  
`;


const MiniSet = styled.div`
    display: flex;
`;



/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const ServiceTop = (props) => {

    const { number, color, name, text } = props;
    return(
      <>
       <Set>
        <BSet>
        <MiniSet>
            <h1 style={{color}}>
              <Pink>{number}</Pink>
            </h1>
            <h1>&nbsp;{name}</h1>
        </MiniSet>
        <PlanText>{text}</PlanText>
        </BSet>
       </Set>
      </>
    )
}