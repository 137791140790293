/*====== Define import ====== */
/* ------ Ract ------ */
import React, { useState } from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
/* ------ Images ------ */
/* ------ Icon ------ */


/*====== Define styles ======*/
/*------ Styled Components ------*/
const SaccordionItem = styled.div`
    margin: 2% 0;
    background-color: #fff;
`;
  
const SaccordionSection = styled.div`
    border: 1.5px solid #ccc;
`;

const SaccordionP = styled.div`
    font-size: 1rem;
`;

const SaccordionTitle = styled.h3`
    position: relative;
    cursor: pointer;
    font-size:1rem;
    font-weight: normal;
    padding: 4% 3% 4% 50px;
    transition: all .5s ease;
    /* プラスとバツのアニメーション */
    ::before,::after{
        position: absolute;
        content:'';
        width: 15px;
        height: 2px;
        background-color: #333;
    }
    ::before{
        top:48%;
        left: 15px;
        transform: ${(props) => props.index === props.clicked ? "rotate(45deg)" : "rotate(0deg)"};
    }
    ::after{
        top:48%;
        left: 15px;
        transform: ${(props) => props.index === props.clicked ? "rotate(-45deg)" : "rotate(90deg)"};
    }
`;

// アコーディオンのanswer部分の開閉
const Sbox = styled.div`
    max-height: ${(props) => props.index === props.clicked ? "auto" : 0 };/*はじめは非表示*/
    overflow: hidden;
    transition: max-height 0.3s, padding 0.3s;
    transition: all .3s;
    background: #f3f3f3;
    margin: ${(props) => props.index === props.clicked ? "0 3% 3% 3%" : "0 3% 0 3%" };
    padding: ${(props) => props.index === props.clicked ? "5%" : "0 5%" };
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const Accodion = (props) => {

    /*====== Instance ======*/
    /*====== variables ======*/
    const { index, question, answer} = props;

    /*====== State ======*/
    const [clicked, setClicked] = useState(100);
    //押したアコーディオンを開く変数　（初期値はアコーディオンのコンテンツ量より多く）

    /*====== Data ======*/
    /*====== function ======*/
    const changedClicked = (index) => {
        if (clicked === index) {
            return setClicked(100);//アコーディオンを2回連続クリックすると閉じる
        }
        setClicked(index);//clickedをindexにしてアコーディオンの開閉を制御
    };

    /*====== Jsx ======*/

    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return(
        <>
            <SaccordionItem>
                <SaccordionSection
                    onClick={()=>{
                        changedClicked(index)
                    }}
                >
                    <SaccordionTitle 
                        clicked={clicked} 
                        index={index}
                        key={index}
                    >{question}</SaccordionTitle>
                    <Sbox 
                        clicked={clicked} 
                        index={index}
                    >
                        <SaccordionP>{answer}</SaccordionP>
                    </Sbox>
                </SaccordionSection>
            </SaccordionItem>
        </>
    );
};