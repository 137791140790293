/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";

/* ---------- Images ----- ----- */
import One from "../../Images/Recruit_Number/RAVEN_icon_1.svg"
import Two from "../../Images/Recruit_Number/RAVEN_icon_2.svg"
import Three from "../../Images/Recruit_Number/RAVEN_icon_3.svg"
import Four from "../../Images/Recruit_Number/RAVEN_icon_4.svg"
import Five from "../../Images/Recruit_Number/RAVEN_icon_5.svg"
import Six from "../../Images/Recruit_Number/RAVEN_icon_6.svg"
import Seven from "../../Images/Recruit_Number/RAVEN_icon_7.svg"
import Eight from "../../Images/Recruit_Number/RAVEN_icon_8.svg"
import Nine from "../../Images/Recruit_Number/RAVEN_icon_9.svg"
import Ten from "../../Images/Recruit_Number/RAVEN_icon_10.svg"
import Eleven from "../../Images/Recruit_Number/RAVEN_icon_11.svg"
import Twelve from "../../Images/Recruit_Number/RAVEN_icon_12.svg"
import MBTI01 from "../../Images/Recruit_Number/MBTI_01.svg"
import MBTI02 from "../../Images/Recruit_Number/MBTI_02.svg"





/* ---------- Styled Compornents ---------- */
//全体のcss
const NumberWrapper = styled.div`
    /* margin: 0 1rem 10vh 1rem; */
    margin:10vh auto;
    max-width: 1170px;
    width: auto;
`;

//画像全体のcss
const Ul = styled.ul`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
//画像一つのcss
const Li = styled.li`
    text-align: center;
    list-style: none;
    width: 30%;
    justify-content: space-between;
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
    width: 50%;
      }
`;
//svgのcss
const Svg = styled.svg`
    width: 80%;
    transition:1s all;
        /* レスポンシブ */
    //スマホ画面以外(599px以上)はホバーしたら動きをつけます。
    @media (min-width: 599px)  {
            &:hover {
                transform:scale(1.2,1.2);
                transition:1s all;
            }
        }
`;

//MBTIの枠
const SLi01 = styled.li`
    text-align:center;
    list-style-type: none;
    width:80%;
    justify-content:center;
    margin-top:80px;
    /* ########### 599px以下の時に非表示 ########### */
    @media (max-width: 599px) {
    display: none;
    }
`;

const SLi02 = styled.li`
    text-align:center;
    list-style-type: none;
    width:100%;
    justify-content:center;
    margin-top: 48px;
    margin-bottom: -28px;
    /* ########### 600px以上の時に非表示 ########### */
    @media (min-width: 600px) {
        display: none;
    }
`;

//MBTIのsvgcss
const Ssvg = styled.svg`
    transition:1s all;
        /* レスポンシブ */
    //スマホ画面以外(599px以上)はホバーしたら動きをつけます。
    @media (min-width: 599px)  {
            &:hover {
                transform:scale(1.1,1.1);
                transition:1s all;
            }
        }
`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */



export const NumberPart = () => {
    return (
        <>

            <NumberWrapper>
                <Ul>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${One}#One`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Two}#Two`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Three}#Three`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Four}#Four`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Five}#Five`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Six}#Six`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Seven}#Seven`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Eight}#Eight`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Nine}#Nine`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Ten}#Ten`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Eleven}#Eleven`}></use>
                        </Svg>
                    </Li>
                    <Li>
                        <Svg viewBox="0 0 1 1">
                            <use xlinkHref={`${Twelve}#Twelve`}></use>
                        </Svg>
                    </Li>
                    <SLi01>
                        <Ssvg viewBox="0 0 980 790">
                            <use xlinkHref={`${MBTI01}#MBTI01`}></use>
                        </Ssvg>
                    </SLi01>
                    <SLi02>
                        <Ssvg viewBox="0 0 980 1488">
                            <use xlinkHref={`${MBTI02}#MBTI02`}></use>
                        </Ssvg>
                    </SLi02>

                </Ul>
            </NumberWrapper>
        </>
    );
};