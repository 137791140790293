/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Style ---------- */
import styled from "styled-components";
/* ---------- components ---------- */
import { HeadBlock } from "../../HeadBlock";
import { KeyVisual } from "../../Components/Recruit/InterviewKeyVisual";
import { NamePlate } from "../../Components/Recruit/InterviewNamePlate";
import { BAnswer } from "../../Components/Recruit/InterviewContents";
import { Iimages } from "../../Components/Recruit/InterviewPhoto";
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { InterviewTransionList } from "../../Components/Recruit/InterviewTransionList";

/* ---------- Images ---------- */

/*----------キービジュアル----------*/
import KeyVisualImg from "../../Images/Recruit_image/yamamoto01.jpg"
/*----------３つの連なる画像----------*/
import Timages1 from "../../Images/Recruit_image/yamamoto03.jpg"
import Timages2 from "../../Images/Recruit_image/yamamoto05.jpg"
import Timages3 from "../../Images/Recruit_image/yamamoto06.jpg"

const Height = styled.div`
height: 8rem;
@media (max-width: 599px) {
height: 2rem;
}
`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Ymmt = (props) => {
  return (
    <>
      <HeadBlock title="RECRUIT - 山本 彩乃 - 株式会社レイヴン" description="インタビュー山本" path="Ymmt" />

      <Header color="white" />

      <KeyVisual
        image={KeyVisualImg}
      />

      <NamePlate
        EngName="Ayano Yamamoto"
        JpnName="山本 彩乃"
        year="2022年入社"
        division="コーポレート部"
      />
      <Height />

      <BAnswer
        color="#FFEF61"
        Ctitle="入社して感じたこと"
        Ctext="自分のペースで仕事ができる、働きやすい職場だということです。入社前の会社説明会では、体育会系の会社で熱血な人たちばかりで、バリバリ働いているんだろうなという印象を受けました。ですが、実際に入社したらそういった感じではなく、仕事をバリバリやる時はやるけど、業務が落ち着いている時は自由な雰囲気で、メリハリのある職場だと印象が変わりました。頑張り続けないといけないというわけではなく、意外と自分のペースで頑張っていいんだなと思えるような、働きやすい雰囲気があります。そういった雰囲気や自由な時間に働けるサポート体制もあったため、思っていたより肩の力を抜くことができています。"

        Dtitle="入社して大変だったこと"
        Dtext="入社当初は人事労務の知識が全く無く、分からないことばかりでした。社会人になると発生してくる保険料や税金のことなども知らなかったため、社会保険と健康保険の違いや住民税は給料から引かれる事など、基本的なレベルから吸収していきました。1年目はとにかくついていくことに必死でしたが、助けてもらいながら少しずつ知識を蓄え、2年目からはなんとなく物事の繋がりなどが理解できるようになりました。バラバラだった知識が少しずつ体系的に理解できるようになり、自信が持てるようになりました。入社当初は不安が大きかったものの、周りの人に支えられながら成長していける環境でよかったなと実感しています。"

        Etitle="今どういう仕事をしているか"
        Etext="コーポレート部では会社の「ひと・もの・かね」を管理しています。普段の業務は、入退社する人の管理や売り上げや支払いの管理、会社に必要なPCや備品の購入などを行います。時期によって業務内容は変化しますが、現在は少ない人数でも効率的に業務を進めるために、コーポレート部の通常業務をAIに任せることや、ツールを使用して作業を効率化できないかを検討し、導入するためにトライアルで試しています。また、パスワードマネージャーのツールを使用しパスワード管理を行うことで、業務の効率化を目指しています。このツールは大きい企業の情報システム部が使用することが多いので、知識がある人向けに作られています。私は未経験なのでわからない単語ばかりで苦労していますが、サーバーの勉強や専門用語を調べながら使用しています。"
      />

      <Iimages
        images={Timages1}
        Bimages={Timages2}
        Cimages={Timages3}
      />
      <InterviewTransionList />
      <RecruitFooter
        display="block"
      />
      <Footer />
    </>
  )
};
