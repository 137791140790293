/*====== Define import ======*/
/*------ React ------*/
import React from "react";
import {  useNavigate } from "react-router-dom";
import {  useEffect, useState } from "react";
import { TopNewsParts } from "./TopNewsParts";
import { GlobalBtn } from "../Common/GlobalBtn";
/*------ CSS ------*/
import styled from "styled-components";
/*------ Img ------*/
import img1 from "../../Images/PHITOP.JPG"
import img2 from "../../Images/NewsImage/Newsarticle.JPG"
import img3 from "../../Images/RMATCH.jpg"
import img4 from "../../Images/NewsImage/internship.JPG"
import img5 from "../../Images/PHITOP24.JPG"
import img6 from "../../Images/HP_Re.JPG"
import img7 from "../../Images/RMATCHUp.jpg"
import img8 from "../../Images/NewsImage/internship24.JPG"
/*====== Define styles ======*/
const Total = styled.div``
const Snews = styled.div`
    list-style: none;
    max-width: 1170px;
    width: 100%;
    /* text-align: left; */
    display: flex;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;
    margin: 1rem auto;
    justify-content: center;

    @media screen and (max-width: 1169px) {
      justify-content: flex-start;
    }`

const SbtnWrapper = styled.div`
    text-align: center;
    margin: 0 auto 10vh 0;
`;

export const TopNews = () => {
  const [loadIndex] = useState(4);
  // const [isEmpty, setIsEmpty] = useState(false);
  const [currentPost, setCurrentPost] = useState([]);
  const navigate = useNavigate();
  const newsData = [
    { id: '1', year: '2023', month: '06', date: '26', kind: 'CORPORATE', text: '入社式を行いました', img: img1 },
    { id: '2', year: '2023', month: '06', date: '26', kind: 'CORPORATE', text: 'ホームページをリニューアルしました', img: img2 },
    { id: '3', year: '2023', month: '06', date: '26', kind: 'CORPORATE', text: '自社サービスのトライアル運用開始しました', img: img3 },
    { id: '4', year: '2023', month: '06', date: '26', kind: 'RECRUIT', text: 'インターンシップ開催', img: img4 },
    { id: '5', year: '2024', month: '04', date: '01', kind: 'CORPORATE', text: '入社式を行いました', img: img5 },
    { id: '6', year: '2024', month: '05', date: '01', kind: 'CORPORATE', text: '自社サービス（トライアル）をアップデートしました', img: img7 },
    { id: '7', year: '2024', month: '05', date: '07', kind: 'RECRUIT', text: 'インターンシップ開催', img: img8 },
    { id: '8', year: '2024', month: '05', date: '22',kind: 'CORPORATE', text: 'ホームページをリニューアルしました', img: img6 },
]

  useEffect(() => {
    setCurrentPost(newsData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

 // 画面遷移とstateの値を渡す
 const onClick = (item) => {
  navigate('/newspages', {
      state: { id: item, path: '/NewsList',
             
       }
  })};
  return (
    <>
      <Total>
        <Snews>
          {currentPost.slice(-loadIndex).reverse().map((item, index) => (
            <TopNewsParts
              border="0.5px solid #1C1C1C"
              color={item.kind}
              backColor="corporation"
              hoverColor="#6d9bc3"
              hoverBackColor="corporation"
              date={`${item.year}.${item.month}.${item.date}`}
              category={item.kind}
              p={item.text}
              value={item.value}
              onClick={() => onClick(item.id)}
              img={item.img}
              key={index}
            />
          ))}
        </Snews>
          {/* <Button
            disabled={isEmpty ? true : false}
            onClick={displayMore}
            variant="contained"
          >
          </Button> */}
          <SbtnWrapper>
            <GlobalBtn
              variant="contained"
              btn_text="MORE　〉"
              width="12rem"
              height="3rem"
              hoverColor="#27282F"
              hoverBackgroundColor="rgb(251,224,79)"
              navi="/news"
            />
          </SbtnWrapper>


      </Total>
    </>
  );
};