/*====== Define import ====== */
/* ------ Ract ------ */
import React from "react";
import { useNavigate } from "react-router-dom";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
/* ------ Images ------ */
/* ------ Icon ------ */


/*====== Define styles ======*/
/*------ Styled Components ------*/
const SmediaItem = styled.li`
    display: block;
    width: ${(props) => props.width};//40%
    padding-top: ${(props) => props.paddingTop};//38.5%
    margin: 0.5%;
    cursor: pointer;
    position: relative;
    transition: 0.5s all;
    overflow: hidden;
    &:hover {
        transition: 0.5s all;
        opacity: 1;
    }
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        width: 49%;
        padding-top: 49%;
    }
`;


const SmediaInner = styled.div`
    margin: 160% 0 0 0;
    margin-top: ${(props) => props.marginTop};
    color: black;
    position: absolute;
    top: 0;
    z-index: 5;
    padding: 2% 5%;
    background-color: white;//#27282F
    transition:0.5s all;
    pointer-events: none;
    ${SmediaItem}:hover & {
        background-color: rgb(251,224,79);
        transition:0.5s all;
    }
`;

const SmediaTitle = styled.h1`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        font-size: 0.8rem;
        font-weight: normal;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        font-size: 1rem;
    }
`;

const SmediaContent = styled.p`
    font-size: 0.8rem;
    white-space: nowrap;
    padding-top: 2%;
    @media (max-width: 768px) {
        display: none;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        font-size: 0.6rem;
    }
`;

const SmediaImg = styled.img`
    position: absolute;
    border-style: ${(props) => props.border};
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    transition:0.7s all;
    ${SmediaItem}:hover & {
        transform:scale(1.2,1.2);
        opacity: 0.7;
        transition:0.7s all;
    }
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const Tile = (props) => {

    const navigate = useNavigate();  

    /*====== Instance ======*/
    /*====== variables ======*/
    const {  img, alt, text, width, paddingTop, title, href, marginTop,navi } = props;

    /*====== State ======*/
    /*====== Data ======*/
    /*====== function ======*/
    /*====== Jsx ======*/

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/
    return (
        <>
            <SmediaItem 
             onClick={()=>{
                navigate(navi);
             }}
                width={width}
                paddingTop={paddingTop}
            >
                <SmediaInner marginTop={marginTop}>
                    <SmediaTitle>{title}</SmediaTitle>
                    <SmediaContent>{text}</SmediaContent>
                </SmediaInner>
                <a href={href}>
                    <SmediaImg src={img} alt={alt} />
                </a>
            </SmediaItem>
        </>
    );
};