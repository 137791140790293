/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
import { useEffect, useState } from "react";
/* ------ CSS ------ */
import "../../App.css";
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
import { HeaderLogoParts } from "./HeaderLogoParts";
import { GlobalNavParts } from "./GlobalNavParts";
/* ------ Icon ------ */
import { FiMenu } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { IconContext } from "react-icons";
import { useLocation } from "react-router-dom";

/*====== Define styles ======*/
/*------ Styled Components ------*/
const SHeaderInner = styled.div`
    background-color: ${(props) => props.flg ? props.backgroundColor1 : props.backgroundColor2}; //スクロール時に背景色が変わるようにpropsで管理
    position: fixed;
    top:0;
    display: flex;
    align-items: center;
    width:100%;
    padding: 2vh 0;
    z-index: 99;
`;

const SUl = styled.ul`
    display:${(props) => props.display ? "flex" : "block"}; 
    margin:0 auto;
    width:65vw; 
`;

const Shun = styled.button`
    margin: 0 auto;
    text-align: right;
    display: inline-block;
    background: none;
    border: none;
    width: 10vw;
`;

const ShunSpan = styled.span`
    position: fixed;
    margin: 1.7vh 2vw 0 0;
    top: 0;
    right: 0;
    z-index: 90;
    // レスポンシブ 
    @media (min-width:1001px) {
          display: none;
      }
`;

const SModalNav = styled.div`
    display: ${(props) => props.isVisible ? "none" : "flex"};
    background-color: ${(props) => props.backGroundModal} ; 
    padding: 5vh 0 0 0;
    position: fixed;
    top: 0;
    z-index: 90;
    width: 100%;
    @media (min-width:1001px) {
        display: none;
    }
`;

const SnaviResponsive = styled.div`
    margin:0 auto;
    @media (max-width:1001px) {
        display: none;
    }
`;

const Scenter = styled.div`
    text-align: center;
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const Header = (props) => {

	/*====== variables ======*/
    const { color } = props;
    /*------ ナビの部品化 ------*/
    const NaviList = [
        { route: "/news", itemEn: "NEWS", itemJp: "ニュース"},
        { route: "/about", itemEn: "ABOUT", itemJp: "レイヴンについて" },
        { route: "/service", itemEn: "SERVICE", itemJp: "サービス" },
        { route: "/company", itemEn: "COMPANY", itemJp: "会社情報" },
        { route: "/recruit", itemEn: "RECRUIT", itemJp: "採用情報" },
        { route: "/contact", itemEn: "CONTACT", itemJp: "お問い合わせ"}
    ];
    
	/*====== State ======*/
    const [isVisible, setVisibility] = useState(true);
    /*------ 高さでheaderの背景色を変える処理 ------*/
    const [scrollPosition, setScrollPosition] = useState(0);
    const [cssFlg, setCssFlg] = useState(false);
    const location = useLocation();
	/*====== function ======*/
    /*------ アイコン押下時 ------*/
    const updateVisibility = () => {
        setVisibility(!isVisible);
    };
    /*------ windowの高さ取得 ------*/
    useEffect(() => {

        const PositionUp = () => {
            setScrollPosition(window.pageYOffset);
        }

        window.addEventListener("scroll", PositionUp);
        PositionUp();

        return () => window.removeEventListener("scroll", PositionUp);
    }, []);
    /*------ 高さが変わったら背景色が変わる処理 ------*/
    useEffect(() => {
        if (scrollPosition > 110) {
            setCssFlg(true);
        } else {
            setCssFlg(false);
        }
    }, [cssFlg, scrollPosition]);

    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return (
        <>
            <SHeaderInner
                backgroundColor1={props.color === "white" ? "rgb(39,40,47)" : "White"} 
                backgroundColor2="" 
                flg={cssFlg}
            >
            
            <HeaderLogoParts color={color} />
            
            <SnaviResponsive>
                <SUl display="flex">
                    {
                        NaviList.map((NaviItem) => {
                            return (
                                <GlobalNavParts
                                    route={NaviItem.route} 
                                    itemEn={NaviItem.itemEn} 
                                    itemJp={NaviItem.itemJp} 
                                    color={color}
                                    key={NaviItem.itemEn}
                                    location={location}
                                    pathname={location.pathname}
                                />
                                );
                            })
                    }
                </SUl>
            </SnaviResponsive>

            <ShunSpan>
                <Shun 
                    isVisible={isVisible} 
                    onClick={updateVisibility}
                >
                    <IconContext.Provider value={{ color: props.color, size: '2rem'}}>
                        {isVisible ? <FiMenu /> : <RxCross2 />}
                    </IconContext.Provider>
                </Shun>
            </ShunSpan>

            </SHeaderInner>
            {
                // !isVisible &&
                    <SModalNav 
                        // style={{backgroundColor:'red'}}
                        isVisible={isVisible} 
                        backGroundModal={props.color === "white" ? "rgb(39,40,47)" : "White"} 
                    >
                        <SUl style={{ margin: '8vh auto' }}>
                            <Scenter>
                            {
                                NaviList.map((NaviItem) => {
                                    return (
                                        <GlobalNavParts
                                            route={NaviItem.route}
                                            itemEn={NaviItem.itemEn} 
                                            itemJp={NaviItem.itemJp} 
                                            color={color}
                                            key={NaviItem.itemEn}
                                            location={location}
                                            pathname={location.pathname}
                                        />
                                    )
                                })
                            }
                            </Scenter>
                        </SUl>
                    </SModalNav>
            }
        </>
    );
};

