/*====== Define import ======*/
import React from "react";
import styled from "styled-components";

/* ------ styled components ------ */
const Sth = styled.th`
padding: 1rem;
border-bottom: 1px solid #ebecf0;
/* white-space: normal; */
font-size: 1rem;
vertical-align: middle;
text-align: left;
width: 45em;
font-weight:normal;
/* #### 900px以下 ### */
@media (max-width: 900px) {
    padding: 0 10vw 0 1rem;
    font-size: 0.8rem;
    width: 20rem;
}
@media (max-width: 400px) {
    font-size: 0.8rem;
    width: 10rem;
    padding: 0 10vw 0 1rem;
}
`;


const Std = styled.td`
padding: 1vh 0;
border-bottom: 1px solid #ebecf0;
width: 30rem;
line-height: 1.5em;
vertical-align: middle;
font-size: 1rem;
/* #### 900px以下 ### */
@media (max-width: 900px) {
    font-size: 0.8rem;
    width: 20rem;
}
@media (max-width: 400px) {
    font-size: 0.8rem;
    width: 15rem;
}
`;
const Std2 = styled.td`
padding: 3vh 0;
border-bottom: 1px solid #ebecf0;
width: 20rem;
line-height: 1.5em;
vertical-align: middle;
font-size: 1rem;
/* #### 768px以下 ### */
@media (max-width: 900px) {
    font-size: 0.8rem;
    width: 15rem;
}
@media (max-width: 400px) {
    font-size: 0.8rem;
    width: 10rem;
}
`;

const Simg = styled.img`
width: 100%;
object-fit: cover;

`;


// const Image = styled.div`
// width: 18vh;
// @media (max-width: 900px) {
//     width: 10vh;
// }
// @media (max-width: 400px) {
//     width: 6vh;
// }
// /* object-fit: cover; */

// `;
/*====================*/
/*====== Main ======*/
/*====================*/
export const AppravalTable = (props) => {

    /*====== variables ======*/
    const {  td, td1, td2, img } = props;

    return (
        <>
            <tbody>
                <tr>
                    <Sth>
                        {td}
                    </Sth>
                    <Std>
                        {td1}
                    </Std>

                    {
                        img ? (
                            <Std >
                                    <Simg src={td2} alt="許認画像" />
                            </Std>
                        ) : (

                            <Std2>
                                {td2}
                            </Std2>
                        )

                    }

                </tr>
            </tbody>

        </>
    );
};
