/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
import { RecruitTraining21 } from "../../Components/Recruit/RecruitTraining21";
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { PgTitle } from "../../Components/Recruit/pgTitle";
import { PgTitle2 } from "../../Components/Recruit/pgTitle2";
import { GlobalBtn } from "../../Components/Common/GlobalBtn";
import { Answer } from "../../Components/Recruit/InterviewContents";
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { RecruitMiniTitle } from "../../Components/Recruit/RecruitMiniTitle";
import { HeadBlock } from "../../HeadBlock";
/* ====== Image ====== */

import TarningListSvg from "../../Images/RecruitSVG/TopTraining.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon3-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon3-2.png";
import schedule from "../../Images/recruit_Training/suke21.png";
/* ======================================================================== */
/* ============================ Styles ==================================== */
/* ======================================================================== */




/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
const Top = styled.div`
    margin: 60px auto;
    max-width: 1170px;
    width: 90%;
`;

    const All = styled.div`
    text-align  : center;
    `;

    const TitleSize = styled.h1`
    margin-bottom: 40px;
    font-size: 1.5rem;
    `;

    const Heigth = styled.div`
    margin-bottom: 80px;
    `;

    const SImg = styled.img`
    width: 90%;
    `;
/*====================*/
/*======  Main  ======*/
/*====================*/
export const TrainingG21 = () => {
    /*====== Instance ======*/
    /*====== variables ======*/
    /*====== Data ======*/
    /*====== function ======*/
    /*====== Jsx ======*/


    /* ======================================================================== */
    /* ============================ Styles ==================================== */
    /* ======================================================================== */

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/
    return (
        <>
        <HeadBlock title="RECRUIT - 21卒研修 - 株式会社レイヴン" description="21卒研修の研修内容" path="TrainingG21"  />
            <PgTitle
                pgTitle={TarningListSvg}
                pgTitleName={'TopTraining'}
                describe="21卒内定者研修"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <PgTitle2
                pgTitle={TarningListSvg}
                pgTitleName={'TopTraining'}
                describe="21卒内定者研修"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <Header color="black" />
            <Top>
                <All>
                    <RecruitMiniTitle
                        title="ITクイズ"
                    />
                </All>
            </Top>
            <RecruitTraining21 />
            <Top>
                <All>
                    <Heigth />
                    <TitleSize>研修内容</TitleSize>
                </All>
                    <Answer
                        color="#5CC6C0"
                        title="ITクイズ"
                        text="クイズの内容を難易度別に分け、ITについて詳しい人、あまり詳しくない人それぞれが楽しめるようなクイズアプリになっています。また、ITに関してだけではなく、レイヴンに関するクイズも盛り込みました。レイヴンに勤めている人の特徴、働き方、制度について出題しています。少しでもレイヴンのことを知っていただけたらという思いで作成しました。"
                        Btitle="研修で学んだこと"
                        Btext="半年間の内定者研修で、アプリが出来るまでの流れとHTML、CSSのコードの書き方を理解できるようになりました。
                        アプリを1つ作るとなると、様々な工程があります。スケジュールの組み立て、アプリのテーマ決め、デザイン作成、設計など…。
                        研修を始める前までは、アプリ作成、コードの書き方について全くの初心者でしたが、1つ1つ実際に体験していくことで、
                        自分が成長できている実感もありました。また、入社前に実際のプロジェクトの工程を体験することで、
                        入社前と入社後のギャップを少なくすることができました。"
                    />
                    <Heigth />
                <All>
                    <TitleSize>スケジュール</TitleSize>
                    <SImg style={{ padding: '1vw 0' }} src={schedule} alt="pagetitle" />

                    <Heigth />
                    {/* <TitleSize>研修風景</TitleSize>
                    <Iimages
                        images={trpic}
                        Bimages={trpic}
                        Cimages={trpic}
                    />
                    <Heigth /> */}
                    <GlobalBtn
                        btn_text="研修一覧に戻る〉"
                        width="15rem"
                        height="3rem"
                        color="#27282F"
                        hoverColor="#00000"
                        hoverBackgroundColor="#fbe04f"
                        navi="/recruit/traininglist"
                    />
                    <Heigth />
                </All>
            </Top>
            <RecruitFooter
                display="black"
            />
            <Footer />
        </>
    );
}
