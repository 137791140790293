/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React, { useEffect } from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/*------ component ------*/
import { XSlider } from "../Common/XSlider";
/* ------ Library ------ */
import Vivus from "vivus";


/*------ image ------*/
import Image2101 from "../../Images/recruit_Training/train21-1.png";
import Image2102 from "../../Images/recruit_Training/train21-2.png";
import Image2103 from "../../Images/recruit_Training/train21-3.png";
import Image2104 from "../../Images/recruit_Training/train21-4.png";
import Image2105 from "../../Images/recruit_Training/train21-5.png";
import Image2106 from "../../Images/recruit_Training/train21-6.png";
import Image2107 from "../../Images/recruit_Training/train21-7.png";
import Image2108 from "../../Images/recruit_Training/train21-8.png";

/* ---------- Styled Compornents ---------- */

const One = styled.div`
    position: relative;
    margin: 3vh 0;
`;



const SvgB = styled.svg`
    max-width:80rem;/*SVGタグの横幅*/
    width:100%;/*レスポンシブ対応にするため100%を指定*/
    height:auto;
    position: absolute;
    top: 20%;
    left: -20%;
    z-index: 5;
`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */


export const RecruitTraining21 = () => {

    useEffect(() => {
        new Vivus(
            "Svg21",
            {
                duration: 100,
                // file: Svg21,
                type: "delayed",
                forceRender: false,
                animTimingFunction: Vivus.EASE,
            })
    }, []);
    return (
        <>

                    <One>
                    <SvgB id="Svg21" viewBox="0 0 2.5 0.4" />
                        <XSlider
                            Image01={Image2101}
                            Image02={Image2102}
                            Image03={Image2103}
                            Image04={Image2104}
                            Image05={Image2105}
                            Image06={Image2106}
                            Image07={Image2107}
                            Image08={Image2108}
                            width={"45rem"}
                        >
                        </XSlider>
                    </One>
        </>
    );
};