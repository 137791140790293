/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled, {keyframes} from "styled-components";

/* ------ Compornent Js/jsx ------ */
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { QandA } from "../../Components/Recruit/QandA";
import {TMessage} from "../../Components/Recruit/RecruitTopMessage";
import { KarasuAnimeSmall } from "../../Components/Recruit/KarasuAnimeSmall";
import { KarasuAnimeMiddle } from "../../Components/Recruit/KarasuAnimeMiddle";
import { KarasuAnimeBig } from "../../Components/Recruit/KarasuAnimeBig";
import { RecruitCard } from "../../Components/Common/RecruitCard";
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { HeadBlock } from "../../HeadBlock";
/* ------ Icon ------ */
/* ------ Library ------ */
/* ---------- Images ----- ----- */
import RecruitTopMessage from "../../Images/RecruitSVG/RecruitTopMessage.svg"
import Karasu0101 from "../../Images/Recruit_Karasu/crowA.png";
import Karasu0102 from "../../Images/Recruit_Karasu/crowAA.png";
import Karasu0201 from "../../Images/Recruit_Karasu/crowE.png";
import Karasu0202 from "../../Images/Recruit_Karasu/crowEE.png";
import Karasu0301 from "../../Images/Recruit_Karasu/crowF.png";
import Karasu0302 from "../../Images/Recruit_Karasu/crowFF.png";
import Karasu0401 from "../../Images/Recruit_Karasu/RV_karasu_icon21-1.png";
import Karasu0402 from "../../Images/Recruit_Karasu/RV_karasu_icon21-2.png";
import Karasu0501 from "../../Images/Recruit_Karasu/RV_karasu_icon19-1.png";
import Karasu0502 from "../../Images/Recruit_Karasu/RV_karasu_icon19-2.png";
import Karasu0601 from "../../Images/Recruit_Karasu/RV_karasu_icon22-1.png";
import Karasu0602 from "../../Images/Recruit_Karasu/RV_karasu_icon22-2.png";
import Karasu0701 from "../../Images/Recruit_Karasu/RV_karasu_icon17-1.png";
import Karasu0702 from "../../Images/Recruit_Karasu/RV_karasu_icon17-2.png";
import Karasu0801 from "../../Images/Recruit_Karasu/RV_karasu_icon12-1.png";
import Karasu0802 from "../../Images/Recruit_Karasu/RV_karasu_icon12-2.png";
import Karasu0901 from "../../Images/Recruit_Karasu/RV_karasu_icon24-1.png";
import Karasu0902 from "../../Images/Recruit_Karasu/RV_karasu_icon24-2.png";
import Karasu1001 from "../../Images/Recruit_Karasu/RV_karasu_icon6-1.png";
import Karasu1002 from "../../Images/Recruit_Karasu/RV_karasu_icon6-2.png";
import Karasu1101 from "../../Images/Recruit_Karasu/crowC.png";
import Karasu1102 from "../../Images/Recruit_Karasu/crowCC.png";
import Karasu1201 from "../../Images/Recruit_Karasu/crowD.png";
import Karasu1202 from "../../Images/Recruit_Karasu/crowDD.png";
import Karasu1301 from "../../Images/Recruit_Karasu/crowB.png";
import Karasu1302 from "../../Images/Recruit_Karasu/crowBB.png";
import Karasu1401 from "../../Images/Recruit_Karasu/RV_karasu_icon13-1.png";
import Karasu1402 from "../../Images/Recruit_Karasu/RV_karasu_icon13-2.png";
import Karasu1501 from "../../Images/Recruit_Karasu/RV_karasu_icon18-1.png";
import Karasu1502 from "../../Images/Recruit_Karasu/RV_karasu_icon18-2.png";
import Karasu1601 from "../../Images/Recruit_Karasu/RV_karasu_icon4-1.png";
import Karasu1602 from "../../Images/Recruit_Karasu/RV_karasu_icon4-2.png";

/*====== Define styles ======*/
/*------ Styled Components ------*/
const SheroWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #FFEF61;
`;
const SvgS = styled.svg`
    width: 25vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* #### 850px以下 ### */
    @media (max-width: 850px) {
        width: 40vw;
    }
    /* #### 400px以下 ### */
    @media (max-width: 500px) {
        width: 50vw;
    }
`;




// 左上から右下
const tlrKeyframes = keyframes`
    0% {
        transform: translate(-30vw, -50vh);
    }
    20%{
        transform: translate(-30vw, -50vh);
    }
    100% {
        transform: translate(0, 0);
    }
`;

const StopLeftRight = styled.div`
    animation: ${tlrKeyframes} 5s steps(10, jump-none);
`;

// 右上から左下
const trlKeyframes = keyframes`
    0% {
        transform: translate(30vw, -50vh);
    }
    20%{
        transform: translate(30vw, -50v);
    }
    100% {
        transform: translate(0, 0);
    }
`;

const StopRightLeft = styled.div`
    animation: ${trlKeyframes} 5s steps(10, jump-none);
`;

// 左下から右上
const blrKeyframes = keyframes`
    0% {
        transform: translate(-30vw, 30vh);
    }
    20%{
        transform: translate(-30vw, 30vh);
    }
    100% {
        transform: translate(0, 0);
    }
`;

const SbottomLeftRight = styled.div`
    animation: ${blrKeyframes} 5s steps(10, jump-none);
`;

// 右下から左上
const brlKeyframes = keyframes`
    0% {
        transform: translate(30vw, -30vh);
    }
    20%{
        transform: translate(30vw, -30vh);
    }
    100% {
        transform: translate(0, 0);
    }
`;

const SbottomRightLeft = styled.div`
    animation: ${brlKeyframes} 5s steps(10, jump-none);
`;

// 上から下
const tbKeyframes = keyframes`
    0% {
        transform: translate(0, -30vh);
    }
    20%{
        transform: translate(0, -30vh);
    }
    100% {
        transform: translate(0, 0);
    }
`;

const StopBottom = styled.div`
    animation: ${tbKeyframes} 5s steps(10, jump-none);
`;

// 下から上
const btKeyframes = keyframes`
    0% {
        transform: translate(30vh, -50vh);
    }
    20%{
        transform: translate(30vh, -50vh);
    }
    100% {
        transform: translate(0, 0);
    }
`;

const SbottomTop = styled.div`
    animation: ${btKeyframes} 5s steps(10, jump-none);
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const Recruit = () => {


    /*====== Jsx ======*/

    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return(
        <>
            <HeadBlock title="RECRUIT - 株式会社レイヴン" description="レイヴンの新卒採用サイトです。代表メッセージ、新入社員のインタビュー、数字で見る、研修内容、社員の成長とチャレンジ、オフィスの様子などがご覧いただけます。" path="Recruit"  />
            <Header color="black" />

            <SheroWrapper>


                <SvgS viewBox="0 0 384 348">
                    <use xlinkHref={`${RecruitTopMessage}#RecruitTopMessage`}></use>
                </SvgS>

                <StopLeftRight>
                    <KarasuAnimeMiddle
                        top="20vh"
                        left="10%"
                        karasuA={Karasu0101}
                        karasuB={Karasu0102}
                    />
                    <KarasuAnimeSmall
                        top="45vh"
                        left="10%"
                        karasuA={Karasu0201}
                        karasuB={Karasu0202}
                    />
                    <KarasuAnimeMiddle
                        top="37vh"
                        left="25%"
                        resTop="33vh"
                        resLeft="20%"
                        resTop2="29vh"
                        karasuA={Karasu0301}
                        karasuB={Karasu0302}
                    />

                <StopRightLeft>
                    <KarasuAnimeMiddle
                        top="30vh"
                        left="75%"
                        resLeft="80%"
                        karasuA={Karasu0401}
                        karasuB={Karasu0402}
                    />
                    <KarasuAnimeMiddle
                        top="20vh"
                        left="92%"
                        karasuA={Karasu0501}
                        karasuB={Karasu0502}
                    />
                    <KarasuAnimeSmall
                        top="45vh"
                        left="90%"
                        karasuA={Karasu0601}
                        karasuB={Karasu0602}
                    />
                </StopRightLeft>

                <SbottomLeftRight>
                    <KarasuAnimeSmall
                        top="60vh"
                        left="23%"
                        resLeft="20%"
                        resLeft2="10%"
                        karasuA={Karasu0701}
                        karasuB={Karasu0702}
                    />
                    <KarasuAnimeMiddle
                        top="75vh"
                        left="10%"
                        karasuA={Karasu0801}
                        karasuB={Karasu0802}
                    />
                    <KarasuAnimeSmall
                        top="82vh"
                        left="50%"
                        resTop2="85vh"
                        resLeft2="40%"
                        karasuA={Karasu1501}
                        karasuB={Karasu1502}
                    />
                </SbottomLeftRight>

                <SbottomRightLeft>
                    <KarasuAnimeBig
                        top="80vh"
                        left="90%"
                        karasuA={Karasu1601}
                        karasuB={Karasu1602}
                    />
                    <KarasuAnimeSmall
                        top="70vh"
                        left="75%"
                        resLeft="80%"
                        karasuA={Karasu0901}
                        karasuB={Karasu0902}
                    />
                    <KarasuAnimeMiddle
                        top="55vh"
                        left="75%"
                        resLeft="80%"
                        resLeft2="90%"
                        karasuA={Karasu1001}
                        karasuB={Karasu1002}
                    />
                </SbottomRightLeft>

                <StopBottom>
                    <KarasuAnimeSmall
                        top="18vh"
                        left="32%"
                        resTop2="20vh"
                        resLeft2="37%"
                        karasuA={Karasu1101}
                        karasuB={Karasu1102}
                    />
                    <KarasuAnimeSmall
                        top="18vh"
                        left="56%"
                        resTop2="22vh"
                        resLeft2="60%"
                        karasuA={Karasu1201}
                        karasuB={Karasu1202}
                    />
                </StopBottom>

                <SbottomTop>
                    <KarasuAnimeMiddle
                        top="85vh"
                        left="30%"
                        resTop2="73vh"
                        karasuA={Karasu1301}
                        karasuB={Karasu1302}
                    />
                    <KarasuAnimeSmall
                        top="85vh"
                        left="65%"
                        resTop2="78vh"
                        resTop3="10vh"
                        karasuA={Karasu1401}
                        karasuB={Karasu1402}
                    />
                </SbottomTop>
                </StopLeftRight>

            </SheroWrapper>

            <TMessage/>

            <RecruitCard />

            <QandA/>

            <RecruitFooter
                display="black"
            />

            <Footer/>
        </>
    );
}
