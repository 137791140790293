/*====== Define import ======*/
/*------ React ------*/
import React from "react";
/* ==================== Define styles ==================== */
/* ---------- Styled Compornents ---------- */
import styled from "styled-components";
/*------ メインの画像 ------*/
const Wrao = styled.div`
	position: relative;
	width: 1170px;
	margin: 0 auto;
    // レスポンシブ 
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
		width: 100%;
    }
    /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
		width: 100%;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
		width: 100%;
    }
`;

/*------ メインの画像 ------*/
const KeyImg = styled.img`
	width: 100%;
	height: 45rem;
	object-fit: cover;
  /* ### 599px以下#### */
  @media screen and (max-width:599px){
		height: 30rem;
    }
`;
/*------ 手書きのフォント ------*/
const FontImg = styled.img`
    position: absolute;
    top: 300px;
    left: 100px;
    z-index: 10;
    // レスポンシブ 
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
        width: 50%;
        top: 400px;
        left: 20px;
    }
    /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
        left: 20px;
    }

`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */
export const KeyVisual = (props) => {
	const { image, handFont } = props;
	return (
		<>
			<Wrao>
				<FontImg src={handFont} />
			</Wrao>
			<KeyImg src={image} />
		</>
	)
}



