/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { PgTitle } from "../../Components/Recruit/pgTitle";
import { PgTitle2 } from "../../Components/Recruit/pgTitle2";
import { GlobalBtn } from "../../Components/Common/GlobalBtn";
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { HeadBlock } from "../../HeadBlock";
/* ====== Image ====== */
import EntryTop from "../../Images/RecruitSVG/EntryTop.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon8-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon8-2.png";
import mainavi from "../../Images/Recruit_Entry/MYNAVI_SERVICE_2026_YOKO_COLOR_VER2.jpg"



/* ======================================================================== */
/* ============================ Styles ==================================== */
/* ======================================================================== */
const EntryImg = styled.div`
/* margin: 80px 0; */
margin: 0 auto;
text-align: center;
max-width: 1170px;
`;

const TitleSize = styled.h1`
margin-bottom: 30px;
font-size: 1.5rem;
letter-spacing: 0.1em;
`;
const Heigth = styled.div`
margin-bottom: 80px;
`;
const HeigthMin = styled.div`
margin-bottom: 40px;
`;
const SImg = styled.img`
width: 15rem;
margin: 10px 20px;
/* display: flex; */
/* margin: 0 auto; */
border: solid 2px #5CC6C0;
&:hover{
    border: solid 2px #fbe04f;
    }
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const JobDescription = (props) => {
    /*====== Instance ======*/
    /*====== variables ======*/
    /*====== Data ======*/
    /*====== function ======*/
    /*====== Jsx ======*/

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/
    return (
        <>
         <HeadBlock title="RECRUIT - エントリー - 株式会社レイヴン" description="中途採用、新卒採用のエントリーはこちら" path="JobDescription"  />
            <Header color="white" />

            <Header color="black" />

            <PgTitle
                pgTitle={EntryTop}
                pgTitleName={'EntryTop'}
                describe="新卒採用のエントリーは下記の情報サイトから可能です。"
                karasuA={karasuA}
                karasuB={karasuB}
                />
            <PgTitle2
                pgTitle={EntryTop}
                pgTitleName={'EntryTop'}
                describe="新卒採用のエントリーは下記の情報サイトから可能です。"
                karasuA={karasuA}
                karasuB={karasuB}
                />

            <EntryImg>

                <Heigth/>
                <TitleSize>新卒採用</TitleSize>
                <a href="https://job.mynavi.jp/26/pc/search/corp244491/outline.html" target="_blank" rel="noreferrer">
                <SImg style={{padding:'1vw 0'}} src={mainavi} alt="pagetitle" />
                </a>
                <Heigth/>
                <GlobalBtn
                  btn_text="採用情報に戻る〉"
                  width="15rem"
                  height="3rem"
                  color="#27282F"
                  hoverColor="#00000"
                  hoverBackgroundColor="#fbe04f"
                  navi="/recruit"
                  />
                  <HeigthMin/>

                <GlobalBtn
                  btn_text="お困りごとはこちら〉"
                  width="15rem"
                  height="3rem"
                  color="#27282F"
                  hoverColor="#00000"
                  hoverBackgroundColor="#fbe04f"
                  navi="/contact"
                  />
                  <Heigth/>

            </EntryImg>
            <RecruitFooter
                display="none"
            /> 
            <Footer />
        </>
    );
}
