/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from 'react';
/* ---------- CSS ---------- */
import styled from 'styled-components'
import Mtng from "../../Images/Recruit_KeyVisual/KeyVisualMitunaga.jpg";
import Mzgc from "../../Images/Recruit_KeyVisual/KeyVisualMizoguchi.jpg";
import Inb from "../../Images/Recruit_KeyVisual/KeyVisualInaba.jpg";
import Fjok from "../../Images/Recruit_KeyVisual/KeyVisualFujioka.jpg";
import Szk from "../../Images/Recruit_image/appi02.jpg";
import Kdr from "../../Images/HP_ replace_2024/Interview/kodera01.jpg";
import InterviewSvg from "../../Images/RecruitSVG/TopInterview.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon17-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon17-2.png";
/* ---------- compornent ---------- */
import { TileJp } from '../../Components/Common/TileJp';
import { RecruitCard } from '../../Components/Common/RecruitCard';
import { PgTitle } from '../../Components/Recruit/pgTitle';
import { PgTitle2 } from '../../Components/Recruit/pgTitle2';
import { Footer } from '../../Components/Common/Footer';
import { RecruitMiniTitle } from '../../Components/Recruit/RecruitMiniTitle';
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { HeadBlock } from "../../HeadBlock";
import { Header } from '../../Components/Common/Header';

/*====== Define styles ======*/
/*------ Styled Components ------*/
const Swrap = styled.div`
    max-width: 1170px;
    margin: 3% auto 8% auto;
    width: 95%;
`;
const StileWrap1 = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Sspan = styled.span`
    color: #5CC6C0;
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const InterviewList = () => {
    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return( 
        <>
            <Header color="black" />
            <HeadBlock title="RECRUIT - インタビュー - 株式会社レイヴン" description="レイヴン社員のインタビューを紹介するページ。" path="InterviewList"  />
            <PgTitle 
                pgTitle={InterviewSvg}
                pgTitleName={'TopInterview'}
                describe="レイヴン社員の仕事内容やレイヴンに入社して
                感じたことや大変だった事などインタビューを行いました。"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <PgTitle2 
                pgTitle={InterviewSvg}
                pgTitleName={'TopInterview'}
                describe="レイヴン社員の仕事内容やレイヴンに入社して
                感じたことや大変だった事などインタビューを行いました。"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <RecruitMiniTitle
                title="インタビュー一覧"
            />
            <Swrap>
                <StileWrap1>
                    <TileJp 
                        img={Mtng}
                        alt="光永"
                        text="2022年入社"
                        width="32%"
                        paddingTop="30%"
                        title={
                            <>光永 和功 <Sspan>/</Sspan> 開発</>
                        }
                        href="/recruit/mitsunaga"
                    />
                    <TileJp 
                        img={Mzgc}
                        alt="溝口"
                        text="2021年入社"
                        width="32%"
                        paddingTop="30%"
                        title={
                            <>溝口 和哉 <Sspan>/</Sspan> 開発</>
                        }
                        href="/recruit/mizoguchi"
                    />
                    <TileJp 
                        img={Inb}
                        alt="稲葉"
                        text="2018年入社"
                        width="32%"
                        paddingTop="30%"
                        title={
                            <>稲葉 真巳 <Sspan>/</Sspan> 開発・PM/PMO</>
                        }
                        href="/recruit/inaba"
                    />
                    <TileJp 
                        img={Fjok}
                        alt="藤岡"
                        text="2021年入社"
                        width="32%"
                        paddingTop="30%"
                        title={
                            <>藤岡 莉紗 <Sspan>/</Sspan> 開発</>
                        }
                        href="/recruit/fujioka"
                    />
                    <TileJp 
                        img={Szk}
                        alt="鈴木"
                        text="2022年入社"
                        width="32%"
                        paddingTop="30%"
                        title={
                            <>鈴木 亜衣梨 <Sspan>/</Sspan> PM/PMO</>
                        }
                        href="/recruit/suzuki"
                    />
                    <TileJp 
                        img={Kdr}
                        alt="小寺"
                        text="2022年入社"
                        width="32%"
                        paddingTop="30%"
                        title={
                            <>小寺 みき <Sspan>/</Sspan> 開発</>
                        }
                        href="/recruit/kodera"
                    />     
                </StileWrap1>
            </Swrap>

            <RecruitCard />
            
            <RecruitFooter 
                display="block"
            />
            
            <Footer />
        </>
    );
};