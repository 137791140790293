/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Style ---------- */
import styled from "styled-components";
/* ---------- components ---------- */
import { HeadBlock } from "../../HeadBlock";
import { KeyVisual } from "../../Components/Recruit/InterviewKeyVisual"
import { NamePlate } from "../../Components/Recruit/InterviewNamePlate"
import { Answer } from "../../Components/Recruit/InterviewContents"
import { Iimages } from "../../Components/Recruit/InterviewPhoto"
import { Header } from "../../Components/Common/Header"
import { Footer } from "../../Components/Common/Footer"
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { InterviewTransionList } from "../../Components/Recruit/InterviewTransionList";

/* ---------- Images ---------- */

/*----------キービジュアル----------*/
import KeyVisualImg from "../../Images/Recruit_KeyVisual/KeyVisualMitunaga.jpg"
/*----------３つの連なる画像----------*/
import Timages1 from "../../Images/Recruit_image/Mitsu_image1.jpg"
import Timages2 from "../../Images/Recruit_image/Mitsu_image2.jpg"
import Timages3 from "../../Images/Recruit_image/Mitsu_image3.jpg"

const Height = styled.div`
height: 8rem;
@media (max-width: 599px) {
height: 2rem;
}
`;
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Mtng = (props) => {
  return (
    <>
      <HeadBlock title="	RECRUIT - 光永 和功 - 株式会社レイヴン" description="インタビュー光永" path="Mtng" />

      <Header color="white" />

      <KeyVisual
        image={KeyVisualImg}
      />

      <NamePlate
        EngName="Kazunari Mitsunaga"
        JpnName="光永 和功"
        year="2022年入社"
        division="開発事業部"
      />

      <Height />
      <Answer
        color="#FFEF61"
        title="入社した理由"
        text="就職活動では、ITと人材育成の2軸に絞って取り組んでいました。人材育成に興味を持った理由は、大学で人的資源を専攻していたため、人材育成に関連する仕事をしたいと考えていたからです。IT企業では「プログラマーとして入社するため、人事系は数十年後」と言われ、人材系企業では「両方をすることは出来ない」と言われました。ITと人事系の両方できる企業はないと諦めかけていたところ、レイヴンの代表に出会いました。代表からは「両方やっていいよ。むしろ、両方やってより付加価値の高い人物になって欲しい」と言われ、両方経験したかった私は、レイヴンに入社する事を決めました。実際に入社して1年目から新卒採用のリーダーを担当しながら、開発の実務では要件定義からテストまでの工程に携わる事が出来ました。夢は、ITと人材育成を組み合わせてビジネスマンを教育する機関を作る事です。自分の好きなITと人材育成で培った経験を生かして、より多くの人の人生を豊かにしていきたいと考えています。"

        Btitle="今どういう仕事をしているか"
        Btext="従業員情報を管理するシステムを作成しています。部署、雇用形態、役職などの労務情報を管理するシステムを作成し、上流から下流まで一連の工程に携わっています。今回の開発で初めてバックエンドの実装に挑戦しています。バッグエンドは、莫大なデータから効率的に取り出したいデータに絞るなどの考え方が必要となります。この考え方は、これまでに経験したフロントエンドとは異なるため、試行錯誤しながらも新しい技術を身につけることが楽しいです。"
      />

      <Iimages
        images={Timages1}
        Bimages={Timages2}
        Cimages={Timages3}
      />
      <InterviewTransionList />
      <RecruitFooter
        display="block"
      />
      <Footer />
    </>
  )
};