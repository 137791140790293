/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React, { useEffect } from "react";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/*------ component ------*/
import { XSlider } from "../Common/XSlider";
/* ------ Library ------ */
import Vivus from "vivus";


/*------ image ------*/
import Image2301 from "../../Images/HP_ replace_2024/kenshu/train23-1.jpg";
import Image2302 from "../../Images/HP_ replace_2024/kenshu/train23-2.jpg";
import Image2303 from "../../Images/HP_ replace_2024/kenshu/train23-3.jpg";
import Image2304 from "../../Images/HP_ replace_2024/kenshu/train23-4.png";
import Image2305 from "../../Images/HP_ replace_2024/kenshu/train23-5.png";
import Image2306 from "../../Images/HP_ replace_2024/kenshu/train23-6.png";
import Image2307 from "../../Images/HP_ replace_2024/kenshu/train23-7.png";
import Image2308 from "../../Images/HP_ replace_2024/kenshu/train23-8.jpg";

/* ---------- Styled Compornents ---------- */
const Two = styled.div`
    position: relative;
`;


const SvgA = styled.svg`
    max-width:80rem;/*SVGタグの横幅*/
    width:100%;/*レスポンシブ対応にするため100%を指定*/
    height:auto;
    position: absolute;
    top: 10%;
    left: -20%;
    z-index: 5;
`;


/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */


export const RecruitTraining23 = () => {
    useEffect(() => {
        new Vivus(
            "Svg23",
            {
                duration: 100,
                // file: Svg22,
                type: "delayed",
                forceRender: false,
                animTimingFunction: Vivus.EASE,
            })
    }, []);

    return (
        <>
            <Two>
                <SvgA id="Svg23" viewBox="0 0 2 0.4" />
                <XSlider
                    Image01={Image2301}
                    Image02={Image2302}
                    Image03={Image2303}
                    Image04={Image2304}
                    Image05={Image2305}
                    Image06={Image2306}
                    Image07={Image2307}
                    Image08={Image2308}
                    width={"35rem"}
                >
                </XSlider>
            </Two>

        </>
    );
};