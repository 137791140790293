
/* ==================== Define import ==================== */
/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
import { Answer } from "./InterviewContents";
import image01 from "../../Images/recruit_work/Group 229.png"
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const All = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  opacity:1;
  animation-name:sample01;
  animation-duration:2s; 
  animation-iteration-count:1;
  @keyframes sample01 {
    0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
  }
  40%,100% {
    opacity: 1;
  }}
  @media (max-width: 768px) {
    width: 80%;
    }
`;
const Sbox = styled.div`
`;
const Sbox2 = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 5% auto 2% auto;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      margin: 20px 0;
    }
`;
const SimgBox = styled.div`
    text-align: center;
    margin: 0 30px 0 0;
    width: 150px;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Simg = styled.img`
  width: 100%;
`;
const Stext = styled.p`
    margin: 5% 0 0 0;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      font-size: 0.8rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Stitle = styled.p`
    /* margin: 0 0 0 5%; */
    font-weight: bold;
    font-size: 1.4rem;
`;
const Test = styled.span`
    font-size: 2rem;
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const WorkTest = (props) => {
  return (
    <>
        <All>
            <Sbox>
                <Sbox2>
                    <SimgBox>
                        <Simg src={image01} alt="テスト担当"/>
                        <Stext>2023年入社</Stext>
                    </SimgBox>
                    <Stitle>入社直後の<Test>テスト</Test></Stitle>
                </Sbox2>
            </Sbox>
            <Answer 
                color="#FFEF61"
                title="いつテストを経験？"
                text="私がテストを経験したのは、入社2日目でした。テストといっても複数の工程があり、結合テストというテストを行いました。用意されていたテスト仕様書という、テスト項目が書かれている資料を使って、実際にシステムをPC上で動かし、正常に作動しているかどうかをテストしました。テスト工程を行なって2週間ほどでテストのリーダーを務めました。経緯としては、元から山田代表にリーダーをやりたいですと話していたので、やらせていただきました。テストリーダーでは、主にテストが計画通りに消化できているかを把握し、納期に間に合わせるようにテストチームをリードし、タスクを割り当てます。他にも、テストチームのメンバーとコミュニケーションをとりながら、バグの抽出状況や網羅できていないテストケースを確認します。その結果を踏まえ全体に進捗を報告しました。"
                Btitle="テストリーダーとしての成長"
                Btext="テストで大変だったことは、立てた計画通りにテストを消化することが大変でした。大学では、デザイン系を専攻していたので、全くITやテストに関する知識がありませんでした。テストが始まった時も、これが結合テストというテストだと理解せずに、とりあえずテスト仕様書に従って、先輩に質問をしまくりました。テストに慣れてきて、テストリーダーに任命されてからは、自分のタスクを消化しながらテストチームのタスクも割り振り、進捗管理を行いました。ですが、日々計画より遅れたり、他の案件で時間を取られたりして１日ってこんなに短いんだと感じました。なので、積極的に知識をいれました。先輩社員にテストとは何かから質問し、山田代表には進捗管理の極意を教えていただきました。他にもネットや書籍で、結合テスト、単体テスト、統合テストは何のためにあるのかなどの知識をいれ、今自分が行なっていることは何を確認するためのテストなのかを考えて行いました。その結果、テストに対して理解が深まり、テストの全体像が見えてきました。計画通りにテストは消化できませんでしたが、わからないことはわかるまで調べることが大切です。"
            />
        </All>
    </>
  )
}