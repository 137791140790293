//=============================================================================
// Contents   : HPRP
//              数字で見る
// Author     : RV神野
// LastUpdate : 2023/6/6
// Since      : 2023/6/6
//=============================================================================

/* ======================================================================================================================================================= */
/* ==================================================================== Start Of Program ================================================================= */
/* ======================================================================================================================================================= */


/* ======================================================================== */
/* ============================ import ==================================== */
/* ======================================================================== */
/* ====== React ====== */
import React from "react";

/* ====== CSS ====== */
/* ====== Image ====== */
import NumberSvg from "../../Images/RecruitSVG/TopNumber.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon1-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon1-2.png";
/* ====== Component Js/Jsx ====== */
import { NumberPart } from "../../Components/Recruit/NumberPart";
import { RecruitCard } from "../../Components/Common/RecruitCard";
import { Header } from "../../Components/Common/Header";
import { PgTitle } from "../../Components/Recruit/pgTitle"
import { PgTitle2 } from "../../Components/Recruit/pgTitle2";
import { Footer } from "../../Components/Common/Footer";
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { HeadBlock } from "../../HeadBlock";
/* ====== Style ====== */
/* ======================================================================== */
/* ============================ Styles ==================================== */
/* ======================================================================== */

/* ======================================================================== */
/* ================== global variables/functions ========================== */
/* ======================================================================== */

/* ======================================================================== */
/* ============================ Main ====================================== */
/* ======================================================================== */

/* ========= 一覧テンプレート ========= */
export const Number = (props) => {
    /* ---------------------------------------------------- */
    /* ------------------ Instance ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ------------------ variables ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ---------------------- state ----------------------- */
    /* ---------------------------------------------------- */

    /* ----------------------------------------------------- */
    /* ----------------- reack-hook-form ------------------- */
    /* ----------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ---------------------- function -------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- data ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- jsx ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* -------------------- Start Render ------------------ */
    /* ---------------------------------------------------- */

    return (
        <>
            <HeadBlock title="RECRUIT - 数字で見る - 株式会社レイヴン" description="レイヴンについての情報を数字で表したページ。" path="Number"  />
            <Header color="black" />
            <PgTitle
                pgTitle={NumberSvg}
                pgTitleName={'TopNumber'}
                describe="レイヴンで実際に働いている社員の実態を数字で表しました。"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <PgTitle2
                pgTitle={NumberSvg}
                pgTitleName={'TopNumber'}
                describe="レイヴンで実際に働いている社員の実態を数字で表しました。"
                karasuA={karasuA}
                karasuB={karasuB}
            />
                <NumberPart></NumberPart>
                <RecruitCard />
                <RecruitFooter
                    display="black"
                />
                <Footer />

        </>
    );
};

/* ======================================================================================================================================================= */
/* ==================================================================== End Of Program =================================================================== */
/* ======================================================================================================================================================= */


