/* ==================== Define import ==================== */

/* ---------- React ---------- */
import React from "react";
import { AboutTile } from "../../Components/about/aboutTile4";
import { SectionTitleAnime } from "../../Components/Common/SectionTitleAnime";
/* ==================== Define styles ==================== */
import styled from "styled-components";
import ceoImg from "../../Images/ceoImg.jpg";
/* ---------- Styled Compornents ---------- */
const About = styled.div`
  width: 100%;
  margin-top: 240px;
  @media (max-width: 768px) {
    margin-top: 120px;
  }
`;
const All = styled.div`
  margin: 60px auto 0px auto;
  max-width: 1250px;
  justify-content: center;
  opacity: 1;
`;
const Sdiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;
const Sdiv2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;
const AImage = styled.img`
  width: 100%;
  margin: 60px 0;
  @media (max-width: 768px) {
    margin: 20px 0 60px 0;
  }
  @media (max-width: 440px) {
  }
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/

export const CeoMessage = (props) => {
  const CompanyImage = [
    {
      key: "1",
      title: "これからの会社像",
      text1:
        "まだまだ、社員全員で企業理念を統一できていない部分があるので、しっかりと意識を統一させ、全員が経営者の目線でレイヴンについて考えられるようにしたいです。",
      text2:
        "そのため、積極的にやりたいと声を上げた社員には何事もとことんやらせる文化を作り上げています。",
      text3: "社員全員が先見の明を持ち、自発的に行動する。",
      text4:
        "常に自分自身をアップデートし、世界に通用するビジネスマンを目標に成長し続け、常識や一般論を打ち壊す。",
      text5: "そんな人たちを育て、最強な会社にしたいです。",
      width: "calc(50% - 10px)",
      height: "auto",
    },
  ];
  const President = [
    {
      key: "2",
      title: "社長をやる上で考えること",
      text1:
        "社長をやるということは社員一人一人の人生を預かることと考えています。人がいるから会社が大きくなり、人が育つから仕事が面白くなる。そして、社員あっての会社なので社員をゆたかにすることはもちろん、その社員を支えてくれている家族のことまでゆたかにすることを意識しています。",
      text2:
        "また、今は少人数ですが、私の考えや企業理念に賛同してくれる同志を増やし、100年以上成長し続ける会社を作りたいと思っています。それを社長である自分だけでなく、社員全員で企業理念の意識を統一させることが100年以上続く企業にする第一歩だと考えています。",
      width: "calc(50% - 10px)",
      height: "auto",
    },
  ];
  const Businessman = [
    {
      key: "3",
      title: "レイヴン流「ビジネスマン」",
      text1:
        "企業は人を成長させながら発展していかなければいけないと考えています。なので、レイヴンは人を育てることに全力を注いでいます。ただプログラマーやエンジニアとしての技術を育てたいのではありません。自分自身の力で社会を生き抜き、日本だけではなく世界に通用する「ビジネスマン」を育てたいのです。",
      text2:
        "ビジネスマンとは、人・モノ・金・情報を自在に扱うことができ、現場と経営をよく理解している、その人がいればプロジェクトの完遂はもちろん、ビジネスを生み出すことができる人です。",
      text3:
        "世界に通用するビジネスマンに近づくためには、最前線の現場で多くのビジネスに関わりながらITスキルを磨き続けること、日本のみならず世界のスケールで経済状況を意識しながら経営スキルを身につけることが重要です。これら二つをやり続け、自分で実現したいビジネスを持ち、どんな困難をも乗り越え、実現する。それがレイヴン流のビジネスマンです。",
      width: "100%",
      height: "auto",
    },
  ];
  const CompanyRaven = [
    {
      key: "4",
      title: "株式会社レイヴン",
      title2: "代表取締役 山田 浩一郎",
      text1:
        "1970年11月29日群馬県生まれ。情報系専門学校卒業後、富士ファコムシステム株式会社に入社。2004年伊藤忠テクノソリューションズ株式会社に入社し、課長職を経験。2011年より個人事業主として独立。2014年に株式会社レイヴンを設立し、代表取締役に就任。",
      width: "100%",
      height: "auto",
    },
  ];

  return (
    <>
      <About>
        <SectionTitleAnime
          TitleName="REPRESENTATIVE  MESSAGE"
          fontSize="32px"
          fontFamily="'Roboto Condensed', sans-serif;"
          fontColor="black"
          backGroundcolor="black"
        />
        <br />
        <SectionTitleAnime
          TitleName="代表メッセージ"
          fontSize="20px"
          fontFamily=" 'Noto Sans JP', sans-serif"
          fontColor="black"
          backGroundcolor="black"
        />

        <All>
          <Sdiv>
            {CompanyImage.map((item, index) => (
              <AboutTile
                title={item.title}
                text1={item.text1}
                text2={item.text2}
                text3={item.text3}
                text4={item.text4}
                text5={item.text5}
                width={item.width}
                height={item.height}
                key={item.key}
              />
            ))}
            {President.map((item) => (
              <AboutTile
                title={item.title}
                text1={item.text1}
                text2={item.text2}
                width={item.width}
                height={item.height}
                key={item.key}
              />
            ))}
          </Sdiv>
          <AImage src={ceoImg} alt="ニュース写真" />
          <Sdiv2>
            {Businessman.map((item) => (
              <AboutTile
                title={item.title}
                text1={item.text1}
                text2={item.text2}
                text3={item.text3}
                width={item.width}
                height={item.height}
                key={item.key}
              />
            ))}
            {CompanyRaven.map((item) => (
              <AboutTile
                title3={item.title}
                title4={item.title2}
                width={item.width}
                height={item.height}
                key={item.key}
              />
            ))}
          </Sdiv2>
        </All>
      </About>
    </>
  );
};
