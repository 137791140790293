/*====== Define import ======*/
import React from "react";
import styled from "styled-components";
/* ---------- components ---------- */
import { Header } from "../../Components/Common/Header";
import { SubVisual } from "../../Components/Common/SubVisual";
import { ServiceInterviewVisual } from "../../Components/Service/ServiceInterviewVisual";
import { ServiceInterviewSentence } from "../../Components/Service/ServiceInterviewSentence";
import { ServiceInterviewBtnS } from "../../Components/Service/ServiceInterviewBtnS";
import { Footer } from "../../Components/Common/Footer";
import { ServiceInterviewBusiness } from "../../Components/Service/ServiceInterview Business";
/* ---------- Images ---------- */
import Img from "../../Images/ogawa.develop.jpg";
import Img01 from '../../Images/HP_ replace_2024/Interview/Mizoguti02.jpg';
import Img04 from '../../Images/HP_ replace_2024/Interview/Mizoguti01.jpg';
import personImage from '../../Images/HP_ replace_2024/Interview/Mizoguchi_Kazuya.png';

// import schedule from "../../Images/システム開発事業.svg";
import serviceHeaderImg from '../../Images/syskey.jpeg';
import { InterviewTitle } from "../../Components/Service/InterviewTitle";
// import Zu from "../../Images/pmpmoKARI.png"

import sys1 from '../../Images/sys1 (2).svg';
// import sys1 from '../../Images/sys1 (2).svg';
import sys2 from '../../Images/sys2.svg';
import sys3 from '../../Images/sys3.svg';

/*------ Styled Components ------*/
const ScolorWrapper = styled.div`
/* background-color: rgb(250,250,250); */
background-color:white;
margin-top: 15%;
padding-bottom: 5%;
`;


const SinterviewWrapper = styled.div`
margin: 0 auto;
max-width: 1170px;
width: 90%;
position: relative;
@media (min-width: 600px) and (max-width: 768px) {
}
@media (min-width: 350px) and (max-width: 599px) {
}
`;

const Center = styled.div`
    margin: 80px auto;
    text-align: center;
    width: 80%;
`
const All = styled.div`
    max-width: 1170px;
    width: auto;
    margin: 0 auto;
`;
const Bbox = styled.div``
const Snummber = styled.h2`
    position: relative;
    top:-20px;
    text-align: center;
    z-index: 100;
    display: block;
    padding: 2rem 0 2rem 0;
    font-size: 1.4rem;
`
const Info = styled.h2`
    font-size: 1.4rem;
`;

const Svg = styled.svg`
    width: 100%;
    max-width: 1170px;
    /* #### 850px以下 ### */
    @media (max-width: 850px) {
        width: 100%;
    }
    /* #### 400px以下 ### */
    @media (max-width: 500px) {
        width: 100%;
    }
    /* margin-left: 3rem; */
    /* height: -webkit-fill-available;
    width: -webkit-fill-available;
    display: flex; */
    /* width: 80%; */
    /* margin: -10rem auto; */
`;
const Sborder = styled.div`
    width: 180px;
    height: 20px;
    margin: -60px auto 0 auto;
    background-color: #FBE04F;
    opacity: 0.5;
`;

const Set = styled.div`
    margin-top: 2rem;
`;
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */
export const System = () => {
    // 改行
    const msg1 = `適正なQ(品質)・C(コスト)・D(納期)でシステムを開発\n提案型スタイルでお客様に貢献`
    const text = msg1.split(/(\n)/).map((item, index) => {
        return (
            <div key={index}>
                {item.match(/\n/) ? <br /> : item}
            </div>
        )
    });

    /*------ 本文の部品化 ------*/
    const sentenceList = [
        { sentenceTitle: "お仕事内容について教えてください。", sentence: "基本的には、お客様から依頼されたシステムを作ります。 まずお客様から「何となくこういうモノを作りたい」という要望があり、 それを具体的にシステム化するとどうなるのか、お客様が持っている曖昧な要求要望に対して深掘りをすることで明確にしていきます。つぎに本当にお客様が作りたいものが何かをヒアリングしながら徐々に仕様を固めていきます。そして固めた仕様をプログラミングしテストをして納品するというのが一連の流れです。", type: "true", img: Img01, alt: "小川さん" },
        { sentenceTitle: "どういうところにやりがいを感じますか？", sentence: "IT業界は新しい技術や知識が更新され続けるため、現状に満足せずに勉強し続けないと良いシステムは作れません。だからこそ、絶えず続けてきた努力がお客様からの評価や便利なシステムとして、目に見える形で返ってきた時に、強い達成感や自分の努力が認められた喜びを人一倍得られることにやりがいを感じます。 " },
        { sentenceTitle: "システム開発事業で大変だったことは何ですか？", sentence: "新しい技術や知識を勉強し続けることはもちろん大変ですが、そうやって得た技術や知識を最大限発揮することがより大変です。自分の技術や知識を最大限発揮するためには、システムを利用する人や業界についての知識を持ち、利用者の視点に立つ必要があります。IT業界というと人との関わりが少ないようなイメージを持たれがちですが、利用者がどのような要望を持ち、どのようなシステムであれば便利だと感じるのか、お客様の気持ちを考え尽くすこともシステム開発の難しさであり醍醐味だと思います。 " },
        { sentenceTitle: "どのような人がシステム開発事業に向いていますか？", sentence: "一番はものづくりが好きなことです。その上で新しい技術や知識が更新され続けるというIT業界の特徴を考慮すると、探究心や向上心を持った人も向いていると思います。一流と呼ばれ、周りから認められるような人たちは、常に探究心や向上心を持ち未知の挑戦を楽しんでいるイメージがあります。 ほかにも、人とのコミュニケーションが好きな人も向いていると思います。幅広い業界のお客様を理解する必要がある業務なので、自分から積極的にコミュニケーションを取りお客様の気持ちを考えて行動できる人は、お客様から求められる人材になれると思います。 ", type: "true", img: Img04, alt: "森田さん" },
    ]

    return (
        <>
            <Header color="white" />
            <SubVisual
                Image={serviceHeaderImg}
                sub_titleEn="SERVICE"
                subTitleJa="サービス"
                //サービス
            />

            <ServiceInterviewVisual
                Img={Img}
                sctsubttl="システム開発事業"
                content={text}
                content2="開発工程における上流(要件定義)からリリース・保守まで、まるっと対応出来ます。フロントエンドやバックエンド、クラウドを使用したインフラも得意です。"
            />
            <All>
                <Center>
                        <Bbox>
                            <ServiceInterviewBusiness
                                nummber='1'
                                text='お客様の「こんなサービスやシステムを作りたい」を解決'
                                p='漠然としたアイデア段階の企画やお困り事に対し、QCDに合った工程や技術を提案します。'
                            // pp='QCDに合った工程や技術を提案します。'
                            // img={Zu}
                            />
                            <Svg viewBox="0 0 933 1390"><use xlinkHref={`${sys1}#sys1`}></use></Svg>
                            <Set>
                                <Info>適正な進め方を提案し、</Info>
                                <Snummber>成功に導きます</Snummber>
                            </Set>
                            <Sborder></Sborder>
                        </Bbox>
                        <Bbox>
                            <ServiceInterviewBusiness
                                nummber='2'
                                text='ご要望に合わせた柔軟な対応'
                                p='既存プロジェクトにおける技術的な問題の解決や、開発標準の策定、技術検証、設計工程や実装工程、デザインのみの作成など、ご要望に合わせて柔軟に対応致します。また、お客様の体制やご要望に最適な開発プロセス（ウォーターフォール、アジャイル）、プラットフォーム、必要なドキュメントをご提案します。'
                            // pp='特に定例会議はPDCAサイクルを回すことでより有意義に'
                            // img={Zu}
                            />
                            <Svg viewBox="0 0 900 476"><use xlinkHref={`${sys2}#sys2`}></use></Svg>
                            <Set>
                                <Info>要望に合わせて工程ごとに</Info>
                                <Snummber>対応可能です</Snummber>
                            </Set>
                            <Sborder></Sborder>
                        </Bbox>
                        <Bbox>

                            <ServiceInterviewBusiness
                                nummber='3'
                                text='システム開発実績'
                                p='高い品質要件の大規模なウォーターフォールでの業務システム開発や展示会向けの短納期のモックアプリ開発など、様々な開発実績があります。記載は一例となりますので、記載にないようなシステム開発もお気軽にご相談ください。'
                            // pp='現場に合わせたツールの最適活用が可能'
                            // img={Zu}
                            />
                            <Svg viewBox="0 0 950 899"><use xlinkHref={`${sys3}#sys3`}></use></Svg>
                        </Bbox>
                </Center>
                <ScolorWrapper>
                    <SinterviewWrapper>
                        <InterviewTitle
                            title="INTERVIEW"
                            inputimg={personImage}
                            name="溝口 和哉 / Kazuya Mizoguchi"
                            workhistory="2021年入社 開発事業部"

                        />
                        {
                            sentenceList.map((sentenceItem, index) => {
                                return (
                                    <ServiceInterviewSentence
                                        sentenceTitle={sentenceItem.sentenceTitle}
                                        sentence={sentenceItem.sentence}
                                        type={sentenceItem.type}
                                        img={sentenceItem.img}
                                        alt={sentenceItem.alt}
                                        key={index}
                                    />
                                )
                            })
                        }
                        {/* <SsheduleWrapper>
                        <SsheduleTitle>ONE DAY<br />SCHEDULE</SsheduleTitle>
                        <SsheduleImg src={schedule} />
                    </SsheduleWrapper> */}

                        <ServiceInterviewBtnS />
                    </SinterviewWrapper>
                </ScolorWrapper>
            </All>
            <Footer color="white" />
        </>
    );
}

