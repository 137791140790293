/*====== Define import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled, {keyframes} from "styled-components";
/* ------ Compornent Js/jsx ------ */
import { TopHeroXslider } from "../../Components/Top/TopHeroXslider";
import { SectionTitleAnime } from "../../Components/Common/SectionTitleAnime";
/* ------ Images ------ */
import ximg1 from "../../Images/24816644_l.jpg";
import ximg2 from "../../Images/3U5A4711_1.jpg";
import ximg3 from "../../Images/3U5A4001.JPG";
import ximg4 from "../../Images/3U5A4724.jpg";

import headerImage from "../../Images/logo/headerImage.svg";
/* ------ Icon ------ */
import { HiArrowLongDown } from "react-icons/hi2";
import { IconContext } from "react-icons";


/*====== Define styles ======*/
/*------ Styled Components ------*/
const SheroWrapper = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
`;

// -----------------------------------------------------------------------------
const WhiteKeyframes = keyframes`
    0% {
        opacity: 1;
    }
    75%{
        opacity: 1;
    }
    85%{
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;
const SsectionAnimationlayer = styled.div`
    background-color: white;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 2;
    opacity: 0;
    animation: ${WhiteKeyframes} 4s ease;
`;

// ----------------------------------------------------------------------------------
const SentenceKeyframes = keyframes`
    0% {
        opacity: 1;
    }
    75%{
        opacity: 1;
    }
    85%{
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;
const SsectionAnimationInner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    opacity: 0;
    transform: translate(-50%,-50%);
    white-space: nowrap;
    animation: ${SentenceKeyframes} 4s ease;
`;

// ----------------------------------------------------------------------------------
const BlackKeyframes = keyframes`
    0% {
        transform: translateY(100vh);
        opacity: 0;
    }
    60%{
        transform: translateY(100vh);
        opacity: 1;
    }
    75%{
        transform: translateY(0);
        opacity: 1;
    }
    85%{
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(100vh);
        opacity: 1;
    }
`;

const SfedeInlayer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgb(39,40,47);
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 4;
    animation: ${BlackKeyframes} 4s ease;
`;

// ----------------------------------------------------------------------------------
const OpenKeyframes = keyframes`
    0% {
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    75%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`;
const SravenLogolayer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 5;
    animation: ${OpenKeyframes} 4s ease;
`;

const Ssvg = styled.svg`
    height: 3rem;
`;

// ----------------------------------------------------------------------------------
const ArrowKeyframes = keyframes`
    0% {
        transform: translate(-50%,-50%);
    }
    50%{
        transform: translate(-50%,-200%);
    }
    100% {
        transform: translate(-50%,-50%);
    }
`;

const Siconlayer = styled.div`
    position: absolute;
    top: 80%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%,-50%);
    animation: ${ArrowKeyframes} 2s ease infinite;
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const TopHero = (props) => {

    /*====== Instance ======*/
    /*====== variables ======*/
    /*====== State ======*/
    /*====== Data ======*/
    /*====== function ======*/

    /*====== Jsx ======*/

    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return(
        <>
            <SheroWrapper>  
                <TopHeroXslider
                    Image01={ximg1}
                    Image02={ximg2}
                    Image03={ximg3}
                    Image04={ximg4}
                    Image05={ximg1}
                    Image06={ximg2}
                    Image07={ximg3}
                    Image08={ximg4}
                />
                <Siconlayer>
                    <IconContext.Provider value={{ size: '3rem', color: 'white'}}>
                        <HiArrowLongDown />
                    </IconContext.Provider>
                </Siconlayer>
                <SsectionAnimationlayer></SsectionAnimationlayer>
                <SsectionAnimationInner>
                    <SectionTitleAnime
                        TitleName="進むべき道を指し示す"
                        fontSize="1.8rem"
                        fontFamily="'Noto Sans JP', sans-serif"
                        fontColor="black"
                        backGroundcolor="yellow"
                    />
                </SsectionAnimationInner>
                <SfedeInlayer></SfedeInlayer>
                <SravenLogolayer>
                    <Ssvg color="white">
                        <use xlinkHref={`${headerImage}#headerImage`}></use>
                    </Ssvg>
                </SravenLogolayer>
            </SheroWrapper>
        </>
    );
};