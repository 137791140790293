/*====== Define import ======*/
import React from "react";
import styled from "styled-components";
import fuki2 from '../../Images/fuki2.svg'

const SdivContens = styled.div`
display:flex;

`;

//インタビューと人の写真
const Titlediv = styled.div`
margin-left: 13vw;
/* ### 1170px以下#### */
    @media (max-width:399px){
        margin-left: 30px;
    }
`;

//インタビュータイトル
const Sinterview = styled.div`
display: flex;
letter-spacing: 0.1rem;
font-size: 3rem;
margin-bottom: 30px;
color: #1C1C1C;
font-weight: bold;
font-family: 'Roboto Condensed', sans-serif;

   // レスポンシブ 
    /* ### 1170px以下#### */
    @media (min-width: 500px) and (max-width:740px){
        font-size: 2rem;
    }
    @media (min-width: 400px) and (max-width:499px){
        font-size: 1.5rem;
    }
    @media (max-width:399px){
        font-size: 1.5rem;
    }
`;

const Simg = styled.img`
width: 100%;
@media screen and (max-width:1170px){
    width: 90%;
    }
`;

//吹き出し画像と名前
const Sdiv = styled.div`
`;

//SVG
const Fukidasi = styled.svg`
margin-left: 10vw;
@media (max-width: 1169px){
    margin-left: 0;
    height: 40%;
    width: 35vw;
    }
`;

const Namediv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5vw;
`;

const Sname = styled.h2`
font-size: 1.3rem;
margin-bottom: 15px;
color: #1C1C1C;
 
    @media (min-width: 431px) and (max-width: 670px){
        font-size: 1rem;
    }
    @media screen and (max-width:430px){
        font-size: 1rem;
    }
`;
const Detail = styled.h2`
font-size: 0.9rem;
color: #1C1C1C;
   // レスポンシブ 
    /* ### 1170px以下#### */
    @media (min-width: 300px) and (max-width: 670px){
        font-size: 0.6rem;
        
    }
    @media screen and (min-width:410px){
        font-size: 0.7rem;
    }
`;


/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */
export const InterviewTitle = (props) => {

    const {title, name, workhistory, inputimg}  = props;

    return(
        <>
            <SdivContens>
                <Titlediv>
                        <Sinterview>{title}</Sinterview>
                        <Simg src={inputimg} alt="小川さん"/>
                </Titlediv>
                <Sdiv>
                    <Fukidasi>
                        <use xlinkHref={`${fuki2}#fuki2`}></use>
                    </Fukidasi>
                    <Namediv>
                        <div>
                            <Sname>{name}</Sname>
                            <Detail>{workhistory}</Detail>
                        </div>               
                    </Namediv>  
                </Sdiv>
            </SdivContens>
        </>
    )
}