/*====== Define import ======*/
/*------ React ------*/
import React from "react";
/*------ CSS ------*/
import styled, {keyframes} from "styled-components";

/*====== Define styles ======*/
/*------ Styled Components ------*/
/*------ 画像が流れる処理 ------*/
/*------ キーフレーム ------*/
const Keyframes = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
`;
/*------ 画像が流れる枠 ------*/
const Sserviseloop = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;
`;
/*------ 画像が流れる ------*/
const Sserviseslider = styled.div`
	display: inline-flex;
    flex: none;
    overflow: hidden;
	animation: ${Keyframes} 25s 0s linear infinite;
`;
/*------ 画像リストul ------*/
const Sservicelist = styled.ul`
    display: flex;
    list-style: none;
`;
/*------ 画像 ------*/
const Sserviceimg = styled.img`
    width:  ${(props) => props.width};
    flex: none;
    padding: 0 1rem 0 0;
    // レスポンシブ 
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
        width: 25rem;
    }
    /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
        width: 35rem;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        width: 40rem;
    }
`;



/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const XSlider = (props) => {

    /*------ global variables ------*/
    const { Image01, Image02, Image03, Image04, Image05,Image06,Image07,Image08,width} = props;

    return (
        <>
            <Sserviseloop>
                <Sserviseslider>
                    <Sservicelist>
                        <li>
                            <Sserviceimg 
                                src={Image01}  
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image02} 
                                width={width} 
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image03}
                                width={width}  
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image04}
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image05}
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image06}
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image07}
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image08}
                                width={width}
                            />
                        </li>
                    </Sservicelist>
                </Sserviseslider>
                <Sserviseslider>
                    <Sservicelist>
                        <li>
                            <Sserviceimg 
                                src={Image01}
                                width={width}  
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image02}
                                width={width}  
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image03} 
                                width={width} 
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image04}
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image05}
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image06}
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image07}
                                width={width}
                            />
                        </li>
                        <li>
                            <Sserviceimg 
                                src={Image08}
                                width={width}
                            />
                        </li>
                    </Sservicelist>
                </Sserviseslider>
            </Sserviseloop>
        </>
    );
}