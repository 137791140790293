/*====== Define import ======*/
/*------ React ------*/
import React from "react";
/*------ CSS ------*/
import "./App.css";
/*------ Route ------*/
import { BrowserRouter, Route, Routes } from "react-router-dom";

/*------ Top ------*/
import { Top } from "./Pages/Top/Top";
import { News } from "./Pages/News/News";
import { About } from "./Pages/About/About";
import { Service } from "./Pages/Service/Service";
import { Company } from "./Pages/Company/Company";
import { Recruit } from "./Pages/Recruit/Recruit";
import { Contact } from "./Pages/Contact/Contact";
import { Privacy } from "./Pages/Other/Privacy";
/*------ Service ------*/
import { Business } from "./Pages/Service/BusinessInterview";
import { Pmpmo } from "./Pages/Service/PmpmoInterview";
import { System } from "./Pages/Service/SystemInterview";
/*------ Contact ------*/
import { ContactForm } from "./Components/Contact/ContactForm";
import { ContactConfirmform } from "./Components/Contact/ContactConfirmForm";
import { ContactComplete } from "./Components/Contact/ContactComplete";
/*------ Recruit ------*/
import { JobDescription } from "./Pages/Other/JobDescription";
import { Mtng } from "./Pages/Recruit/Mitsunaga_Interview"
import { Mzgc } from "./Pages/Recruit/Mizoguchi_interview"
import { Inb } from "./Pages/Recruit/Inaba_Interview"
import { Fjok } from "./Pages/Recruit/Fujioka_Interview"
import {Kdr} from"./Pages/Recruit/Kodera_Interview";
import {Ymmt} from"./Pages/Recruit/Yamamoto_Interview";
import {Szk} from"./Pages/Recruit/Suzuki_Interview";
import { NewsPages } from "./Pages/News/NewsPages";
import { OfficeImage } from "./Pages/Recruit/Office";
import { InterviewList } from "./Pages/Recruit/InterviewList";
import { TarningList } from "./Pages/Recruit/TrainingList";
import { TrainingG21 } from "./Pages/Recruit/TrainingG21";
import { TrainingG22 } from "./Pages/Recruit/TrainingG22";
import { TrainingG23 } from "./Pages/Recruit/TrainingG23";
import { TrainingG24 } from "./Pages/Recruit/TrainingG24";
import { Richness } from "./Pages/Recruit/Richess";
import { Number } from "./Pages/Recruit/Number";
import { Work } from "./Pages/Recruit/Work";
import { CeoMessage } from "./Components/about/ceoMessage";
import { AgendaMiniTile } from "./Components/about/action_agenda_tile";

/* -----workspace------ */
// import { Akira } from "./Pages/WorkSpace/feature_Akira";
// import { Jinno } from "./Pages/WorkSpace/feature_Jinno";
// import { Nakao } from "./Pages/WorkSpace/feature_Nakao";
// import { Ota } from "./Pages/WorkSpace/feature_Ota";
// import { Rikuto } from "./Pages/WorkSpace/feature_Rikuto";
// import { Shitori } from "./Pages/WorkSpace/feature_Shitori";




export default function App() {
  return (
    <>
      {/* <Button /> */}

      <BrowserRouter>
        <Routes>

          <Route
            path="/"
            element={<Top />}
          />
          <Route
            path="/news"
            element={<News />} />
          <Route
            path="/about"
            element={<About />} />
          <Route
            path="/service"
            element={<Service />} />
          <Route
            path="/company"
            element={<Company />} />
          <Route
            path="/recruit"
            element={<Recruit />} />
          <Route
            path="/contact"
            element={<Contact />} />
          <Route
            path="/privacy"
            element={<Privacy />} />
          <Route
            path="/business"  //サービスページビジネス構築インタビュー
            element={< Business />} />
          <Route
            path="/pmpmo" //サービスページpm.pmoインタビュー
            element={< Pmpmo />} />
          <Route
            path="/system" //サービスページシステム開発インタビュー
            element={< System />} />
          <Route
            path="/ceoMessage" //サービスページシステム開発インタビュー
            element={< CeoMessage />} />
          <Route
            path="/action_agenda_tile" //サービスページシステム開発インタビュー
            element={< AgendaMiniTile />} />


          <Route
            path="/contactform" //記入欄
            element={<ContactForm />} />

          <Route
            path="/contactconfirmform" //確認フォーム
            element={<ContactConfirmform />} />

          <Route
            path="/contactcomplete" //完了フォーム
            element={<ContactComplete />} />

          <Route
            path="/recruit/jobdescription" //完了フォーム
            element={<JobDescription />} />

          <Route
            path="/newspages"
            element={<NewsPages />}
          />

          <Route
            path="/recruit/mitsunaga"
            element={<Mtng />}
          />

          <Route
            path="/recruit/mizoguchi"
            element={<Mzgc />}
          />

          <Route
            path="/recruit/inaba"
            element={<Inb />}
          />

          <Route
            path="/recruit/fujioka"
            element={<Fjok />}
          />

          <Route
            path="/recruit/kodera"
            element={<Kdr />}
          />

          <Route
            path="/recruit/suzuki"
            element={<Szk />}
          />

          <Route
            path="/recruit/yamamoto"
            element={<Ymmt />}
          />
          {/* リクルート */}
          <Route
            path="/recruit/entry"
            element={<JobDescription />}
          />

          <Route
            path="/recruit/office"
            element={<OfficeImage />}
          />

          <Route
            path="/recruit/interviewlist"
            element={<InterviewList />}
          />

          <Route
            path="/recruit/traininglist"
            element={<TarningList />}
          />

          <Route
            path="/recruit/training21"
            element={<TrainingG21 />}
          />

          <Route
            path="/recruit/training22"
            element={<TrainingG22 />}
          />

          <Route
            path="/recruit/training23"
            element={<TrainingG23 />}
          />

          <Route
            path="/recruit/training24"
            element={<TrainingG24 />}
          />

          <Route
            path="/recruit/richness"
            element={<Richness />}
          />

          <Route
            path="/recruit/number"
            element={<Number />}
          />

          <Route
            path="/recruit/work"
            element={<Work />}
          />


          {/* workspace */}
          {/* <Route
            path="/Akira"
            element={<Akira />} />
          <Route
            path="/Jinno"
            element={<Jinno />} />
          <Route
            path="/Nakao"
            element={<Nakao />} />
          <Route
            path="/Ota"
            element={<Ota />} />
          <Route
            path="/Rikuto"
            element={<Rikuto />} />
          <Route
            path="/Shitori"
            element={<Shitori />} /> */}
        </Routes>

      </BrowserRouter>
    </>
  );
};