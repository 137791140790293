/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
import { RecruitTraining24 } from "../../Components/Recruit/RecruitTraining24";
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { PgTitle } from "../../Components/Recruit/pgTitle";
import { PgTitle2 } from "../../Components/Recruit/pgTitle2";
import { GlobalBtn } from "../../Components/Common/GlobalBtn";
import { Answer } from "../../Components/Recruit/InterviewContents";
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { RecruitMiniTitle } from "../../Components/Recruit/RecruitMiniTitle";
import { HeadBlock } from "../../HeadBlock";
/* ====== Image ====== */

import TarningListSvg from "../../Images/RecruitSVG/TopTraining.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon3-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon3-2.png";
import schedule from "../../Images/HP_ replace_2024/kenshu/suke24.png";
/* ======================================================================== */
/* ============================ Styles ==================================== */
/* ======================================================================== */




/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
const Top = styled.div`
    margin: 60px auto;
    max-width: 1170px;
    width: 90%;
`;

    const All = styled.div`
    text-align  : center;
    `;

    const TitleSize = styled.h1`
    margin-bottom: 40px;
    font-size: 1.5rem;
    `;

    const Heigth = styled.div`
    margin-bottom: 80px;
    `;

    const SImg = styled.img`
    width: 90%;
    `;
/*====================*/
/*======  Main  ======*/
/*====================*/
export const TrainingG24 = () => {
    /*====== Instance ======*/
    /*====== variables ======*/
    /*====== Data ======*/
    /*====== function ======*/
    /*====== Jsx ======*/


    /* ======================================================================== */
    /* ============================ Styles ==================================== */
    /* ======================================================================== */

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/
    return (
        <>
        <HeadBlock title="RECRUIT - 24卒研修 - 株式会社レイヴン" description="24卒研修の研修内容" path="TrainingG24"  />
            <PgTitle
                pgTitle={TarningListSvg}
                pgTitleName={'TopTraining'}
                describe="24卒内定者研修"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <PgTitle2
                pgTitle={TarningListSvg}
                pgTitleName={'TopTraining'}
                describe="24卒内定者研修"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <Header color="black" />
            <Top>
                <All>
                    <RecruitMiniTitle
                        title="Webサービス作成"
                    />
                </All>
            </Top>
            <RecruitTraining24 />
            <Top>
                <All>
                    <Heigth />
                    <TitleSize>研修内容</TitleSize>
                </All>
                    <Answer
                        color="#5CC6C0"
                        title="Webサービス開発"
                        text="「学生に主体性や目的意識を持たせ、学生の今後をゆたかにする」ことを目標としたWebサービスの作成をしました。
                        Webサービス開発では1から企画し、デザイン・実装を行いました。
                        企画・デザインは内定者同士の話し合いだけでなく、先輩社員からアドバイスももらいました。実装は基本的に内定者同士で教えあい、それでもわからないことは積極的に先輩に聞いてコーディングしました。
                        具体的にはTOPページ・ログインページ・記事ページを作成しました。
                        ログインページではログイン機能やユーザーの新規登録機能、プロフィールの編集機能を実装しました。
                        記事ページでは検索や並び替え、絞り込みができる一覧ページの実装をし、閲覧ページでは、ブックマークや前後の記事へ表示を切り替える機能を実装しました。"
                        Btitle="研修で学んだこと"
                        Btext="半年以上の研修では、サービスができるまでの流れとReactについて学ぶことができました。
                        サービスの作成には様々な工程があります。どのようなスケジュールを立てると良いのか、誰に向けたどのようなサービスにするのか、どのような色・デザインが見やすいのか、どのようなコードを書くと思い通りに動くのかなど…。
                        サービス企画は全く触れたことがなく何もわからない状況であったため、誰のためのサービスなのか、どのようなサービスにするのかなどを考えるのが大変でした。ですがその経験の全てが自分たちの成長に繋がったと思います。
                        プログラミングでは実際に会社に来て研修を行なっていたため、わからないことがあった時は先輩に質問をしました。自分たちの研修中も先輩は業務を行なっていたため、なるべく時間をとらないように質問をすることを心がけました。
                        回数を重ねるごとにわかりやすい質問ができるようになったと感じます。この力は入社後にも使う力のため今後も伸ばしていきたいと思います。"
                    />
                    <Heigth />
                <All>
                    <TitleSize>スケジュール</TitleSize>
                    <SImg style={{ padding: '1vw 0' }} src={schedule} alt="pagetitle" />

                    <Heigth />
                    {/* <TitleSize>研修風景</TitleSize>
                    <Iimages
                        images={trpic}
                        Bimages={trpic}
                        Cimages={trpic}
                    />
                    <Heigth /> */}
                    <GlobalBtn
                        btn_text="研修一覧に戻る〉"
                        width="15rem"
                        height="3rem"
                        color="#27282F"
                        hoverColor="#00000"
                        hoverBackgroundColor="#fbe04f"
                        navi="/recruit/traininglist"
                    />
                    <Heigth />
                </All>
            </Top>
            <RecruitFooter
                display="black"
            />
            <Footer />
        </>
    );
}
