/*====== Define import ======*/
import { useLocation } from "react-router-dom";
/*------ React ------*/
import React from "react";
/*------ CSS ------*/
import styled from "styled-components";
import { useEffect } from "react";


/*------ Styled Components ------*/
const Btn = styled.div`
    width: 12rem;
    height: 50px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    text-decoration: none;
    display: inline-block;
    border: 0.5px solid ${(props) => props.color};
    color:${(props) => props.color}; //枠の色と文字の色は同じ 
    padding: 0.2rem 1rem 0.2rem 1rem;
    text-align: center;
    outline: none;
    transition: ease .2s;
    font-weight: bold;
    letter-spacing: 0.2em;   
    //margin-left: 1rem; 
    cursor: pointer;
    background: ${(props) => props.backColor === "true" ? "white" : "none"};//背景色がいる場合はtrueで何も書かなくても大丈夫
    //hoverした時の文字色
    &:hover{
        color: ${(props) => props.hoverColor};
    }
    //hoverした時のスライドする背景をbeforeで設定
    &:before {
        content: '';
        position: absolute;//絶対配置で位置を指定
        top: 0;
        left: 0;
        z-index: 1;
        /*色や形状*/
        background:${(props) => props.hoverBackgroundColor};//背景色
        width: 100%;
        height: 100%;
        //アニメーション
        transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
        transform: scale(0, 1);//背景の位置
        transform-origin: right top;
    }
    //hoverした時の背景色の動き
    &:hover::before {
        transform-origin:left top;
        transform:scale(1, 1); //背景の位置
    }
    @media screen and (max-width: 820px) { 
            
        width: 6rem;
        height: 30px;
        font-size: 5px;
    }  

`;

/*------ Styled Components ------*/
//ButtonTextの設定
const ButtonText = styled.p`
    position: absolute;
    color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;//動く背景よりも文字を前にする
    @media screen and (max-width: 820px) { 
        font-size: 8px;
    } 

`;
/*====================*/ 
/*====== Main ======*/ 
/*====================*/ 
export const WACCBtn = (props) => {
    /*====== Instance ======*/ 
    /*====== variables ======*/
    const { btn_text, width, height, color, hoverColor, hoverBackgroundColor ,  navi, backColor, id } = props;
    /*====== State ======*/
    
    /*====== function ======*/
    const location = useLocation();

    useEffect(()=>{
        window.scrollTo(0,0);
    },[location]);

    // const handleClick = () => {
    //     // ボタンがクリックされたときの処理
    //     window.location.href = url;
    //   };

    return (
        <>

        <Btn
            width={width}
            height={height}
            color={color}
            backColor={backColor}
            hoverColor={hoverColor}
            hoverBackgroundColor={hoverBackgroundColor}
            id={id}
            navi={navi}
        >
            <ButtonText>{btn_text}</ButtonText>
        </Btn>
        </>
    );
};


