/* ==================== Define import ==================== */

/* ---------- React ---------- */
import React from "react";
import { AboutTile } from "./aboutTile1";
import { SectionTitleAnime } from "../../Components/Common/SectionTitleAnime";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/*------ Img ------*/
import imgabout1 from "../../Images/imgabout1.svg"
import imgabout2 from "../../Images/imgabout2.svg"
import imgabout3 from "../../Images/imgabout3.svg"
import imgabout4 from "../../Images/imgabout4.svg"
/* ---------- Styled Compornents ---------- */
const About = styled.div`
 width: 100%;
`
const All = styled.div`
max-width: 1170px;
margin: 60px 40px 0 40px;
`;
const AboutImg = styled.div`
box-sizing: border-box;
  @media (max-width: 768px) {
    order: 1 ;
    width: 80%;
    margin: 20px auto 0 auto;
    }
`;
const AboutImg2 = styled.div`
box-sizing: border-box;
  @media (max-width: 768px) {
    order: 1 ;
    width: 80%;
    margin: 20px auto 0 auto;
    }
`;
const Philosophy = styled.div`
width: 100%;
display:flex;
  @media (max-width: 768px) {
    flex-direction: column;
    }
`;
const Sdiv = styled.div`
max-width: 1250px;
margin: 0 auto;
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const CorpoPhi = (props) => {
  const Tiles = [
    { id: '1',  color: "#F08070",img: imgabout1, title: '世界に通じるビジネスマンを育てる', text1: '相手は目の前のお客様だけではなく、日本国内だけでもない。', text2: '世界の一流の人や仕事をリードできる人材を育てる。',text4: '相手は目の前のお客様だけではなく、日本国内だけでもない。世界の一流の人や仕事をリードできる人材を育てる。', width: '654px' , height: 'auto',imgwidth: '44.1%',margin:'0 0 0 40px' },
    { id: '2', color: "#F1931F",img: imgabout2, title: '社員とその家族をゆたかにする', text1: '会社は社員に支えられ、社員は家族に支えられている。', text2: '会社を支えるすべての人をゆたかにすることが会社の使命である。',text4: '会社は社員に支えられ、社員は家族に支えられている。会社を支えるすべての人をゆたかにすることが会社の使命である。', width: '585px' , height: 'auto',imgwidth: '50%',margin:'0 40px 0 0'},
    { id: '3', color: "#599AD5",img: imgabout3, title: '社会から必要とされる存在になる', text1: '社会のニーズがわかること、そしてそのニーズに応える。', text2: 'それがお客様のみならず社会から必要とされる企業であり、自ら仕事が作り出せる企業である。', text4: '社会のニーズがわかること、そしてそのニーズに応える。それがお客様のみならず社会から必要とされる企業であり、自ら仕事が作り出せる企業である。', width: '615px' , height: 'auto',imgwidth: '47.4%',margin:'0  0 0 40px'},
    { id: '4', color: "#77B151",img: imgabout4,title: '100年以上続く企業にする', text1: '100年以上を考えるからこそ、基盤づくりの今が最重要。', text2: 'そしてこの信念が会社や人を成長させる。', text4: '100年以上を考えるからこそ、基盤づくりの今が最重要。そしてこの信念が会社や人を成長させる。', width: '585px' , height: 'auto',imgwidth: '50%',margin:'0 40px 0 0'},
  ];

  return (
    <>
      <Sdiv>
     <About>
          <SectionTitleAnime
                    TitleName="CORPORATE  PHILOSOPHY"
                    fontSize="32px"
                    fontFamily="'Roboto Condensed', sans-serif;"
                    fontColor="black"
                    backGroundcolor="black"
                />
                <br />
                <SectionTitleAnime
                    TitleName="企業理念"
                    fontSize="20px"
                    fontFamily=" 'Noto Sans JP', sans-serif"
                    fontColor="black"
                    backGroundcolor="black"
                />
      <All>
        {Tiles.map((item) => (
          <Philosophy key={item.id}>
            {parseInt(item.id)% 2 === 0 ?<AboutImg style={{ width: `calc(100% - ${item.width}px)` }}><img src={item.img} width="100%"/></AboutImg>: ''}
          <AboutTile
            title={item.title}
            text1={item.text1}
            text2={item.text2}
            text4={item.text4}
            color={item.color}
            width={item.width}
            height={item.height}
            margin={item.margin}
            />
          {(parseInt(item.id)% 2) !== 0 ? <AboutImg2 style={{ width: `calc(100% - ${item.width}px)` }}><img src={item.img} width="100%"/></AboutImg2>: ''}
          </Philosophy>
        ))}
      </All>
      </About>
        </Sdiv>
    </>
  )
}