//=============================================================================
// Contents   : HPRP
//              pgTitle
// Author     : RV太田
// LastUpdate : 2023/6/6
// Since      : 2023/6/6
//=============================================================================

/* ======================================================================================================================================================= */
/* ==================================================================== Start Of Program ================================================================= */
/* ======================================================================================================================================================= */


/* ======================================================================== */
/* ============================ import ==================================== */
/* ======================================================================== */
/* ====== React ====== */
import React from "react";

/* ====== CSS ====== */
import styled, { keyframes } from "styled-components";
/* ====== Image ====== */
/* ====== Component Js/Jsx ====== */
/* ====== Style ====== */
/* ======================================================================== */
/* ============================ Styles ==================================== */
/* ======================================================================== */

const Swrapper = styled.div`
    background-color: #FFEF61;
    box-shadow: 0px 10px #5CC6C0;
    height: fit-content;
    /* height: 50vh; */
    width: 100%;
    overflow: hidden;
    padding: 0 0 2% 0;
    border-bottom-left-radius: 150px;
    position: relative;
    z-index: 1;
    text-align: center;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        border-bottom-left-radius: 100px;
        box-shadow: 0px 5px #5CC6C0;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        border-bottom-left-radius: 125px;
    }
    /* ########### 1000px以上 ########### */
    @media (max-width: 1000px) {
        display: none;
    }
`;

const ScontentWrapper = styled.div`
    
`;

const SimgWrapper = styled.svg`
width: 100%;
margin: 15vh auto 2.5vh auto;
`;

const SdescribeWrapper = styled.div`
    max-width: 600px;
    width: 70%;
    margin: 0 auto 5px auto;
`;

const Sdescribe = styled.h1`
    font-size: 1rem;
    line-height: 1.6rem;
    letter-spacing: 0.1em;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        font-size: 0.9rem;
        line-height: 1.3em;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.3em;
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;

const SkarasuWrapper = styled.div`
    position: relative;
    position: absolute;
    right: 15vw;
    bottom: 10vh;
    transform: translate(-50%,-50%);
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        display: none;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        display: none;
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
        display: none;
    }
`;

const SkarasuResWrapper = styled.div`
    position: relative;
    position: absolute;
    top: 50%;
    display: none;
    transform: translate(-50%,-50%);
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        display: block;
        right: 13%;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        display: block;
        right: 20%;
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
        display: block;
        right: 25%;
    }
`;


const AKeyframes = keyframes`
    0% {
        opacity: 1;
    }
    49%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;

const SkarasuA = styled.img`
    animation: ${AKeyframes} 2s ease infinite;
    position: absolute;
    top: 0;
    opacity: 1;
    width: 11rem;
    transform: translate(-50%,-50%);
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        width: 6rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        width: 9rem;
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }

`;

const BKeyframes = keyframes`
    0% {
        opacity: 0;
    }
    49%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`;

const SkarasuB = styled.img`
    animation: ${BKeyframes} 2s ease infinite;
    position: absolute;
    top: 0;
    opacity: 0;
    width: 11rem;
    transform: translate(-50%,-50%);
    /* ########### 599px以下 ########### */
    @media (max-width: 300px) {
        width: 4rem;
    }
    @media (max-width: 599px) {
        width: 6rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        width: 9rem;
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;

/* ======================================================================== */
/* ================== global variables/functions ========================== */
/* ======================================================================== */

/* ======================================================================== */
/* ============================ Main ====================================== */
/* ======================================================================== */

/* ========= 一覧テンプレート ========= */
export const PgTitle2 = (props) => {
    /* ---------------------------------------------------- */
    /* ------------------ Instance ------------------------ */
    /* ---------------------------------------------------- */
    const { pgTitle, pgTitleName, describe, karasuA, karasuB } = props;

    /* ---------------------------------------------------- */
    /* ------------------ variables ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ---------------------- state ----------------------- */
    /* ---------------------------------------------------- */

    /* ----------------------------------------------------- */
    /* ----------------- reack-hook-form ------------------- */
    /* ----------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ---------------------- function -------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- data ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- jsx ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* -------------------- Start Render ------------------ */
    /* ---------------------------------------------------- */

    return (
        <>
            <Swrapper>
                {/* <Simg src={pgTitle} alt="pagetitle" /> */}
                <ScontentWrapper>
                    <SimgWrapper>
                        <use xlinkHref={`${pgTitle}#${pgTitleName}`}></use>
                    </SimgWrapper>
                    <SkarasuResWrapper>
                        <SkarasuA src={karasuA} alt="karasuA" />
                        <SkarasuB src={karasuB} alt="karasuB" />
                    </SkarasuResWrapper>
                </ScontentWrapper>
                <SdescribeWrapper>
                    <Sdescribe>{describe}</Sdescribe>
                </SdescribeWrapper>
                <SkarasuWrapper>
                    <SkarasuA src={karasuA} alt="karasuA" />
                    <SkarasuB src={karasuB} alt="karasuB" />
                </SkarasuWrapper>
            </Swrapper>
        </>
    );
};

/* ======================================================================================================================================================= */
/* ==================================================================== End Of Program =================================================================== */
/* ======================================================================================================================================================= */


