/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/* ==================== Define styles ==================== */
import styled from "styled-components";
/*------ component ------*/
import { WorkTeigi } from "./workTeigi";
import { WorkSekei } from "./workSekei";
import { WorkFkaihatu } from "./workFkaihatu";
import { WorkBkaihatu } from "./workBkaihatu";
import { WorkTest } from "./workTest";
/* ------ Library ------ */



/*------ image ------*/
import Image01 from "../../Images/recruit_work/Group 225.png";
import Image02 from "../../Images/recruit_work/Group 226.png";
import Image03 from "../../Images/recruit_work/Group 227.png";
import Image04 from "../../Images/recruit_work/Group 228.png";
import Image05 from "../../Images/recruit_work/Group 229.png";
import Image06 from "../../Images/recruit_work/Group 243.png";

/* ---------- Styled Compornents ---------- */
const SmainWrap = styled.div`
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        display: none;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Sbox = styled.div`
    max-width: 1170px;
    display: flex;
    width: 100%;
    margin: 2% auto 0 auto;
    justify-content: center;
    align-items: baseline;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        font-size: 0.5rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Swrap = styled.button`
    width: 13%;
    cursor: pointer;
    transition: 1s;
    letter-spacing: 0.1em;
    line-height: 1.1em;
    font-weight: bold;
    background-color: white;
    border: none;
    &:hover{
        transform: scale(1.2);
    }
`;
const Simg = styled.img`
    width: 80%;
    margin: 0 0 5% 0;
`;
const Sp = styled.p`
    font-size: 0.8rem;
`;
const SimgWrap = styled.div`
    margin: 5% 0 0 0;
    text-align: center;
`;
const Skarasu = styled.img`
    width: 30%;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        width: 50%;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;

/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */


export const RecruitWork = () => {

    const [data, setData] = useState();
    const location = useLocation();

    useEffect(()=>{
        window.scrollTo(100,1000);
    },[location]);

    const ImageList = [
        {id:"1", src:Image01, text:"要件定義", },
        {id:"2", src:Image02, text:"設計"},
        {id:"3", src:Image03, text:"実装", fb:"（フロントエンド）"},
        {id:"4", src:Image04, text:"実装", fb:"（バックエンド）"},
        {id:"5", src:Image05, text:"テスト"}
    ];

    const onClick = (item) => {
        const BtnClick = item;
        if (BtnClick === '1') {
            setData('1')
        } else if (BtnClick === '2') {
            setData('2')
        } else if (BtnClick === '3') {
            setData('3')
        } else if (BtnClick === '4') {
            setData('4')
        } else if (BtnClick === '5') {
            setData('5')
        } 
    };

    return(
        <>
            <SmainWrap>
                <div>
                    {
                        data === '1' ?
                            <WorkTeigi />
                        : data === '2' ?
                            <WorkSekei />
                        : data === '3' ?
                            <WorkFkaihatu />
                        : data === '4' ?
                            <WorkBkaihatu />
                        : data === '5' ?
                            <WorkTest />
                        : ''
                    }
                </div>
                
                <Sbox>
                    {
                        ImageList.map((item, index) => {
                            return(
                                data === item.id ?
                                ""
                                :
                                <Swrap key={index} onClick={() => {
                                    onClick(item.id)
                                }}>
                                    <Simg src={item.src} />
                                    <p>{item.text}</p>
                                    <Sp>{item.fb}</Sp>
                                </Swrap>
                            )
                        })
                    }
                </Sbox>

                <SimgWrap>
                    <Skarasu src={Image06} alt="カラス" />
                </SimgWrap>


            </SmainWrap>
        </>
    );
};