/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
/* ------ img ------ */
import pmpmoImg from "../../Images/HP_ replace_2024/Interview/Kato01.jpg";
import serviceImg from "../../Images/HUGIN.svg";
import businesImg from "../../Images/SuzukiFace.jpg";
import { Tile } from "../Common/Tile";
/*====== Define styles ======*/
/*------ Styled Components ------*/
const SWrapper = styled.div`
    max-width: 1170px;
    width: auto;
    margin: 80px auto 0 auto;
`;
const SList = styled.ul`
    list-style: none;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
`;

/*------ Main ------*/
export const ServiceInterviewBtnS = (props) => {

    // const { displayP, displayS, displayB } = props;

    /*--------------------------*/
    /*------ Start Render ------*/
    /*--------------------------*/

    return (
        <>

            <SWrapper>
                <SList>
                    {/* <Tiles href='/service'><Text>サービスページへ戻る（仮）</Text></Tiles> */}
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        title="PM/PMO事業"
                        text="プロジェクトマネジメント"
                        img={pmpmoImg}
                        alt="PM/PMO事業の画像"
                        href="/pmpmo"
                        marginTop='70%'
                    />
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        title="ビジネス構築事業"
                        text="サービス企画/サービス運用構築・サービス運用"
                        img={businesImg}
                        alt="ビジネス構築事業の画像"
                        href="/business"
                        marginTop='70%'
                    />
                    <Tile
                        width="30%"
                        paddingTop="30%"
                        title="SERVICE"
                        text="サービスページへ戻る"
                        img={serviceImg}
                        alt="システム開発事業の画像"
                        href="/service"
                        marginTop='70%'
                        border='2px'
                    />
                </SList>
            </SWrapper>

        </>
    );
}