import React from "react";
import styled from "styled-components";

const Sth = styled.th`
border: 0.5px solid black;
padding: 1%;
vertical-align: middle;
/* white-space: nowrap; */
`;

const Std = styled.td`
border: 0.5px solid black;
padding: 1%;
vertical-align: middle;
`;

export const PrivacyTable = (props) => {


	return (
				<tr>
					<Sth>{props.th}</Sth>
					<Std>{props.td}</Std>
				</tr>
	);
};

