/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from 'react';
/* ---------- CSS ---------- */
import styled from 'styled-components';
/* ------ Components ------ */
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
/* ---------- useNavigate ---------- */
import { useNavigate } from "react-router-dom"
import CheckImage from "../../Images/Check.png"
import S3 from '../../Images/S3.svg';
import { ContactCompleteBtn } from './ContactCompleteBtn';
import { HeadBlock } from '../../HeadBlock';


/*====== Define styles ======*/
/*------ Styled Components ------*/

//画像配置
const CheckImagePlace = styled.div`
display: flex;
    justify-content: center;
    margin: 40px 0;
    
	/* margin: 5% auto 12% 44%; */
	/* height:40px; */
	
`
//ニュース画像
const Checkimage = styled.img`
    width: 10%;
    margin:0 auto;
    padding: 0 0 0 10px;
    @media screen and (max-width:599px){
        width: 20%;
    }
   `;

//画像配置

//ステップ画像
// const FloW = styled.img`
//     width: 72%;
//     margin:0 auto;
//    `;
const Smargin = styled.div`
margin: 80px 0 0 0;
min-height:70vh;
height: fit-content;
/* height:700px; */
`;
const Completewrapper = styled.div`
max-width: 1170px;
margin:0 auto 80px auto;
`;

//送信タイトル
const CompleteTitle = styled.p`
width: 80%;
margin: 0 auto ;
font-size: 1.2rem;
line-height: 30px;
margin-top:40px ;
margin-bottom:40px ;


text-align: center;
/* @media(max-width: 533px){
  		font-size:0.7rem;
		//width: 80%;
		margin: 0 auto;
	} */
    @media screen and (max-width:699px){
        margin: 30px;
    }
`;
const CompleteExplain = styled.p`
width: 80%;
margin: 0 auto;
font-size: 1rem;
letter-spacing:0.1em;
text-align: center;
line-height: 30px;
@media screen and (max-width:599px){
		font-size: 0.7rem;
        line-height: 20px;
    }
/* @media screen and (max-width:418px){
		font-size: 0.5rem;
    } */

`;
//ボタンの配置
const ButtonPlace = styled.div`
margin: 40px 0 0 0;
/* margin-top: 10%;; */
text-align: center;
width:100%;
/* height: 90px; */
/* font-size: 0.7rem; */
`;


const Ssvg = styled.svg`

    height:60px;
    margin-top:70px;
	@media screen and (max-width:899px){
		width: 60%;
    }
	@media screen and (max-width:699px){
		width: 90%;
        margin:40px auto;
    }
`
const Frow = styled.div`
    display: flex;
    justify-content: center;

    	@media screen and (max-width:699px){
		margin: -50px auto;
    }
`

export const ContactComplete = () => {
    // 画面遷移
    const navigate = useNavigate();

    return (
        <>
        <HeadBlock title ="CONTACT - 完了 - 株式会社レイヴン" description="完了" path="ContactComplete" />
            <Header color="black" />
            <Smargin>
                    <Frow><Ssvg   viewBox="0 0 982 90"><use xlinkHref={`${S3}#S3`　}></use></Ssvg></Frow>
                <Completewrapper>
                    <CompleteTitle >お問合せ誠にありがとうございます。</CompleteTitle>
                    <CheckImagePlace>
                        <Checkimage src={CheckImage} alt="ニュース写真" />
                    </CheckImagePlace>
                    <CompleteExplain>
                        {/* お問い合わせ頂き誠にありがとうございます。<br /> */}
                        担当の者が確認の上、ご連絡差し上げます。<br />
                        しばらくして連絡がない場合は、送信エラーの可能性がございます。<br />
                        お手数をお掛けしますが、再度ご連絡をお願いいたします。
                    </CompleteExplain>
                    <ButtonPlace>
                        <ContactCompleteBtn 
                            onClick={() => navigate('/')}
                            btn_text="TOPへ戻る"
                            height="50px"
                            width="200px"
                            hoverBackgroundColor="#fbe04f"
                        />
                    </ButtonPlace>
                </Completewrapper>
            </Smargin>
            <Footer />
        </>
    );
};