
/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
import { Answer } from "./InterviewContents";
import Image01 from "../../Images/recruit_work/Group 227.png";
/* ==================== Define styles ==================== */
import styled from "styled-components";
/* ---------- Styled Compornents ---------- */
const All = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  opacity:1;
    animation-name:sample01;
  animation-duration:2s; 
  animation-iteration-count:1;
  @keyframes sample01 {
    0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
  }
  40%,100% {
    opacity: 1;
  }}
  @media (max-width: 768px) {
    width: 80%;
    }
`;
const Sbox = styled.div`
`;
const Sbox2 = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 5% auto 2% auto;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      margin: 20px 0;
    }
`;
const SimgBox = styled.div`
    text-align: center;
    margin: 0 30px 0 0;
    width: 150px;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Simg = styled.img`
  width: 100%;
`;
const Stext = styled.p`
    margin: 5% 0 0 0;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
      font-size: 0.8rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const Stitle = styled.p`
    /* margin: 0 0 0 5%; */
    font-weight: bold;
    font-size: 1.4rem;
`;
const Test = styled.span`
    font-size: 2rem;
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const WorkFkaihatu = (props) => {
  return (
    <>
        <All>
            <Sbox>
                <Sbox2>
                    <SimgBox>
                        <Simg src={Image01} alt="フロントエンド開発担当"/>
                        <Stext>2022年入社</Stext>
                    </SimgBox>
                    <Stitle>初めてのフロントエンド<Test>実装</Test></Stitle>
                </Sbox2>
            </Sbox>
            <Answer 
                color="#FFEF61"
                title="いつ実装を経験？"
                text="私がフロントエンドの実装を経験したのは、1年目の6月です。私は情報系の大学出身だったので、当時からプログラミングを経験していました。大学では、計算処理のプログラムは作成しており、webサイトの画面は作成したことがなかったため、入社してから初めて本格的なフロントエンドを経験しました。"
                Btitle="初めての実装"
                Btext="初めて開発に携わった案件では、Reactという言語を用いて、画面のモック(実際の業務システムを模倣した画面)を作成しました。1年目の7月に案件の要件定義が終了し、検索機能や編集機能を入れた画面の作成を行いました。その時に作成したのが、社内のお問い合わせを管理するシステムで、その中でもお問い合わせ内容を確認し返信をする画面を作成しました。お問い合わせ内容によって実行したい機能が異なるので、各工程の画面の制御構造が複雑で大変でした。その時は、まず仕様の整理を行いました。画面の制御や切り替え要因を洗い出し、実際に文字に書き起こすことで整理しました。難しい仕組みでも諦めずに続ければなんとか完成するので、諦めない心が大切だと思いました。"
            />
        </All>
    </>
  )
}