/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Style ---------- */
import styled from "styled-components";
/* ---------- components ---------- */
import { HeadBlock } from "../../HeadBlock";
import { KeyVisual } from "../../Components/Recruit/InterviewKeyVisual"
import { NamePlate } from "../../Components/Recruit/InterviewNamePlate"
import { BAnswer } from "../../Components/Recruit/InterviewContents"
import { Iimages } from "../../Components/Recruit/InterviewPhoto"
import { Header } from "../../Components/Common/Header"
import { Footer } from "../../Components/Common/Footer"
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { InterviewTransionList } from "../../Components/Recruit/InterviewTransionList";
/* ---------- Images ---------- */

/*----------キービジュアル----------*/
import CKeyVisualImg from "../../Images/Recruit_KeyVisual/KeyVisualInaba.jpg"
// import CKeyVisualImg from "../../Images/Recruit_KeyVisual/KeyVisualOgawa.jpg"
/*----------３つの連なる画像----------*/
import Timages7 from "../../Images/Recruit_image/Inaba_image1.jpg"
import Timages8 from "../../Images/Recruit_image/Inaba_image2.jpg"
import Timages9 from "../../Images/Recruit_image/Inaba_image3.jpg"
// import Timages7 from "../../Images/Recruit_image/Ogawa_image1.jpg"
// import Timages8 from "../../Images/Recruit_image/Ogawa_image2.jpg"
// import Timages9 from "../../Images/Recruit_image/Ogawa_image3.jpg"

const Height = styled.div`
height: 8rem;
@media (max-width: 599px) {
height: 2rem;
}
`;
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Inb = (props) => {
    return (
        <>
            <HeadBlock title="RECRUIT - 稲葉 真巳 - 株式会社レイヴン" description="インタビュー稲葉" path="inaba" />

            <Header color="white" />

            <KeyVisual
                image={CKeyVisualImg}
            />

            <NamePlate
                EngName="Inaba Mami"
                JpnName="稲葉 真巳"
                year="2018年入社"
                division="開発・PM/PMO"
            />
            <Height />
            <BAnswer
                color="#FFEF61"
                Ctitle="入社して感じたこと"
                Ctext="レイヴンとは事務派遣で契約が始まり、PMOのサポートをしていました。 2年ほど過ぎた頃、正社員にならないかとお誘いをいただき案件業務を継続したまま正社員として入社しました。しばらくして開発事業部に配属されたのですが、経験が全くないため開発のことは何一つわかりませんでした。しかしこれまでやってきたサポート業務の経験を糧にして、できることを増やしていく楽しみを見出しました。年齢やキャリアに関係のない評価や、挑戦する機会があるレイヴンの魅力を改めて感じました。私はこれまでチーム仕事が苦手で、1人で完結したいと思うことが多かったのですが、社内で自分の立ち位置を理解したりお客様から刺激を受けたりするうちに、自分だけが理解していればいいという考えが変わりました。考え方の視野を広げることができたのはこの会社のおかげです。 転職経験があるので会社を俯瞰してみたときに、レイヴンは福利厚生が手厚いと感じました。ＩＴは新しい技術や知識の習得が必須の業界なのでテキストや資格の試験費用や、社員同士の食事会を会社が補助してくれるといったことや、巨大モニターや会議ブースなど社内設備の潤沢さはよそにはない魅力だと感じました。  "
                Dtitle="どんな人にレイヴンに入ってもらいたいか"
                Dtext="物怖じしない人、素直に取り組める人でしょうか。レイヴンの特徴として、代表や上司との距離が近いことが挙げられます。物怖じせず質問や報告、自分の希望や相談などもできると、代表や知識を持っている社員から直接話を聞けることにつながるので、吸収できるものがぐんと増えます。また、距離が近いということは相談しやすく、人の意見を取り入れやすい環境にあるのですが、人の意見を聞いた際に、自分の考えに固執するのではなく柔軟に対応していける素直さを持って取り組むことも必要になってきます。また、仕事は社内だけで完結するものではなく、取引先と二人三脚ですすめるものです。お客様に確認や提案できないと、大事な要望を取りこぼすことにもつながるため、わからないことはわからないと素直に言えることも大事な要素の一つです。この二つの特徴を持っている人はより楽しく仕事ができると思うので、未知なことにも積極的に取り組んでいってほしいなと思います。 "
                Etitle="今どんな仕事をしているか"
                Etext="開発グループとPM/PMOグループのグループマネージャー(GM)をしています。それぞれのラインマネージャーとして、メンバーが業務に専念できるように案件の活動や社内で必要なことを円滑に進める計画や実行、管理も含めて担当しています。GMの仕事内容は、案件のこと、メンバーのこと、お客様のことを会社組織として全ての案件の進行状況を俯瞰して見て管理することが主な仕事です。案件リーダーの相談に乗ったり、他の案件との調整のハブ役になったり、メンバーの相談に乗ったり、社内業務が適宜進められているか・方針通りかのチェックも含まれたりします。それ以外に、GMになる以前から受け持っていた開発案件が継続しているため、メンバーが円滑にプロジェクトを進行できるように管理をしています。両立は大変ですが、管理だけのGＭにならないよう案件メンバーの一員としても仕事を続けたいという気持ちはずっと持っています。 "
            />
            <Iimages
                images={Timages7}
                Bimages={Timages8}
                Cimages={Timages9}
            />

            <InterviewTransionList />
            <RecruitFooter 
                display="block"
            />
            <Footer />
        </>
    )
};