/*====== Define import ======*/

/*------ React ------*/
import React from "react";
/*------ CSS ------*/
import styled from "styled-components";


/*------ Styled Components ------*/
const Btn = styled.div`
    width: ${(props) => props.width};//whidthを使い回したい場合があるのでpropsで管理します
    height: ${(props) => props.height};//whidthを使い回したい場合があるのでpropsで管理します
    position: relative;
    overflow: hidden;
    z-index: 0;
    text-decoration: none;
    display: inline-block;
    border: 0.5px solid ${(props) => props.color};
    color:${(props) => props.color}; //枠の色と文字の色は同じ 
    padding: 0.2rem 1rem 0.2rem 1rem;
    text-align: center;
    outline: none;
    transition: ease .2s;
    font-weight: bold;
    letter-spacing: 0.2em;
    background-color: ${(props)=> props.id ? "#FBE04F":"none"};
    margin-right: ${(props) => props.marginright};//whidthを使い回したい場合があるのでpropsで管理します
    position: relative;
  
    cursor: pointer;

    //hoverした時の文字色
    &:hover{
        color: ${(props) => props.hoverColor};
    }
    //hoverした時のスライドする背景をbeforeで設定
    &:before {
        content: '';
        position: absolute;//絶対配置で位置を指定
        top: 0;
        left: 0;
        z-index: 1;
        /*色や形状*/
        background:${(props) => props.hoverBackgroundColor};//背景色
        width: 100%;
        height: 100%;
        //アニメーション
        transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
        transform: scale(0, 1);//背景の位置
        transform-origin: right top;
    }
    //hoverした時の背景色の動き
    &:hover::before {
        transform-origin:left top;
        transform:scale(1, 1); //背景の位置
    }
    @media screen and (max-width: 766px) {
        margin-bottom:10px;
    } 
   
    @media(min-width: 400px) and (max-width: 449px){
        width: 11rem;
    }
    @media(min-width: 350px) and (max-width: 399px){
        width: 9rem;
    }
    @media screen and (max-width: 349px){
        width: 7rem;
        font-size:0.75rem;
    }
    

`;

/*------ Styled Components ------*/
//ButtonTextの設定
const ButtonText = styled.p`
    position: relative;
    top: 50%;
    left: 52%;
    transform: translate(-50%,-50%);
    z-index: 100;//動く背景よりも文字を前にする
`;
/*====================*/ 
/*====== Main ======*/ 
/*====================*/ 
export const NewsBtn = (props) => {
    /*====== Instance ======*/ 

    /*====== variables ======*/
    const { btn_text, width, height, color, hoverColor, hoverBackgroundColor ,  backColor, onChange, onClick, checked, buttonColor, id,value,marginright } = props;
    /*====== State ======*/
    
    /*====== function ======*/


    return (
       
        <>
            <div>
                <Btn 
                type="radio"
                width={width}
                height={height}
                color={color}
                backColor={backColor}
                hoverColor={hoverColor}
                hoverBackgroundColor={hoverBackgroundColor}
                onClick={onClick}
                onChange={onChange}
                checked={checked}
                buttonColor={buttonColor}
                id={id}                
                value={value}
                marginright={marginright}
                >
                <ButtonText>{btn_text}</ButtonText>
                </Btn>
            </div>
        </>
    );
};