/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";
/* ------ Compornent Js/jsx ------ */
import { Accodion } from "../Common/Accodion";
import { RecruitMiniTitle } from "./RecruitMiniTitle";
import { KarasuPosition } from "./KarasuPosition";

/*------ image ------*/
import Karasu0101 from "../../Images/Recruit_Karasu/RV_karasu_icon5-1.png";
import Karasu0102 from "../../Images/Recruit_Karasu/RV_karasu_icon5-2.png";
import Karasu0201 from "../../Images/Recruit_Karasu/RV_karasu_icon8-1.png";
import Karasu0202 from "../../Images/Recruit_Karasu/RV_karasu_icon8-2.png";


//CSS

///全体のCSS
const All = styled.div`
    margin: 10vh auto;
    position: relative;
    max-width: 1170px;
    width: 100%;
`;
//質問欄
const SaccordionArea = styled.ul`
    list-style: none;
    width: 90%;
    max-width: 900px;
    margin:5vh auto;
`;
const Sp = styled.p`
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 1.6rem;
`;



/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */
export const QandA = () => {
    const text1 = () => {
        return (
            <>
                <Sp>
                    服装は自由です。<br />ですので、社内では年齢・役職を問わず、多くのひとがカジュアルなスタイルで勤務しています。<br />ただし、客先へ出向く場合は、スーツスタイルあるいはビジネスカジュアルを指定しています。
                </Sp>
            </>
        )
    }

    const text2 = () => {
        return (
            <>
                <Sp>
                    忘年会（社員旅行）、設立記念パーティー、ヨンイチ会などがあります。<br />忘年会（社員旅行）は、去年は神戸一昨年は京都と計画は立てていたのですが、新型コロナウイルスの影響で実施することができませんでした。<br />設立記念パーティーは、オフィス内で食事会をしてその合間に軽いゲーム(じゃんけん大会)などをしています。<br />ヨンイチ会は、入社式後に社外で食事会と豪華景品（テレビ、モニター、アップルウォッチなど）が当たるくじ引き大会を実施しました。
                </Sp>
            </>
        )
    }

    const text3 = () => {
        return (
            <>
                <Sp>
                    技術的なことなど頭を酷使する業務が多いので、作業に没頭しているときは、口数は少なくなります。<br />ただ、案件・プロジェクトは、チーム・ユニットを組んで、頻繁にコミュニケーションを取り合いながら、遂行していく必要があります。<br />その過程では、議論は活発になりますし、コミュニケーションを必要なときは、すぐにその場でホワイトボードを利用したスタンディングミーティングがはじまります。
                </Sp>
            </>
        )
    }

    const text4 = () => {
        return (
            <>
            <Sp>
                どの部門の上司も課員に対する気づかいは比較的に強い印象があります。<br />課員ひとりひとりの業務状況はもちろん、体調面、精神面、家庭の都合、プライベートなどを加味して、上手くコントロールしています。
            </Sp>
            </>
        )
    }

    const text5 = () => {
        return (
            <>
                <Sp>
                    代表との距離感はかなり近いです。<br />一般的な会社の場合、会社の代表は、なかなか現場の状況を隅々まで見渡すことはしないでしょう。<br />しかし当社の場合、代表は現場まで降りてきて、各部門やチームの状況を把握しようとします。<br />代表自ら現場へ歩み寄ってくれるので、現場のメンバーも代表に対して状況報告や相談しやすい関係性を築けています。
                </Sp>
            </>
        )
    }

    const text6 = () => {
        return (
            <>
                <Sp>
                    従業者がより快適に、かつ仕事のパフォーマンスを最大限に発揮してもらうための環境づくりや、利用してもらうデバイス、ガジェット、ツールにはこだわっています。<br />良い環境、良いモノを利用することによって、生産性を向上させることができるのであれば、会社として手厚く支援していこうというスタンスを持っています。
                </Sp>
            </>
        )
    }

    const accordionList = [
        {id: 1, question: "服装・髪型はどんな感じ？", answer: text1() },
        {id: 2, question: "会社行事は何がある？", answer: text2() },
        {id: 3, question: "オフィスの雰囲気は？", answer: text3() },
        {id: 4, question: "上司との距離感は？", answer: text4() },
        {id: 5, question: "代表との距離感は？", answer:text5()  },
        {id: 6, question: "どんな設備がある？", answer:text6()},
    ];
    return (
        <>
            <All>
                <RecruitMiniTitle
                    title="よくある質問"
                />

                <KarasuPosition
                    top="1.5vh"
                    left="20%"
                    karasuA={Karasu0101}
                    karasuB={Karasu0102}
                />
                <KarasuPosition
                    top="1.5vh"
                    left="80%"
                    karasuA={Karasu0201}
                    karasuB={Karasu0202}
                />

                <SaccordionArea>
                    {
                        accordionList.map((item, index) => {
                            return (
                                <Accodion
                                    question={item.question}
                                    answer={item.answer}
                                    index={index}
                                    key={item.id}
                                />
                            )
                        })
                    }
                </SaccordionArea>
            </All>
        </>
    )

}