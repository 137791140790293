/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from 'react';
/*------ CSS ------*/
import styled from 'styled-components';
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */
/* ---------- Styled Compornents ---------- */
//文字と画像の背景変化
const Department = styled.div`
    background-color:rgb(39,40,47);
    text-align: center;
`;
//


//タイトルの文字を大きく・太文字のcss
const Companytitle = styled.p`
    text-align: center; 
    color: white;
    font-size:1.6rem;
    margin-bottom:0.2rem;
    line-height: 2.5rem;
    letter-spacing: 0.1rem;
    font-family:'Roboto Condensed', sans-serif;
    font-weight:bold;
    padding-top:5rem;
`;
//東京本社のcss
const CompanySubtitle = styled.p`
    text-align: center; 
    color: white;
    font-size:1.6rem;
    margin-bottom:0.3rem;
    line-height: 2.5rem;
    letter-spacing: 0.1rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight:bold; 
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        font-size:1rem;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        font-size:1.2rem;
    }
`;
//タイトルの下の文字のcss
const CompanyDetail = styled.p`
    text-align: left; 
    color: white;
    font-size:0.9rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    font-family: 'Noto Sans JP', sans-serif;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
        font-size:0.8rem;
    }
`;
//東京住所のCSS
const Text = styled.div`
max-width: 690px;
 text-align: center;
margin: 0 auto;
    /* #### 700px以下 ### */
    @media (max-width: 700px) {
      width: 90%;
    }
`;
//名古屋住所のCSS
const Text2 = styled.div`
max-width: 500px;
 text-align: center;
margin: 0 auto;
    /* #### 700px以下 ### */
    @media (max-width: 500px) {
      width: 90%;
    }
`;
//地図のcss
const Iframe = styled.iframe`
    width:100%;
    height:450px;
    margin-top:3rem;
`;
export const Map = () =>/* const [Fade, setFade] = useState(false);//位置取得・true.false判定*/ /* const [isVisible, setIsVisible] = useState(false);//位置取得・true.false判定*/ /*====== JSX ======*/ /*--------------------------*/ /*------ Start Render ------*/ /*--------------------------*/ (
    <>
        {/* <MapTokyoParts/> */}
        <Department>

            {/* <Title> */}

            <Companytitle>HEAD OFFICE</Companytitle>
            <br />
            <CompanySubtitle>[東京本社]</CompanySubtitle>
            {/* </Title> */}
            <Text>
            <CompanyDetail>〒150-0002</CompanyDetail>
            <CompanyDetail>東京都渋谷区渋谷二丁目9番8号 日総第25ビル 10F</CompanyDetail>
            <CompanyDetail>・「渋谷」駅12出口(東急田園都市線、東京メトロ半蔵門線、東京メトロ副都心線) より徒歩8分</CompanyDetail>
            <CompanyDetail> ・「渋谷」駅12出口(東急東横線) より徒歩8分</CompanyDetail>
            <CompanyDetail> ・「渋谷」駅11出口(東京メトロ半蔵門線、東京メトロ副都心線) より徒歩8分</CompanyDetail>
            </Text>
            <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6483.333690074075!2d139.707029!3d35.660579!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xead33d332b2dc1aa!2z44ix44Os44Kk44O044Oz!5e0!3m2!1sja!2sjp!4v1667463199937!5m2!1sja!2sjp">
            </Iframe>
        </Department>

        <Department>
            <Companytitle>NAGOYA OFFICE</Companytitle>
            <br />
            <Text2>
            <CompanySubtitle> [名古屋営業所]</CompanySubtitle>
            <CompanyDetail>〒460-0003</CompanyDetail>
            <CompanyDetail>愛知県名古屋市中区錦二丁目２０番１５号　広小路クロスタワー14F</CompanyDetail>
            <CompanyDetail>・地下鉄東山線「栄」駅８番出口より徒歩約６分</CompanyDetail>
            <CompanyDetail> ・伏見駅４番出口より徒歩約６分</CompanyDetail>
            </Text2>
            <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3261.5101305362577!2d136.901815!3d35.168836!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dbf269b13a6a700!2z5bqD5bCP6Lev44Kv44Ot44K544K_44Ov44O8!5e0!3m2!1sja!2sjp!4v1667463521883!5m2!1sja!2sjp"></Iframe>
        </Department>
        {/* <MapNagoyaParts/> */}


    </>
);