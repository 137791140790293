/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from 'react';
/* ---------- CSS ---------- */
import styled from 'styled-components'

/* ---------- compornent ---------- */
import { Header } from '../../Components/Common/Header';
import { PgTitle } from '../../Components/Recruit/pgTitle';
import { PgTitle2 } from '../../Components/Recruit/pgTitle2';
import { TileJp } from '../../Components/Common/TileJp';
import { RecruitCard } from '../../Components/Common/RecruitCard';
import { Footer } from '../../Components/Common/Footer';
import { RecruitMiniTitle } from '../../Components/Recruit/RecruitMiniTitle';
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { HeadBlock } from "../../HeadBlock";
/* ---------- images ---------- */
import TarningListSvg from "../../Images/RecruitSVG/TopTraining.svg";
import karasuA from "../../Images/Recruit_Karasu/RV_karasu_icon3-1.png";
import karasuB from "../../Images/Recruit_Karasu/RV_karasu_icon3-2.png";
import tarningA from "../../Images/recruit_Training/21卒研修01.webp"
import tarningB from "../../Images/recruit_Training/3U5A1848.JPG"
import tarningC from "../../Images/HP_ replace_2024/kenshu/23sotu.jpg"
import tarningD from "../../Images/HP_ replace_2024/kenshu/24sotu.jpg"


/*====== Define styles ======*/
/*------ Styled Components ------*/
const Swrap = styled.div`
    max-width: 1170px;
    margin: 3% auto 8% auto;
    width: 95%;
`;
const StileWrap1 = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const TarningList = () => {
    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return( 
        <>
            <HeadBlock title="RECRUIT - 研修 - 株式会社レイヴン" description="レイヴンの内定者研修を年代別に紹介するページ。" path="TarningList"  />
            <Header color="black" />
            <PgTitle 
                pgTitle={TarningListSvg}
                pgTitleName={'TopTraining'}
                describe="レイヴンの研修内容を年代別で見られます！"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <PgTitle2 
                pgTitle={TarningListSvg}
                pgTitleName={'TopTraining'}
                describe="レイヴンの研修内容を年代別で見られます！"
                karasuA={karasuA}
                karasuB={karasuB}
            />
            <RecruitMiniTitle
                title="研修一覧"
            />
            <Swrap>
                <StileWrap1>
                    <TileJp 
                        img={tarningA}
                        alt="21卒"
                        text=""
                        width="34%"
                        paddingTop="30%"
                        title="21卒内定者研修"
                        href="/recruit/training21"
                    />
                    <TileJp 
                        img={tarningB}
                        alt="22卒"
                        text=""
                        width="34%"
                        paddingTop="30%"
                        title="22卒内定者研修"
                        href="/recruit/Training22"
                    />
                    <TileJp 
                        img={tarningC}
                        alt="23卒"
                        text=""
                        width="34%"
                        paddingTop="30%"
                        title="23卒内定者研修"
                        href="/recruit/training23"
                    />
                    <TileJp 
                        img={tarningD}
                        alt="24卒"
                        text=""
                        width="34%"
                        paddingTop="30%"
                        title="24卒内定者研修"
                        href="/recruit/training24"
                    />
                </StileWrap1>
            </Swrap>

            <RecruitCard />
            <RecruitFooter
                    display="black"
            />

            <Footer />

        </>
    );
};