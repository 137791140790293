/*====== Define import ======*/
import React from "react";
import styled from "styled-components";
/* ---------- components ---------- */
import { Header } from "../../Components/Common/Header";
import { SubVisual } from "../../Components/Common/SubVisual";
import { ServiceInterviewVisual } from "../../Components/Service/ServiceInterviewVisual";
import { ServiceInterviewSentence } from "../../Components/Service/ServiceInterviewSentence";
import { ServiceInterviewBtn } from "../../Components/Service/ServiceInterviewBtn";
import { Footer } from "../../Components/Common/Footer";
/* ---------- Images ---------- */
import Img from '../../Images/top_pmpmo.JPG';
import Img01 from '../../Images/HP_ replace_2024/Interview/Kato03.jpg';
import Img02 from '../../Images/HP_ replace_2024/Interview/Kato02.jpg';
import serviceHeaderImg from '../../Images/pmpmoHeaderImg.png';
import { ServiceInterviewBusiness } from "../../Components/Service/ServiceInterview Business.jsx";
import personImage from "../../Images/Kato_pmpmo.png";
import { InterviewTitle } from "../../Components/Service/InterviewTitle";
// import { ServiceInterviewBusiness } from "../../Components/Service/InterviewContents";
import Zu from "../../Images/image 28.png";
import PMPMO1 from '../../Images/Group 221.svg';
import pmpmo2 from '../../Images/pmpmo2 (2).svg';
import { HeadBlock } from "../../HeadBlock";
/*------ Styled Components ------*/
const ScolorWrapper = styled.div`
background-color:white;
margin-top: 15%;
padding-bottom: 5%;
`;

const SinterviewWrapper = styled.div`
margin: 0 auto;
max-width: 1170px;
width: 90%;
position: relative;
`;




const Center = styled.div`
    margin: 80px auto;
    text-align: center;
    width: 80%;
`
const All = styled.div`
    max-width: 1170px;
    width: auto;
    margin: 0 auto;
`;

const Svg = styled.svg`
    max-width: 1170px;
    width: 100%;
text-align: center;
    /* #### 850px以下 ### */
    @media (max-width: 850px) {
        width: 100%;
    }
    /* #### 400px以下 ### */
    @media (max-width: 500px) {
        width: 100%;
    }
    /* margin-left: 3rem; */
    /* height: -webkit-fill-available;
    width: -webkit-fill-available;
    display: flex; */
    /* width: 80%; */
    /* margin: -10rem auto; */
`
const Svg1 = styled.svg`
    max-width: 1170px;
    width: 100%;
    /* #### 850px以下 ### */
    @media (max-width: 850px) {
        width: 100%;
    }
    /* #### 400px以下 ### */
    @media (max-width: 500px) {
        width: 100%;
    }
    /* margin-left: 3rem; */
    /* height: -webkit-fill-available;
    width: -webkit-fill-available;
    display: flex; */
    /* width: 80%; */
    /* margin: -10rem auto; */
`
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Pmpmo = (props) => {
    // 改行
    const msg1 = `ITのスペシャリストが手がける\nプロジェクトマネジメント`
    const text = msg1.split(/(\n)/).map((item, index) => {
        return (
            <div key={index}>
                {item.match(/\n/) ? <br /> : item}
            </div>
        )
    });
    /*------ 本文の部品化 ------*/
    const sentenceList = [
        { sentenceTitle: "お仕事内容について教えてください。", sentence: "PMOを担当しています。PMのサポートのために先回りして段取りを組み、プロジェクト全体の足並みを揃えています。PMの手がまわらない作業の代行もしています。具体的には収集した情報の提供、タスク管理や進捗管理、報告資料の作成、PMの下にいる各チームリーダーに決まった情報を流すなど、中間の役割を担います。", type: "true", img: Img01, alt: "森田さん" },
        { sentenceTitle: "どういうところにやりがいを感じますか？", sentence: "自分の提案や段取りが受け入れられ、上手くいった時にやりがいを感じます。人との関わりが多い業務なことから、自分の頑張りが人からの評価として返ってくるので、感謝の言葉やお褒めの言葉をいただいた時にもやりがいを感じます。" },
        { sentenceTitle: "PM/PMO事業で大変だったことは何ですか？", sentence: "プロジェクト全体のタスク管理や細かなフォローに苦労しています。PMOの仕事は関わる部署が非常に多く、それにともなって打合せの回数も多いため情報が分散しがちです。分散したものを上手く取りまとめて、プロジェクト全体で目的や認識を共有し、足並みを揃えることが難しいと感じます。共有するためには自分が情報を正確に理解している必要があります。そうでないと他者に目的や認識を正確に伝えられず、解釈にずれが生じてしまうので、幅広く知識をつけ、要点を押さえた円滑なコミュニケーションが取れるよう意識しています。" },
        { sentenceTitle: "どのような人がPM/PMO事業に向いていますか？", sentence: "コミュニケーションが好きな人や気配りができる人かと思います。PMOは多くの人や情報と関わる仕事なので、積極的に人と関わり情報を得られる、行動力のある人が向いていると思います。また、プロジェクトを円滑に回し、働きやすくする仕事でもあるので、要点を押さえることが上手く、周りをよく見て細かなフォローができる人も向いていると思います。例えば、誰かにタスクを依頼した際に、依頼した後も継続して進捗などを確認し、フォローを続けるなど、あらゆるタスクを自分事として考えられると良いと思います。", type: "true", img: Img02, alt: "森田さん" },
    ]

    return (
        <>
        <HeadBlock title='SERVICE - PM/PMO事業 - 株式会社レイヴン' description='PM・PMO事業は、プロジェクトマネジメントおよびプロジェクトマネジメントオフィスを導入し、お客様のプロジェクトを総合的にマネジメントします。システム開発や運用構築の技術・ノウハウを組み合わせお客さまのプロジェクトを成功に導きます。' path='Pmpmo' />
            <Header color="white" />
            <SubVisual
                Image={serviceHeaderImg}
                sub_titleEn="SERVICE"
                subTitleJa="サービス"
            />
            <ServiceInterviewVisual
                Img={Img}
                sctsubttl="PM/PMO事業"
                content={text}
                content2="プロジェクトマネジメントおよびプロジェクトマネジメントオフィスを導入し、お客様のプロジェクトを総合的にマネジメントします。システム開発や運用構築の技術・ノウハウを組み合わせお客さまのプロジェクトを成功に導きます。"
            />
            <All>
                <Center>
                        <ServiceInterviewBusiness
                            nummber='1'
                            text='PMOとは'
                            p='多忙なPMの時間を最低限にできるよう、PMO(プロジェクトマネジメントオフィス)がチームを横断して支援をする役割です。'
                        />
                        <Svg1 viewBox="0 0 672 400"><use xlinkHref={`${PMPMO1}#PMPMO1`}></use></Svg1>
                        <ServiceInterviewBusiness
                            nummber='2'
                            text='業務紹介'
                            p='会議の価値を高めるための準備を実施します。特に定例会議はPDCAを回すことでより有意義に実施できます。その他、PMに代わってWBSや課題管理資料など、プロジェクト管理資料の作成・更新を行います。'
                        />
                        <Svg viewBox="0 0 914 529"><use xlinkHref={`${pmpmo2}#pmpmo2`}></use></Svg>
                    <ServiceInterviewBusiness
                        nummber='3'
                        text='業務管理ツール活用'
                        p='業務管理ツールの活用経験が豊富です。現場に合わせたツールの最適活用が可能です。'
                        img={Zu}
                    />
                </Center>

                <ScolorWrapper>
                    <SinterviewWrapper>
                        <InterviewTitle
                            title="INTERVIEW"
                            inputimg={personImage}
                            name="加藤 亜友美 / Ayumi  Kato"
                            workhistory="2023年入社　PM / PMO事業部"

                        />

                        {
                            sentenceList.map((sentenceItem, index) => {
                                return (
                                    <ServiceInterviewSentence
                                        sentenceTitle={sentenceItem.sentenceTitle}
                                        sentence={sentenceItem.sentence}
                                        type={sentenceItem.type}
                                        img={sentenceItem.img}
                                        alt={sentenceItem.alt}
                                        key={index}
                                    />
                                )
                            })
                        }
                        {/* <SsheduleWrapper>
                        <SsheduleTitle>ONE DAY<br />SCHEDULE</SsheduleTitle>
                        <SsheduleImg src={schedule} />
                    </SsheduleWrapper> */}
                        <ServiceInterviewBtn />
                    </SinterviewWrapper>
                </ScolorWrapper>
            </All>
            <Footer color="white" />
        </>
    );
}