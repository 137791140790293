// /* ==================== Define import ==================== */

// /* ---------- React ---------- */
import React from "react";
//import { Nakao } from "../../Pages/WorkSpace/feature_Nakao";
// /* ==================== Define styles ==================== */s
import styled from "styled-components";
// /* ---------- Styled Compornents ---------- */
const Tile = styled.div`
display: flex;
  max-width: 1170px;
  background-color: white;
  color: ${(props) => props.color};
  height: ${(props) => props.height};
  @media (min-width: 768px) {
    }
  @media (max-width: 768px) {
  width: 100%;
    }
`;
const Contents = styled.div`
flex-direction: column; 
@media (max-width: 768px) {
  margin-right:auto;
}
@media (min-width: 768px) {
margin: auto;
}
`;
const Title = styled.h1`
border-left: 5px solid ${(props) => props.color};
padding: 10px 0px 10px 10px;
white-space: nowrap;
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 30px;
  @media (max-width: 1170px) {
    white-space: normal;
    }
  @media (max-width: 900px) {
    font-size: 28px;
    
    }
  @media (max-width: 430px) {
    font-size: 20px;
    }
  
`;
const Texts = styled.div`

    font-size: 20px;
    line-height: 1.5rem;
    @media (max-width: 900px) {
    font-size: 17.5px;
    }
  @media (max-width: 440px) {
    font-size: 15px;
    }
`;
const Text1 = styled.p`
@media (max-width: 449px) {
  display: none;
  };`;
const Text2 = styled.p`
@media (max-width: 449px) {
  display: none;
  };`;

const Text4 = styled.p`
@media (min-width: 450px) {
  display: none;
  };
  `;
const Text5 = styled.p``;



/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const AboutTile = (props) => {
  const { title, text1, text2, text4, text5, width,  height,  color,margin } = props;

  return (
    <>    
        <Tile 
        width={width}
        color={color}
        margin={margin}
        >
          <Contents>
            <Title>{title}</Title>
            <Texts>
              <Text1>{text1}</Text1>
              <Text2>{text2}</Text2>
              <Text4>{text4}</Text4>
              <Text5>{text5}</Text5>
            </Texts>
          </Contents>
        </Tile>
    </>
  )
}
