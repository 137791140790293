/*====== Dfine import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled from "styled-components";

// 囲っているdivのCSS
const SsTitle = styled.div`
    position: relative;
`;

// 内側のボックス
const Sbox = styled.div`
    position: relative;
    height: 400px;
    // レスポンシブ 
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
        height: 200px;
    }
    /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
        height: 250px;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        height: 300px;
    }
`;

// バックグラウンドカラー
const Sback = styled.div`
    position: absolute;
    width: 100%;
    height: 400px;
    top: 0;
    background-color: black;
    opacity: 0.3;
    // レスポンシブ 
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
        height: 200px;
    }
    /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
        height: 250px;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        height: 300px;
    }
`;


// imgのCSS
const Simg = styled.img`
    object-fit: cover;
    width: 100%;
    height: 400px;
    // レスポンシブ 
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
        height: 200px;
    }
    /* #### 600px以上～768px以下 ### */
    @media (min-width: 600px) and (max-width: 768px) {
        height: 250px;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
        height: 300px;
    }
`;

// 英語の方のCSS
const SsubTitle = styled.p`
    color: #ebecf0;
    position: absolute;
    z-index:0;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    font-family: 'Roboto Condensed', sans-serif;
    text-shadow:1px 1px 10px  #1c1c1c;
    letter-spacing: 5px;
    // レスポンシブ 
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
        font-size: 1.5rem;
    }
`;

// 日本語の方のCSS
const SsubTitleJa = styled.p`
    font-size: 0.9rem;
    color: #ebecf0;
    position: absolute;
    z-index:0;
    top: 75%;
    left: 50%;
    letter-spacing: 0.1em;
    transform: translate(-50%, -50%);
    text-shadow:1px 1px 10px #1c1c1c;
    // レスポンシブ 
    /* ### 599px以下#### */
    @media screen and (max-width:599px){
        font-size: 0.7rem;
    }
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const SubVisual = (props) => {

    /* ------ 文字と画像を変更したいのでpropsで管理 ------ */
    const { sub_titleEn, subTitleJa, Image } = props;

    return(
        <>
            <SsTitle>
                <Sbox>
                    <Simg src={Image} alt="image" />
                    <Sback />
                </Sbox>
                <SsubTitle>{sub_titleEn}</SsubTitle>
                <SsubTitleJa>{subTitleJa}</SsubTitleJa>
            </SsTitle>
        </>
    )
};