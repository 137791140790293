/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Style ---------- */
import styled from "styled-components";
/* ---------- components ---------- */
import { HeadBlock } from "../../HeadBlock";
import { KeyVisual } from "../../Components/Recruit/InterviewKeyVisual"
import { NamePlate } from "../../Components/Recruit/InterviewNamePlate"
import { BAnswer } from "../../Components/Recruit/InterviewContents"
import { Iimages } from "../../Components/Recruit/InterviewPhoto"
import { Header } from "../../Components/Common/Header"
import { Footer } from "../../Components/Common/Footer"
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { InterviewTransionList } from "../../Components/Recruit/InterviewTransionList";
/* ---------- Images ---------- */

/*----------キービジュアル----------*/
import KeyVisualImg from "../../Images/HP_ replace_2024/Interview/kodera01.jpg"
/*----------３つの連なる画像----------*/
import Timages1 from "../../Images/HP_ replace_2024/Interview/kodera03.jpg"
import Timages2 from "../../Images/HP_ replace_2024/Interview/kodera02.jpg"
import Timages3 from "../../Images/HP_ replace_2024/Interview/kodera04.jpg"

const Height = styled.div`
height: 8rem;
@media (max-width: 599px) {
height: 2rem;
}
`;
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Kdr = (props) => {
    return(
        <>
          <HeadBlock title="RECRUIT - 小寺 みき - 株式会社レイヴン" description="インタビュー小寺" path="Kdr" />

           <Header color="white"/>

          <KeyVisual 
            image={KeyVisualImg}
          />

          <NamePlate 
            EngName="Miki Kodera"
            JpnName="小寺 みき"
            year="2022年入社"
            division="開発"
          />
          <Height/>

          <BAnswer
            color="#FFEF61"
            Ctitle="入社して感じたこと"
            Ctext="入社して一番に感じたことは入社後のギャップがなかった点です。入社前に約８ヶ月の研修があったため同期との仲は良いですし、出社して研修を行う期間には先輩社員との交流があり、入社後に話しかけにくいといったこともなかったです。研修内容も実際の案件に近い内容であったため、入社後の案件に配属された時も全くわからないといったことはなくスムーズに業務に参画することができました。また、社内にはバリバリ働くという人もいますが、プライベートの時間を大切にするという人もおり、働きやすい職場という印象を受けました。"

            Dtitle="入社して大変だったこと"
            Dtext="入社1年目の後半ごろに精神的に疲れてしまったことです。私は小さなミスも自分の出来なさのせいに感じてしまい、後に引きずってしまう性格も相まって体調を崩してしまいました。そのため2年目から配属を変えてもらったり、仕事量を調整したりといった、手厚いフォローをしてもらいました。このままではレイヴンだけでなく他の企業でもやっていけないと感じたため、そんな自分を変えたいと思い、先輩からの助言で仕事中はできるキャリアウーマンを演じてみました。そうすると仕事への慣れもありますが、今は1年目と比べてポシティブな性格になることができました。"

            Etitle="キャリアプラン"
            Etext="ライフイベントに則ったキャリアを積んでいきたいと考えています。具体的には、30歳までに結婚、35歳までに第一子出産をしたいと思っています。ライフイベントには育休などのブランクが生じてしまうものもあり、そういったブランクをものともしない技術や知識をそれまでに身につけたいと考えています。できることを増やして、1つの会社に固執しなくてもいいようにしたり、在宅で働ける環境、技術を身につけていきたいです。今後自分が技術職になるのかリーダーとして活躍していくのか特には決めていないですが、今のところは技術を磨いていきたいと考えています。今は設計書やテストに関する業務に携わっていますが、今後は離れていたプログラミングを再び強化していきたいと考えています。"
          />

          <Iimages 
            images={Timages1}
            Bimages={Timages2}
            Cimages={Timages3}
          />
            <InterviewTransionList />
            <RecruitFooter 
                display="block"
            />


          <Footer />
        </>
    )
};