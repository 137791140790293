/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Style ---------- */
import styled from "styled-components";
/* ---------- components ---------- */
import { HeadBlock } from "../../HeadBlock";
import { KeyVisual } from "../../Components/Recruit/InterviewKeyVisual"
import { NamePlate } from "../../Components/Recruit/InterviewNamePlate"
import { Answer } from "../../Components/Recruit/InterviewContents"
import { BIimages } from "../../Components/Recruit/InterviewPhoto"
import { Header } from "../../Components/Common/Header"
import { Footer } from "../../Components/Common/Footer"
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { InterviewTransionList } from "../../Components/Recruit/InterviewTransionList";
/* ---------- Images ---------- */

/*----------キービジュアル----------*/
import DKeyVisualImg from "../../Images/Recruit_KeyVisual/KeyVisualFujioka.jpg"
/*----------３つの連なる画像----------*/
import Timages10 from "../../Images/Recruit_image/Fuji_image1.jpg"
import Timages11 from "../../Images/Recruit_image/Fuji_image2.jpg"
import Timages12 from "../../Images/Recruit_image/Fuji_image3.jpg"



const Height = styled.div`
height: 8rem;
@media (max-width: 599px) {
height: 2rem;
}
`;
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Fjok = (props) => {
  return (
    <>
        <HeadBlock title="RECRUIT - 藤岡 莉沙 - 株式会社レイヴン" description="インタビュー藤岡" path="Fjok" />

      <Header color="white" />

      <KeyVisual
        image={DKeyVisualImg}
      />

      <NamePlate
        EngName="Risa Fujioka"
        JpnName="藤岡 莉紗"
        year="2021年入社"
        division="開発事業部"
      />

      <Height />
      <Answer
        color="#FFEF61"
        title="キャリアプラン"
        text="自分の実力や環境に合わせてキャリアアップしたいと考えています。具体的には、手に職をつけて年収をアップさせたり、働く環境を変化させたり、自分の仕事の範囲を変化させたいと思っています。そのためには、同じ会社に固執するのでは無く、1つの会社で自分の目標が達成できたら次の会社に挑戦しようと思っています。何年目になったらこうなりたいではなく、様々な経験をして、今の行動や経験が次やりたい事へのきっかけになれば良いなと思っています。レイヴンははじめての会社なので、まだまだ学ぶことが沢山あります。この会社で仕事を学び、多くの知識と技術を身につけたいと思っています。今は多くの成長の機会をもらえるので、この経験を次の成長に繋げていけるように頑張ります！"

        Btitle="プライベート"
        Btext="長期休みは友人と旅行したり実家に帰るので、地元の友人や家族とBBQをしたりしています。旅行は、コロナの影響で海外に行くことが難しかったので、国内旅行が多いです。最近では、車で京都の嵐山にあるカフェに行きました。車を運転する事が好きなので旅行には車で行くことが多いです。次回は東京に行き美味しいものを沢山食べる予定です。週末はドラマを見たり、本を読んだりしてリフレッシュしています。韓国ドラマが好きで見ているのですが、レイヴンでも見ている人がいて休憩中などにドラマの話で盛り上がっています。"
      />

      <BIimages
        images={Timages10}
        Bimages={Timages11}
        Cimages={Timages12}

      />
      <InterviewTransionList />
      <RecruitFooter
        display="block"
      />

      <Footer />
    </>
  )
};