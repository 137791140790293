import styled from 'styled-components';
import React from 'react';

// components
import { Header } from "../../Components/Common/Header";
import { Footer } from "../../Components/Common/Footer";
import { SubVisual } from '../../Components/Common/SubVisual';
import Image from '../../Images/newssub.JPG';
import { NewsAll } from '../../Components/News/NewsAll';
import { HeadBlock } from '../../HeadBlock';

const Sdiv= styled.div`
`
export const News = () => {
    return (
        <>
        <HeadBlock title='NEWS - 株式会社レイヴン' description='レイヴンの会社・採用の重要なお知らせをご覧いただけます。' path='News' />
    
                <Header color="white" id='news'/>
                <SubVisual
                    Image={Image}
                    sub_titleEn="NEWS"
                    subTitleJa="ニュース"
                />
                <Sdiv>
                <NewsAll />
                </Sdiv>
               
                <Footer />
         
        </>
    );
};