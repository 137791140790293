/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React from "react";
/* ---------- Style ---------- */
import styled from "styled-components";
/* ---------- components ---------- */
import { HeadBlock } from "../../HeadBlock";
import { KeyVisual } from "../../Components/Recruit/InterviewKeyVisual"
import { NamePlate } from "../../Components/Recruit/InterviewNamePlate"
import { Answer } from "../../Components/Recruit/InterviewContents"
import { Iimages } from "../../Components/Recruit/InterviewPhoto"
import { Header } from "../../Components/Common/Header"
import { Footer } from "../../Components/Common/Footer"
import { RecruitFooter } from '../../Components/Recruit/recruitFooter';
import { InterviewTransionList } from "../../Components/Recruit/InterviewTransionList";

/*----------キービジュアル----------*/
import BKeyVisualImg from "../../Images/Recruit_KeyVisual/KeyVisualMizoguchi.jpg"
/*----------３つの連なる画像----------*/
/*溝口さん*/
import Timages4 from "../../Images/Recruit_image/Mizo_image1.jpg"
import Timages5 from "../../Images/Recruit_image/Mizo_image2.jpg"
import Timages6 from "../../Images/Recruit_image/Mizo_image3.jpg"

const Height = styled.div`
height: 8rem;
@media (max-width: 599px) {
height: 2rem;
}
`;
/* ================================================== */
/* ====================== Main ====================== */
/* ================================================== */

export const Mzgc = (props) => {
    return(
        <>
            <HeadBlock title="RECRUIT - 溝口 和哉 - 株式会社レイヴン" description="インタビュー溝口" path="Mzgc" />

           <Header color="white"/>

           <KeyVisual 
            image={BKeyVisualImg}
           />

           <NamePlate 
            EngName="Kazuya Mizoguchi"
            JpnName="溝口 和哉"
            year="2021年入社"
            division="開発事業部"
           />
            <Height/>
           <Answer 
            color="#FFEF61"
            title="入社した理由"
            text="レイヴンでなら、旅行をしながらでも働いて結果を出せる力を身につけることができると感じたからです。私は、昔から旅行が好きで旅行をしながら生きていきたいという夢があります。しかし、仕事をするとなると決まった場所、決まった時間で働かなければなりませんし、旅行をするために長期休暇を取得するにも限界があります。就職活動ではさまざまな会社をみましたが、私の夢を叶えることができる会社にはなかなか出会えませんでした。その後、レイヴンと出会い代表の言葉を受け、レイヴンでなら夢を叶えながらでも働くことができると感じました。もちろん、入社1年目からどこにいても働けるわけではありません。お客様に満足していただけるものを提供できるスキルと遠くにいても人を使って仕事ができるスキルを身につける必要があるからです。そのスキルをレイヴンではいち早く身につけることができると感じ入社を決めました。"

            Btitle="どういう人と仕事をしたいか"
            Btext="自身の目標を明確に持っている人です。明確な目標のある人は、その目標を叶えるために自分から積極的に行動できると思うからです。僕自身も成長し自由に働くという夢のために自己投資・スキル磨きを考えて行動しています。目標と成長意欲がある人は自分から積極的に行動し、新しい知識を習得するため、切磋琢磨できる関係が築け、さらに成長できると思います。そのため、自身の目標を明確に持っている人と一緒に働きたいと思っています。"
           />

           <Iimages 
            images={Timages4}
            Bimages={Timages5}
            Cimages={Timages6}
           />
            <InterviewTransionList />
            <RecruitFooter 
                display="block"
            />
           <Footer />
        </>
    )
};