/*====== Define import ======*/
/*------ React ------*/
import React from "react";
// import { useNavigate } from "react-router-dom";
/*------ CSS ------*/
import styled from "styled-components";
/*------ compornent ------*/
import { HeaderLogoParts } from "./HeaderLogoParts";
/*------ Twitter Icon ------*/
import { FaXTwitter } from "react-icons/fa6";
/*------ Wantedly Icon ------*/
import Image01 from "../../Images/logo/Wantedly_Mark_Wht.png";

/*====== Define styles ======*/
/*------ Styled Components ------*/
const SfooterWrapper = styled.div`
  background-color:rgb(39,40,47);
  width: 100%;
  overflow: hidden;
  padding: 10vh 0 0 0;
`;
const Scontainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 auto 40px auto;
`;
const SleftDiv = styled.div`
`;
const SrightDiv = styled.div`
  list-style: none;
  justify-content: flex-end;
  text-align: right;
  text-align: end;
  justify-content: right;
  margin: 0 0 0 auto;
`;
// プライバシーの取り扱い
const Sprivacy = styled.div`
    font-size: 1rem;
    letter-spacing: 0.1rem;
    color: white;
    margin: 4vh 0 0 3vw;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
      font-size: 0.7rem;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
      font-size: 0.9rem;
    } 
`;
// コピーライト
const Scopyright = styled.p`
    color: white;
    font-size: 0.8rem;
    /* #### 768px以下 ### */
    @media (max-width: 768px) {
      font-size: 0.6rem;
    }
    /* #### 769px以上 #### */
    @media (min-width: 769px) and (max-width: 1169px)  {
      font-size: 0.7rem;
    } 
`;
const LinkLi = styled.div`
    display: flex;
    margin: 0 0 2vh 0;
    position: relative;
    &::after {
        position: absolute;
        left: 0;
        content: '';
        width: 35%;
        height: 2px;
        background: white;
        bottom: -0.5vh;
        transform: scale(0,1.2);
        transform-origin:  left top;
        transition: transform 0.3s;
    }
    &:hover::after {
        transform: scale(3,1.2);
    }
`;
const Icon = styled.span`
    padding: 5px 5px 0 0;
`;
const SlinkP = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  letter-spacing: 0.1em;
  font-family: 'Roboto Condensed', sans-serif;
  padding: 0 43.15px 0 0;
  box-sizing: border-box;
`;
const SiconA = styled.a`
    color: white;
    text-decoration: none;
    justify-content: end;
    justify-content: right;
    text-align: right;
    margin: 0 ;
`;
const Sfont = styled.span`
   padding: 0 2px 0 0;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
`;
/*====================*/
/*======  Main  ======*/
/*====================*/
// const navigate = useNavigate();  
export const Footer = () => {
  return (
    <>
      <SfooterWrapper>
        <Scontainer>
          <SleftDiv>
            <div>
              <HeaderLogoParts color="white" />
            </div>
            <Sprivacy>
              <a href="/privacy" style={{ textDecoration:'none',color:'#ffffff' }}>|個人情報保護方針|</a>
            </Sprivacy>
          </SleftDiv>
          <SrightDiv>
            <LinkLi style={{ marginBottom:'2vh'}}>
              <SiconA href="https://twitter.com/raven_newgrads" target="_blank">
                <SlinkP><Icon><FaXTwitter /></Icon><Sfont>採用</Sfont>X</SlinkP>
              </SiconA>
            </LinkLi>
            <LinkLi style={{ marginBottom:'2vh'}}>
              <SiconA href="https://twitter.com/RAVENinc_PR" target="_blank">
                <SlinkP><Icon><FaXTwitter /></Icon><Sfont>会社</Sfont>X</SlinkP>
                </SiconA>
            </LinkLi>
          </SrightDiv>
        </Scontainer>
        <div style={{ margin:'0 auto 1vh 0', width:'100%', textAlign:'center' }}>
          <Scopyright>Copyright © Raven Inc. All Rights Reserved.</Scopyright>
        </div>
      </SfooterWrapper>
    </>
  );
};
