//=============================================================================
// Contents   : HPRP
//              RecruitCard
// Author     : RV太田
// LastUpdate : 2023/6/6
// Since      : 2023/6/6
//=============================================================================

/* ======================================================================================================================================================= */  
/* ==================================================================== Start Of Program ================================================================= */  
/* ======================================================================================================================================================= */ 


/* ======================================================================== */  
/* ============================ import ==================================== */  
/* ======================================================================== */  
/* ====== React ====== */
import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
/* ====== CSS ====== */
import styled from "styled-components";
/* ====== Image ====== */
import Interview from "../../Images/RecruitSVG/Interview.svg";
import Richness from "../../Images/RecruitSVG/Richness.svg"
import Number from "../../Images/RecruitSVG/Number.svg";
import Training from "../../Images/RecruitSVG/Training.svg";
import Work from "../../Images/RecruitSVG/Work.svg";
import Office from "../../Images/RecruitSVG/Office.svg";
import Top from "../../Images/RecruitSVG/Top.svg";
/* ====== Component Js/Jsx ====== */
import { RecruitMiniTitle } from "../Recruit/RecruitMiniTitle";
/* ====== Style ====== */
/* ======================================================================== */  
/* ============================ Styles ==================================== */  
/* ======================================================================== */
const ScntList = styled.ul`
    max-width: 900px;
    width: 50%;
    margin: 5vh auto 10vh auto;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: center;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        width: 80%;
        margin: 3vh auto 3vh auto;
    }   
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        margin: 5vh auto 5vh auto;
        width: 70%;
    }
    /* ########### 769px以上～1170px以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
        margin: 5vh auto 10vh auto;
        width: 50%;
    }
`;

const ScntItem = styled.li`
    display: ${(props) => props.location === props.to ? "none" : "block"};
    text-align: center;
    margin: 2vh 2%;
    width: 28%;
    transition: 0.5s all;
    &:hover {
        transition: 0.5s all;
        opacity: 0.9;
    }
    @media screen and (max-width: 399px) {
        width: 40%;
        margin: 2.5%;
    }
    @media (min-width: 400px) and (max-width: 768px) {
        margin: 1.5%;
    }
    @media (min-width: 768px) and (max-width: 1169px) {
        margin: 1.5%;
    }
`;

const SvgSize =  styled.svg`
    position: relative;
    z-index: 5;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
`;

const ScntImgWrapper = styled.div`
    /* position: relative; */
    /* z-index: 5; */
    overflow: hidden;
    border-radius: 2rem;
    background-color: #FFEF61;
    padding: 1vw 1vh;
    height: 200px;
    box-shadow: 7px 7px #5CC6C0;
    transition: 0.5s;
    &:hover{
        /* 影       X軸  Y軸  影の色*/
        box-shadow: 0 0 rgba(0, 0, 0, 0.4);
        /* Y軸に5px移動 */
        transform: translate(7px, 7px);
    };

    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        height: 80px;
        border-radius: 1.4rem;
        box-shadow: 5px 5px #5CC6C0;
        &:hover{
        /* 影       X軸  Y軸  影の色*/
        box-shadow: 0 0 rgba(0, 0, 0, 0.4);
        /* Y軸に5px移動 */
        transform: translate(5px, 5px);
    };
    }   
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
        height: 100px;
        border-radius: 1.8rem;
    }
    /* ########### 769px以上～1170px以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
        height: 140px;
    }
`;

// const ScntImg = styled.img`
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//     transition:0.5s all;
// `;

/* ======================================================================== */  
/* ================== global variables/functions ========================== */  
/* ======================================================================== */

/* ======================================================================== */  
/* ============================ Main ====================================== */  
/* ======================================================================== */  

/* ========= 一覧テンプレート ========= */
export const RecruitCard = (props) => {
    const location = useLocation();
    
    /* ---------------------------------------------------- */
    /* ------------------ Instance ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ------------------ variables ----------------------- */
    /* ---------------------------------------------------- */
    const navigate = useNavigate();
    const CardList = [
        { route: '/recruit/interviewlist',navi:'/recruit/interviewlist', svg: Interview, svgName:'Interview',alt:'インタビュー', color:'#FFEF61', key:"1"  },
        { route: '/recruit/richness',navi:'/recruit/richness', routeRear: '/richness' , svg: Richness,svgName:'Richness', alt:'「ゆたか」って何？', color:'#FFEF61', key:"2" },
        { route: '/recruit/number',navi:'/recruit/number', routeRear: '/number' , svg: Number,svgName:'Number', alt:'数字で見る', color:'#FFEF61', key:"3" },
        { route: '/recruit/traininglist',navi:'/recruit/traininglist', routeRear: '/trainingList' , svg: Training, svgName:'Training',alt:'研修', color:'#FFEF61', key:"4" },
        { route: '/recruit/work', navi: '/recruit/work',routeRear: '/work' , svg: Work, svgName:'Work',alt:'成長とチャレンジ', color:'#FFEF61', key:"5" },
        { route: '/recruit/office',navi:'/recruit/office', routeRear: '/office' , svg: Office, svgName:'Office',alt:'オフィス', color:'#FFEF61', key:"6" },
        { route: '/recruit',navi: '/recruit', routeRear: '/' ,svg: Top,svgName:'Top', alt:'採用情報に戻る', color:'#FFEF61', key:"7" },
    ];
    /* -------------Card--------------------------------------- */
    /* ---------------------- state ----------------------- */
    /* ---------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------- reack-hook-form ------------------- */
    /* ----------------------------------------------------- */
   
    /* ---------------------------------------------------- */
    /* ---------------------- function -------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- data ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- jsx ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* -------------------- Start Render ------------------ */
    /* ---------------------------------------------------- */

    return(
        <>
            <RecruitMiniTitle
                title="コンテンツ"
            />
            <ScntList>
                {
                    CardList.map((CardItem, index,navi) => {
                        return (
                            <ScntItem key={index} location={location.pathname} to={CardItem.route} 
                            onClick={()=>{navigate(navi);}}
                            >
                                <a key={index} href={CardItem.route} >
                                    <ScntImgWrapper key={index}>
                                        <SvgSize key={index}>
                                            <use key={index} xlinkHref={`${CardItem.svg}#${CardItem.svgName}`}></use>
                                        </SvgSize>
                                    </ScntImgWrapper>
                                </a>
                            </ScntItem>
                        );
                    })
                }
            </ScntList>
        </>
    );
};

/* ======================================================================================================================================================= */  
/* ==================================================================== End Of Program =================================================================== */  
/* ======================================================================================================================================================= */ 


