//=============================================================================
// Contents   : HPRP
//              数字で見る
// Author     : RV太田
// LastUpdate : 2023/6/6
// Since      : 2023/6/6
//=============================================================================

/* ======================================================================================================================================================= */
/* ==================================================================== Start Of Program ================================================================= */
/* ======================================================================================================================================================= */


/* ======================================================================== */
/* ============================ import ==================================== */
/* ======================================================================== */
/* ====== React ====== */
import React from "react";

/* ====== CSS ====== */
/* ====== Image ====== */
import karasuA from "../../Images/Recruit_Karasu/crowA.png";
import karasuB from "../../Images/Recruit_Karasu/crowAA.png";
import WorkSvg from "../../Images/RecruitSVG/TopWork.svg";
/* ====== Component Js/Jsx ====== */
import { Header } from "../../Components/Common/Header";
import { RecruitMiniTitle } from "../../Components/Recruit/RecruitMiniTitle";
import { RecruitWork } from "../../Components/Recruit/recruitWork";
import { PgTitle } from "../../Components/Recruit/pgTitle";
import { PgTitle2 } from "../../Components/Recruit/pgTitle2";
import { RecruitCard } from "../../Components/Common/RecruitCard";
import { RecruitFooter } from "../../Components/Recruit/recruitFooter";
import { Footer } from "../../Components/Common/Footer";
import styled from "styled-components";
import { WorkTeigi } from "../../Components/Recruit/workTeigi";
import { WorkSekei } from "../../Components/Recruit/workSekei";
import { WorkFkaihatu } from "../../Components/Recruit/workFkaihatu";
import { WorkBkaihatu } from "../../Components/Recruit/workBkaihatu";
import { WorkTest } from "../../Components/Recruit/workTest";
import { HeadBlock } from "../../HeadBlock";
import { WorkProcess } from "./workProcess";

/* ====== Style ====== */
/* ======================================================================== */
/* ============================ Styles ==================================== */
/* ======================================================================== */
const Sp = styled.p`
    max-width: 1170px;
    width: 80%;
    margin: 50px auto 0 auto;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    line-height: 1.6rem;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        font-size: 0.8rem;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;
const SvgAll = styled.div`
    text-align: center;
`;

const SworkWrapper = styled.div`
    display: none;
    /* ########### 599px以下 ########### */
    @media (max-width: 599px) {
        display: block;
    }
    /* ########### 600px以上～768px以下 ########### */
    @media (min-width: 600px) and (max-width: 768px) {
    }
    /* ########### 769px以上～1170pxpx以下 ########### */
    @media (min-width: 769px) and (max-width: 1169px) {
    }
`;

/* ======================================================================== */
/* ================== global variables/functions ========================== */
/* ======================================================================== */

/* ======================================================================== */
/* ============================ Main ====================================== */
/* ======================================================================== */

/* ========= 一覧テンプレート ========= */
export const Work = () => {
    /* ---------------------------------------------------- */
    /* ------------------ Instance ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ------------------ variables ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ---------------------- state ----------------------- */
    /* ---------------------------------------------------- */

    /* ----------------------------------------------------- */
    /* ----------------- reack-hook-form ------------------- */
    /* ----------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ---------------------- function -------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- data ----------------------- */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* ----------------------- jsx ------------------------ */
    /* ---------------------------------------------------- */

    /* ---------------------------------------------------- */
    /* -------------------- Start Render ------------------ */
    /* ---------------------------------------------------- */

    return (
        <>
            <HeadBlock title="RECRUIT - 成長とチャレンジ - 株式会社レイヴン" description="レイヴンの新卒社員が実際に何をしているのかを紹介するページ。" path="Work"  />
            <Header color="black" />
            <div>
                <PgTitle
                    pgTitle={WorkSvg}
                    pgTitleName={'TopWork'}
                    describe="RAVENの新卒社員が、実際に何をしているのかを紹介します。"
                    karasuA={karasuA}
                    karasuB={karasuB}
                />
                <PgTitle2
                    pgTitle={WorkSvg}
                    pgTitleName={'TopWork'}
                    describe="RAVENの新卒社員が、実際に何をしているのかを紹介します。"
                    karasuA={karasuA}
                    karasuB={karasuB}
                />

                <RecruitMiniTitle
                    title="開発工程について"
                />
                <Sp>
                    プロジェクトには開発工程があり、一般的に5つの工程に分けられ、
                    要件定義、設計、実装、テスト、運用の順番で実施されます。
                    要件定義に近いほど上流工程と呼ばれ、運用に近いほど下流工程と呼ばれています。<br />
                    レイヴンでは、上流工程から下流工程まで自社で全部行っているので、
                    レイヴンの新卒社員が工程ごとに何を経験したのかを紹介します。
                </Sp>
                <SvgAll>
                    {/* <Ssvg viewBox="0 0 1130 746">
                        <use xlinkHref={`${feazu}#feazu`}></use>
                    </Ssvg>
                    <SRsvg viewBox="0 0 2756 3237">
                        <use xlinkHref={`${feazuResponsive}#feazuResponsive`}></use>
                    </SRsvg> */}
                    <WorkProcess />
                </SvgAll>
                <RecruitMiniTitle
                    title="体験談"
                />

                <RecruitWork />

                <SworkWrapper>
                    <WorkTeigi />
                    <WorkSekei />
                    <WorkFkaihatu />
                    <WorkBkaihatu />
                    <WorkTest />
                </SworkWrapper>

                <RecruitCard />

                <RecruitFooter />

                <Footer />
            </div>
        </>
    );
};

/* ======================================================================================================================================================= */
/* ==================================================================== End Of Program =================================================================== */
/* ======================================================================================================================================================= */


