/*====== Define import ======*/
/*------ React ------*/
import React from  "react";
import styled from "styled-components";
import  headerImage from "../../Images/logo/headerImage.svg";
import { useNavigate } from "react-router-dom";

/*====== Define styles ======*/
/*------ Styled Components ------*/
const Sbutton = styled.button`
    /* display: none; */
    background: none;
    border: none;
    cursor: pointer;
`;

const Ssvg = styled.svg`
    width: 8rem;
    height: 1.4rem;
    margin-left: 2vw;

    color: ${(props) => props.color};
`;

/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const HeaderLogoParts = (props) =>{

	/*====== Instance ======*/
    const navigate = useNavigate();
	/*====== variables ======*/
    const { color } = props;

    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return(
        <>
            {/* ロゴを押したらトップページに戻る動作 */}
            <Sbutton onClick={() => {
                navigate("/");
            }}>

            {/* ロゴの色を変えるため、propsで管理しています */}
                <Ssvg color={color}>
                    <use xlinkHref={`${headerImage}#headerImage`}></use>
                </Ssvg>
            </Sbutton>
        </>
    );
};