/*====== Define import ======*/
/*------ React ------*/
import React from "react";
/*------ CSS ------*/
import styled from "styled-components";
const Sitem = styled.li`
    margin: 2vh 1%;
    width: 22%;
    transition: 0.5s all;
    &:hover {
        transition: 0.5s all;
        opacity: 0.7;
    }
    @media screen and (max-width: 399px) {
        width: 45%;
        /* margin: 3vh auto; */
        /* justify-content: space-evenly; */
        margin: 6px 0 0 3.3%;
    }
    @media (min-width: 400px) and (max-width: 768px) {
        width: 45%;
        /* margin: 3vh auto; */
        margin: 10px 0 0 3.3%;
    }
    @media (min-width: 768px) and (max-width: 1169px) {
        width: 40%;
        margin: 10px 0 20px 6.7%;
        /* margin: 3vh auto; */
    }
`;
const SimgWrapper = styled.div`
    position: relative;
    z-index: 20;
    overflow: hidden;
`;
const Simg = styled.img`
    width: 100%;
    transition:0.5s all;
    cursor: pointer;
    ${Sitem}:hover & {
        transform:scale(1.3,1.3);
        transition:0.5s all;
    }
`;
const Sattrib = styled.p`
    font-family: 'Noto Sans JP', sans-serif;
    color: rgb(120,120,120);
    font-size: 0.8rem;
    margin: 1vh 1rem 1vh 0;
    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        margin: 1rem 0.5rem 0 1rem;
    }
`;

const Scategoly = styled.span`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 0.8rem;
    /* margin: 0 1rem; */
    margin: 1.1vh 0;
    color: rgb(120,120,120);
    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        margin: 0.5rem 1rem;
    }
`;

const Scontent = styled.p`
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: bold;
    margin: 0 0.01rem;
    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        margin: 0.5rem 1rem;
    }
`;
const Stitle = styled.div`
    display: flex;
    @media screen and (max-width: 768px) {
        display: block;
    }
`
export const TopNewsParts = (props) => {
    const { date, category, p,  onClick, img } = props;
    // console.log(color === "recruit" ? "#ebecf0" : "#ffffff")
    // console.log(id)
    return (
        <>
            <Sitem onClick={onClick}>
                <SimgWrapper><Simg src={img} alt="入社式様子" /></SimgWrapper>
                <Stitle>
                    <Sattrib>{date}</Sattrib>
                    <Scategoly>{category}</Scategoly>
                </Stitle>
                <Scontent>{p}</Scontent>
            </Sitem>
        </>
    );
};