/*====== Define import ====== */
/* ------ Ract ------ */
import React from "react";
/* ------ CSS ------ */
import styled, {keyframes} from "styled-components";
/* ------ Compornent Js/jsx ------ */
/* ------ Images ------ */
/* ------ Icon ------ */
/*====== Define styles ======*/
/*------ Styled Components ------*/

const Skeyframe1 = styled.div`
    position: absolute;
    top: ${(props)=>props.top};
    left: ${(props)=>props.left};
    transform: translate(-50%,-50%);
    /* #### 400px以下 ### */
    @media (min-width: 300px) and (max-width: 600px) {
        top: ${(props) => props.resTop2};
        left: ${(props) => props.resLeft2};
    }
    @media (min-width: 768px) and (max-width: 1169px) {
        top: ${(props) => props.resTop};
        left: ${(props) => props.resLeft};
    }

`;

const SkarasuWrapper = styled.div`
    position: relative;
`;

// --------------------------------------------------
const AKeyframes = keyframes`
    0% {
        opacity: 1;
    }
    49%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;

const SkarasuA = styled.img`
    animation: ${AKeyframes} 2s ease infinite;
    position: absolute;
    top: 0;
    opacity: 1;
    width: 11rem;
    transform: translate(-50%,-50%);
    /* #### 768px以下 ### */
    @media (max-width: 766px) {
      width: 4rem;
    }
    /* #### 769px以上 #### */
    @media (min-width: 767px) and (max-width: 1169px)  {
      width: 8rem;
    }
`;

// --------------------------------------------------
const BKeyframes = keyframes`
    0% {
        opacity: 0;
    }
    49%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`;

const SkarasuB = styled.img`
    animation: ${BKeyframes} 2s ease infinite;
    position: absolute;
    top: 0;
    opacity: 0;
    width: 11rem;
    transform: translate(-50%,-50%);
    /* #### 768px以下 ### */
    @media (max-width: 766px) {
      width: 4rem;
    }
    /* #### 769px以上 #### */
    @media (min-width: 767px) and (max-width: 1169px)  {
      width: 8rem;
    }
`;
/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const KarasuAnimeMiddle = (props) => {

    /*====== Instance ======*/
    const { karasuA, karasuB, top, left,resTop ,resLeft, resTop2, resLeft2 } = props;
    /*====== variables ======*/
    /*====== State ======*/
    /*====== Data ======*/
    /*====== function ======*/

    /*====== Jsx ======*/

    /*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
    return(
        <>
            <Skeyframe1
                top={top}
                left={left}
                resTop={resTop}
                resLeft={resLeft}
                resTop2={resTop2}
                resLeft2={resLeft2}
            >
                <SkarasuWrapper>
                    <SkarasuA src={karasuA} alt="karasuA" />
                    <SkarasuB src={karasuB} alt="karasuB" />
                </SkarasuWrapper>
            </Skeyframe1>
        </>
    );
};